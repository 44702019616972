<div class="sticky-display">
  <div>
  <mat-button-toggle-group multiple [value]="['header-1']" #stickyHeaders="matButtonToggleGroup" class="example-sticky-toggle-group">
    <mat-button-toggle value="header-1"> Row 1 </mat-button-toggle>
  </mat-button-toggle-group>
  </div>
  <div>
  <mat-button-toggle-group multiple [value]="['libelle', 'maj_actuelle']" #stickyColumns="matButtonToggleGroup" class="example-sticky-toggle-group">
    <mat-button-toggle value="libelle"> libelle </mat-button-toggle>
    <mat-button-toggle value="maj_fichier_1"> Fichier1 </mat-button-toggle>
    <mat-button-toggle value="maj_actuelle"> maj_actuelle </mat-button-toggle>
  </mat-button-toggle-group>
  </div>
</div>

<div class="example-container mat-elevation-z8 bloc-list-pj">
  <table mat-table [dataSource]="dataSource"  *ngFor="let table of tables"  class="mat-custom-table">

			<ng-container matColumnDef="libelle" [sticky]="isSticky(stickyColumns, 'libelle')">
				<th mat-header-cell *matHeaderCellDef> N° MAJ </th>
				<td mat-cell  *matCellDef="let element"> {{element.LIBELLE_PIECE}} {{element.IS_OBLIGATOIRE ? '*' : ''}} </td>
			</ng-container>

			<ng-container *ngIf="BehaviorCnm$.data_for_cnm$.value?.IS_MAJ" matColumnDef="maj_fichier" [sticky]="isSticky(stickyColumns, 'maj_fichier_1')">
				<th mat-header-cell *matHeaderCellDef class="text-center"> 0 (initiale) </th>
				<td mat-cell *matCellDef="let element" class="text-center"> {{element.maj_fichier_1}} </td>
			</ng-container>

			<ng-container matColumnDef="actuelle" [stickyEnd]="isSticky(stickyColumns, 'maj_actuelle')">
				<th mat-header-cell *matHeaderCellDef class="text-center"> n (Actuelle) </th>
				<td mat-cell *matCellDef="let element" class="text-center">
						<ul *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' " class="table-actions justify-content-center">
							<li matTooltip="Voir le pièce" matTooltipPosition="left" class="fic_name">
								<span class="" > {{element.FIC_NAME}} </span>
							</li>
							<li   matTooltip="Voir le pièce" matTooltipPosition="left">
								<span class="armp-eye"></span>
							</li>
						</ul>
						<ul *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'PRMP' && element.CODE_PIECE == 'PAGP'" class="table-actions justify-content-center">

							<li   matTooltip="Export PPM en PDF" matTooltipPosition="left">
								<span class="armp-ico-pdf-table" (click)="openDialog('PPM')"></span>
							</li>
							<li   matTooltip="Export AGPM en PDF" matTooltipPosition="left">
								<span class="armp-ico-file-table" (click)="openDialog('AGPM')" ></span>
							</li>

						</ul>
						<ul *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'PRMP' && element.CODE_PIECE != 'PAGP' " class="table-actions d-flex justify-content-center">
							<li *ngIf="BehaviorCnm$.data_for_cnm$.value?.IS_MAJ" matTooltip="Modification" matTooltipPosition="left">
									<span class="armp-ico-edit-table"></span>
							</li>
							<li matTooltip="Ajouter une PJ" matTooltipPosition="left" class="maj-pj">
								<mat-form-field>
									<ngx-mat-file-input (change)="onUploadfile($event, element.CODE_PIECE)" placeholder="Basic Input" ></ngx-mat-file-input>
									<span matSuffix class="parcourir-cust">
										...
									</span>
								</mat-form-field>
							</li>
							<!-- <li matTooltip="Télécharger le fichier"  matTooltipPosition="left">
								<span class="armp-download"></span>
							</li> -->
						</ul>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isSticky(stickyHeaders, 'header-1')"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>

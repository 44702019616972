import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { I_PpmHDR } from 'src/app/shared/models/e-planification/ppm_hdr.interface';
import { EplPpmService } from './epl-ppm.service';
import { I_PRMP } from 'src/app/shared/models/e-planification/prmp.interface';
import { EplCommonService } from '../common/epl-common.service';
import { first } from 'rxjs/operators';
import { I_CAPM, FILTRE } from 'src/app/shared/models/e-planification/ppm.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';

@Injectable({
  providedIn: 'root'
})
export class BehaviourPpmServiceService {

	/*=======================================[ DECLARATION DES BEHAVIORS ]=======================================*/

  //Stockage de donnée ppm détailé
  PPmDtl$ = new BehaviorSubject([]);

  //Stockage donnée ppm
  Ppm_HDR$ = new BehaviorSubject<I_PpmHDR []>([]);

  //Stockage donnée ppm
  PpmHDR$ = new BehaviorSubject<I_PpmHDR []>([]);

  //Stockage action crud ppm
  dataActionOnPPM$ =  new BehaviorSubject([]);

  //Stockage de donnée entête ppm
  liste_PPM$ = new BehaviorSubject<I_PpmHDR []>([]);

  //Stockage de donnée utilisateur
  Donnee_User$ = new BehaviorSubject<I_PRMP [] |undefined>(undefined);

  //Stockage de l'entité selectionné
  Donnee_SelEnt$ = new BehaviorSubject <any [] |undefined>(undefined);

  donnee_user$ = this.eplPPMService.data$

  filtrer_param: FILTRE;

  constructor(
    private common$: EplCommonService,
    private eplPPMService: EplPpmService,
    private userService: UserService,
    private appTools: AppToolsService
  ) {

    this.get_Current_User();
  }

  /**
	 *  Recuperation des PPM par filtre
   *  @param param :{ENT_CODE, LOCALISATION, PRMP_CODE, ANNEE}
	 */
  filtrer_PPM(param: any) {

    this.filtrer_param =  { ...this.filtrer_param, ...param};

    this.eplPPMService.GetPpmByUser(this.filtrer_param).then((Liste: any) => {

			if(Liste){
				this.emit_PPM_Liste(Liste.data);
				this.appTools.hideLoader();
			}else{
				this.appTools.hideLoader()
			}

    });

  }


	/**
	 *  Recuperaton des Infos Utilisateurs
	 */
  get_Current_User(){

    this.userService.connectedUserB$
      .subscribe(
        (Data: any) => {
          this.get_Data_Current_User(Data);
        }
    );
    this.userService.getConnectedUser();


  }

    /**
	 *  Recuperation d'un PPM_HDR par son Code
   *  @param {string} PPM_HDR_CODE
	 */
  get_PPM_HDR_by_Code(code:string) {

    this.eplPPMService.GetPpmHDRByCode(code).subscribe(
      (data:any) => {
        this.get_PPM_HDR(data.data);
      }
    )

  }

  /**
	 *  Recuperation du Liste de PPM_DTL par PPM_HDR
   *  @param {string} PPM_HDR_CODE
	 */
  Get_Liste_PPM_DTL(codeHDR: string) {

    this.eplPPMService.GetPpmDtlByCodeHdr(codeHDR).subscribe(
      (Liste: any) => {
        if(Liste.status == 400){
          this.emit_PPM_DTL_Liste([])

        }
        this.emit_PPM_DTL_Liste(Liste.data);
          // console.log(Liste)
          this.appTools.hideLoader();
      }
    )

  }

  /**
  *  Recupération des données d'un PPM_HDR par Code
  *  @param {string} PPM_HDR_CODE
  */
  Get_PPM_HDR(codeHDR: string){

    this.eplPPMService.GetPpmHDRByCode(codeHDR).subscribe(
      (ppmhdr: any) => {
        this.emit_PPM_HDR(ppmhdr.data)
      }
    )

  }

  /**
  *  Emettre la liste des PPM
  */
  emit_PPM_Liste(ppmData:any) {
    this.liste_PPM$.next(ppmData);
  }

  /**
  *  Emettre la liste des PPM_DTL
  */
  emit_PPM_DTL_Liste(ppmdtlData:any) {
    this.PPmDtl$.next(ppmdtlData);
  }

  /**
  *  Emettre les données utilisateur
  */
  get_Data_Current_User(data:any) {
    this.Donnee_User$.next(data);
  }

  /**
  *  Recupérer et emmettre les données d'un PPM_HDR
  */
  get_PPM_HDR(data:any) {
    this.Ppm_HDR$.next(data);
  }

  emit_PPM_HDR(data:any) {
    this.PpmHDR$.next(data);
  }

}

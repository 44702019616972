<div id="dialog">
	<button mat-dialog-close mat-dialog-close="false" class="close" *ngIf=!autorised>×</button>
	<div class="entete">
		<h3>Veuillez, s'il vous plait, entrer le code à 6 chiffres que nous venons de vous envoyer par mail :</h3>
	</div>
	<div class="step1">
		<span>(Nous voulons savoir si c'est vous avant de pouvoir poursuivre)</span>
		<div class="step2" *ngIf="verification">
			<form id="form" [formGroup]="code" (ngSubmit)="onSubmit()">
				<input name="myinput_drs1" (keyup)="onDigitInput($event)" id="0" type="number" formControlName="digit0"
					[ngModel]="Code.d0" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required /><input
					name="myinput_drs2" (keyup)="onDigitInput($event)" id="1" type="number" formControlName="digit1"
					[ngModel]="Code.d1" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required /><input
					name="myinput_drs3" (keyup)="onDigitInput($event)" id="2" type="number" formControlName="digit2"
					[ngModel]="Code.d2" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required /><input
					name="myinput_drs4" (keyup)="onDigitInput($event)" id="3" type="number" formControlName="digit3"
					[ngModel]="Code.d3" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required /><input
					name="myinput_drs5" (keyup)="onDigitInput($event)" id="4" type="number" formControlName="digit4"
					[ngModel]="Code.d4" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required /><input
					name="myinput_drs6" (keyup)="onDigitInput($event)" id="5" type="number" formControlName="digit5"
					[ngModel]="Code.d5" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" required />
				<button class="btnn btnn-primary" type="submit" [disabled]="code.invalid">Valider</button>
			</form>

			<div class="timer">
				Le code expire dans :
				<a>{{tick}} </a>
				min
			</div>

			<div class="link" *ngIf="verification && renvoyer">
				Code non-reçue?<br />
				<a (click)="renvoyerCode()">Renvoyer une autre code?</a><br />
			</div>
			<div class="link" *ngIf="verification && info">
				Vous avez encore {{tentative}}.
			</div>
		</div>
		<div class="step2" *ngIf="autorised">
			<div>
				<div class="otpblocksucces"></div>
				<h5 class="success">Code valide</h5>
				<button mat-dialog-close mat-dialog-close="true" class="btnn btnn-primary" type="button">Poursuivre</button>
			</div>
		</div>
		<div class="step2" *ngIf="error">
			<div>
				<div class="otpblockfail"></div>
				<h5 class="error">Code invalide</h5>
				<button class="btnn btnn-primary btn-error" type="button" (click)="reessayer()" *ngIf="!close">Reessayer</button>
				<button mat-dialog-close mat-dialog-close="false" class="btnn btnn-primary btn-error" type="button" (click)="information()" *ngIf="close">Fermer</button>
				<div class="link">
					<div class="link">
						<a *ngIf="!close" (click)="renvoyerCode()">Renvoyer une autre code</a><br />
						<a *ngIf="close" (click)="information()">Toutes vos tentatives sont epuisées.</a><br />
					</div>
				</div>
			</div> 
		</div>
		<div class="step2" *ngIf="wait">
			<div class="spinners">
				<mat-spinner class="spin" *ngIf="wait"></mat-spinner>
			</div>
		</div>
	</div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { emplacement } from 'src/app/configs/constants.json'; 
import { InscriptionOpecoService } from '../../services/inscription-opeco.service';
import { Subscription } from 'rxjs';
import { api_res } from 'src/app/shared/interfaces';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-opeco-inscription',
  templateUrl: './opeco-inscription.component.html',
  styleUrls: ['./opeco-inscription.component.scss']
})
export class OpecoInscriptionComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isFormValid: Boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appToolsService: AppToolsService,
    private inscriptionOpecoService: InscriptionOpecoService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const token = params?.q;
      if (token) {
        this.appToolsService.showLoader();
        this.subscription.add(
          this.inscriptionOpecoService.postConfirmOpeco(token)
          .subscribe((res: api_res) => {
            this.toastr.info(res.message as string, "Confirmation inscription OPECO");
            this.router.navigate(['/login/prive'])
            this.appToolsService.hideLoader();
          })
        )
      }
    })

    this.appToolsService.emitEmplacement(emplacement.signUpOpeco);
    this.subscription.add(
      this.inscriptionOpecoService.isValidForm
      .subscribe((isFormValid: Boolean) => {
        this.isFormValid = isFormValid;
      })
    )
  }

  onSubmit(): void {
    const formData = this.inscriptionOpecoService.data.value;
    if (formData !== null) {
      this.appToolsService.showLoader();
      this.subscription.add(
        this.inscriptionOpecoService.postOpeco(formData)
        .subscribe((res: api_res) => {
          if (res.status = "200") {
            this.toastr.info(res.message as string, "Inscription OPECO");
            this.appToolsService.hideLoader();
            this.onBackHome();
          } else {
            this.appToolsService.hideLoader();
            this.toastr.error(res.message as string, "Inscription OPECO");
          }
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBackHome() {
    this.router.navigate(['/']);
  }
}

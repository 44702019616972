import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { I_CustomResolver } from '../../interfaces/core_customisation';
import { I_RequestData } from '../../models/request';
import { ApiService } from '../../services/api/api.service';
import { DataService } from '../../services/data/Data.service';
import { UtilsService } from '../../services/utils/utils.service';

@Injectable()
export class EssentialsDataLoaderResolver implements Resolve<boolean>, I_CustomResolver {

	constructor(
		public dataStorageService: DataService,
		public utilsService: UtilsService,
		public appAPIService: ApiService
	){

	}


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		const routeParams = route.paramMap;

			// this.RPJCode = routeParams.get('idRPJ') as string;

			return new Observable((subscriber) => {

				// this.spinner.show();
				/* console.log("Fetching essentials data..."); */


				this.fetchData().subscribe(data => {

					/* console.log("Resolver Essentials Data ", data); */

					this.dataStorageService.hydrate(data);

					subscriber.next(true);

					subscriber.complete();

				});

			});

  }


	fetchData(){

		return forkJoin({
			listEtat: this.utilsService.reqDataToObservable(this.dataStorageService.listEtat$.value.length!=0 ? this.dataStorageService.listEtat$.value :  this.appAPIService.getAllEtat())
		} as I_RequestData);

	}

}

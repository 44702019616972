import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-pre-requis',
  templateUrl: './liste-pre-requis.component.html',
  styleUrls: ['./liste-pre-requis.component.scss']
})
export class ListePreRequisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

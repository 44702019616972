<div class="container auth-container">
    <app-back-navigation></app-back-navigation>
    <div class="row">
        <div class="col-sm-6">
          <app-egp-auth></app-egp-auth>
        </div>
        <div class="col-sm-6">
            <app-main-logo></app-main-logo>
            <h3>Récupération compte</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,</p>
            <app-form-forgot-password></app-form-forgot-password>
        </div>
    </div>
</div>
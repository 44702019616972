import { Injectable } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfirmButtonConfig, ConfirmConfig, NgxMatAlertConfirmService } from 'ngx-mat-alert-confirm';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AppToolsService {

	menu$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>({});
	emplacement$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>("-");

	emplacemetDynamic$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>("");

	/**
 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
 *
 * menuNavigationChange
 *
 * @description Envènement de changement de menu,
 * par défaut il est toujours emis pendant le click des menus et que l'accès a été validé mais peut
 * être provoqué quand on le veut partout selon le besoin de la fonctionalité
 *
 */
	menuNavigationChange$ = new Subject();

	constructor(
		private spinner: NgxSpinnerService,
		private router: Router,
		private alertConfirmService: NgxMatAlertConfirmService
	) {


		this.router.events.subscribe(event => {

			if (event instanceof GuardsCheckEnd) {

				let nav_data = this.router.getCurrentNavigation()?.extras.state as { is_menu: boolean };

				// console.log("Taaaaake me, to the magic of the moment, of the glooooory night...", nav_data);

				if (nav_data?.is_menu == true) {

					this.menuNavigationChange$.next();

				}

			}

		});


	}


	/**
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * showLoader
	 *
	 * @description Afficher un loader avec un back-drop qui epêche l'utilisateur à tocher à quelques chose
	 *
	 */
	showLoader() {

		this.spinner.show();

	}


	/**
	* @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	*
	* hideLoader
	*
	* @description Ferme le loader et tout rendre cliquable
	*
	*/
	hideLoader() {

		this.spinner.hide();

	}

	// ...............

	updateMenu(menu: any) {

		this.emitMenu(menu);
	}


	updateEmplacement(emplacement: string) {

		this.emitEmplacement(emplacement);
	}


	emitMenu(menu: any) {
		this.menu$.next(menu);
	}


	emitEmplacement(emplacement: string) {
		this.emplacement$.next(emplacement);
	}

	/**
	 * @author Michaël Jonathan Maheriniaina
	 * @param {
			title: string;
			message: string;
			confirmBtnText?: string;
			cancelBtnText?: string;
		}) 
	 * @returns Observable<Boolean>
	 * @description Afficher un dialogue de confirmation
	 */
	confirm(params: {
		title: string;
		message: string;
		confirmBtnText?: string;
		cancelBtnText?: string;
	}): Observable<Boolean> {
		let confirmConfig: ConfirmConfig = {
			title: params.title,
			titleSize: 20,
			message: params.message,
			messageSize: 16,
			iconAnimation: 'shake',
			iconColor: '',
			buttons: [],
			disableClose: true,
			autoFocus: true,
			restoreFocus: true,
			width: 250
		};

		const buttonArr: Array<ConfirmButtonConfig> = [
			{
				id: 'cancel',
				text: params.cancelBtnText ? params.cancelBtnText : "Annuler",
				color: 'primary',
				type: 'basic',
				icon: ''
			},
			{
				id: 'confirm',
				text: params.confirmBtnText ? params.confirmBtnText : "Valider",
				color: 'primary',
				type: 'raised',
				icon: ''
			}
		];
		confirmConfig.buttons = buttonArr;

		const dialogueRef: Observable<string> = this.alertConfirmService.confirm(confirmConfig).afterClosed();
		return dialogueRef.pipe(map(value => value === 'confirm'));
	}
}

import { Component, OnInit, Input } from '@angular/core';
import { hide_browser_route } from "src/app/configs/app.json";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() menu: any;

  hide_browser_route;

  constructor() {
    this.hide_browser_route = hide_browser_route;

  }

  ngOnInit(): void {
  }

}

import { admin } from 'src/app/configs/parametre.json';
import { Injectable } from '@angular/core';
import { session_key, connection_status } from 'src/app/configs/storage_key.json';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../session/session.service';
import { Router } from '@angular/router';
import { I_User, User } from '../../models';

import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { hide_browser_route } from 'src/app/configs/app.json';
import { ApiService } from '../api/api.service';
import { Login_data, Register_data } from '../../interfaces/user';
import { User_sign_res, Opeco_sign_res, api_res } from '../../interfaces';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { I_Direction } from '../../models/e-planification/direction.interface';
import { Entite } from '../../models/entite';

import { I_Opeco } from '../../models/opeco';
import { OtpModalService } from 'src/app/modules/e-soummission/services/otp/otp-modal.service';
import { I_Region } from '../../interfaces/region';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	/**
	 * @deprecated use connectedUserB$ instead
	 *
	 * @todo delete it later
	 *
	 * @description
	 */
	public connecteUser?: User;

	/**
	 * @deprecated use connectedUserB$ instead
	 *
	 * @todo delete it later
	 *
		 * @description
		* */
	public connectedUser$ = new Subject<User>();

	// connected opeco behaviour
	public connectedOpeco$ = new BehaviorSubject<I_Opeco | undefined>(undefined);

	// connected user behaviour
	public connectedUserB$ = new BehaviorSubject<I_User | undefined>(undefined);

	// Emeteur de status de session d'utilisateur
	public connectionStusEvent$ = new BehaviorSubject<{ isConnected: boolean }>({ isConnected: false });

	public isGroupSuperAdmin = new BehaviorSubject<boolean>(false);

	public users: I_User[] = [];
	public usersSubject = new BehaviorSubject<I_User[]>([]);

	//Formulaire Inscription
	public openedForm$ = new BehaviorSubject<any>(undefined);
	public entites$ = new BehaviorSubject<Entite[]>([]);
	public direction$ = new BehaviorSubject<I_Direction[]>([]);
	public region$ = new BehaviorSubject<I_Region[]>([]);

	// Groupe liste
	public listGroupe$ = new BehaviorSubject([]);
	public listEntite$ = new BehaviorSubject([]);
	public prmpByEntCode$ = new BehaviorSubject([]);

	constructor(
		public sessionService: SessionService,
		public route: Router,
		public apiService: ApiService,
		public toastr: ToastrService,
		private spinner: NgxSpinnerService,
		private OtpMoService: OtpModalService
	) {


		this.connectedUserB$.subscribe(
			data => {
				if (data) {
					if (!this.connectionStusEvent$.value.isConnected) {
						this.connectionStusEvent$.next({ isConnected: true });
					}
				} else {
					if (this.connectionStusEvent$.value.isConnected) {
						this.connectionStusEvent$.next({ isConnected: false });
					}
				}
			}
		);
	}

	/**
	 * @description Récupération du PRMP by ENT
	 */
	public getPRMPByEnt(code_ent: string): Observable<api_res> {
		return this.apiService.getPRMPByEntCodeWT(code_ent);
	}

	/**
	 * @deprecated use connectedUserB$ instead
	 *
	 * @description
	 */
	public emitConnectedUser() {
		this.connectedUser$.next(this.connecteUser);
	}


	public emitGroupSuperAdmin(isSuperAdmin: boolean) {
		this.isGroupSuperAdmin.next(isSuperAdmin);
	}


	public login(loginParams: Login_data) {
		this.spinner.show();
		let req = this.apiService.autheticate_user<User_sign_res>(loginParams);
		req.pipe(
			// retry(1),
			catchError(this.handleError)
		).subscribe(data => {
			if (data.status) {
				let user = data.data.user;

				let groupe = data.data.groupeInfo[0];
				let droit = data.data.droitGroupe;
				// petite transformation
				Object.keys(groupe).forEach((d) => { if (groupe[d] === null) { groupe[d] = ''; } });

				let userInfo = {
					...user,
					"Groupe": {
						...groupe,
						"DroitGroupe": {
							...droit
						}
					}
				}

				let jsonConvert: JsonConvert = new JsonConvert();
				jsonConvert.ignorePrimitiveChecks = false;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

				this.sessionService.setItem(session_key, data.data.token);
				this.sessionService.setItem(connection_status, JSON.stringify(data.status));

				try {
					let user_deserialised = jsonConvert.deserializeObject(userInfo, User);
					this.setConnectedUser(user_deserialised);
					this.emitConnectedUser();
					this.connectionStusEvent$.next({
						isConnected: true
					});
					this.spinner.hide();
				} catch (e) {
					this.spinner.hide();
				}
				this.route.navigateByUrl('/welcome', { skipLocationChange: hide_browser_route }).then(
					() => {
						this.toastr.success(data.message as string, 'Authentification');
					}
				);
			} else {
				if (this.connectionStusEvent$.value.isConnected == true) {
					this.connectionStusEvent$.next({
						isConnected: false
					});

				}
				this.toastr.error(data.message as string, 'Authentification');
				this.spinner.hide();
			}

		});

	}


	public loginCivil(loginParams: Login_data) {
		this.spinner.show();
		let req = this.apiService.autheticate_civil<User_sign_res>(loginParams);
		req.pipe(
			// retry(1),
			catchError(this.handleError)
		).subscribe(data => {
			if (data.status) {
				let user = data.data.user;

				let groupe = data.data.groupeInfo[0];
				let droit = data.data.droitGroupe;
				// petite transformation
				Object.keys(groupe).forEach((d) => { if (groupe[d] === null) { groupe[d] = ''; } });

				let userInfo = {
					...user,
					"Groupe": {
						...groupe,
						"DroitGroupe": {
							...droit
						}
					}
				}

				let jsonConvert: JsonConvert = new JsonConvert();
				jsonConvert.ignorePrimitiveChecks = false;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

				this.sessionService.setItem(session_key, data.data.token);
				this.sessionService.setItem(connection_status, JSON.stringify(data.status));

				try {
					let user_deserialised = jsonConvert.deserializeObject(userInfo, User);
					this.setConnectedUser(user_deserialised);
					this.emitConnectedUser();
					this.connectionStusEvent$.next({
						isConnected: true
					});
					this.spinner.hide();
				} catch (e) {
					this.spinner.hide();
				}
				this.route.navigateByUrl('/welcome', { skipLocationChange: hide_browser_route }).then(
					() => {
						this.toastr.success(data.message as string, 'Authentification');
					}
				);
			} else {
				if (this.connectionStusEvent$.value.isConnected == true) {
					this.connectionStusEvent$.next({
						isConnected: false
					});

				}
				this.toastr.error(data.message as string, 'Authentification');
				this.spinner.hide();
			}

		});

	}

	public verifyOpeco(loginParams: Login_data) {
		this.spinner.show();
		let exist = this.apiService.verify_opeco<any>(loginParams);
		exist.pipe(
			// retry(1),
			catchError(this.handleError)
		).subscribe(isExist => {
			if (isExist.status) {

				const indice = 1;
				this.OtpMoService.openLoginOTPModal(isExist.user, indice);
				this.OtpMoService.otpSubject.subscribe(
					(verify: any) => {
						if (verify == 'true') {
							this.loginOpeco(loginParams);
						} else {
							this.toastr.error('Vous n\'avez pas d\'acces.' , 'Authentification');
						}
					}
				);

			} else {
				this.spinner.hide();
				this.toastr.error(isExist.message, 'Authentification');
			}
		});
	}

	public loginOpeco(loginParams: Login_data) {
		this.spinner.show();
		let req = this.apiService.autheticate_opeco<Opeco_sign_res>(loginParams);
		req.pipe(
			// retry(1),
			catchError(this.handleError)
		).subscribe(data => {
			if (data.status) {
				let user = data.data.user;
				let userInfo = {
					...user
				}

				let jsonConvert: JsonConvert = new JsonConvert();
				jsonConvert.ignorePrimitiveChecks = false;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

				this.sessionService.setItem(session_key, data.data.token);
				this.sessionService.setItem(connection_status, JSON.stringify(data.status));

				// const indice = 1;
				// this.OtpMoService.openOTPModal(indice);
				// this.OtpMoService.otpSubject.subscribe(
				// 	(verify: any) => {
				// 		if (verify == 'true') {
				try {
					this.emmitConnectedOpeco(userInfo);
					this.connectionStusEvent$.next({
						isConnected: true
					});
				} catch (e) {
				}
				this.route.navigateByUrl('/welcome', { skipLocationChange: hide_browser_route }).then(
					() => {
						this.toastr.success(data.message as string, 'Authentification');
					}
				);
				// 		} else {
				// 			this.logOut();
				// 		}
				// 	}
				// );
			} else {
				if (this.connectionStusEvent$.value.isConnected == true) {
					this.connectionStusEvent$.next({
						isConnected: false
					});
				}
				this.toastr.error(data.message as string, 'Authentification');
				this.spinner.hide();
			}
		});
	}


	public logOut() {
		this.spinner.show();
		this.sessionService.clearSession();
		this.connectionStusEvent$.next({
			isConnected: false
		});
		this.connectedUserB$.next(undefined);
		this.connecteUser = undefined;
		this.route.navigateByUrl('', {
			skipLocationChange: hide_browser_route
		});
		// this.route.navigateByUrl("/login" + this.sessionService.currentPortail$.value.link, {
		// 	skipLocationChange: hide_browser_route
		// });
		// TODO: delete it later
		// window.location.reload();
		this.spinner.hide();
	}


	public handleError(error: any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			errorMessage = error.error.message;
		} else {
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		return throwError(errorMessage);
	}


	public getLocalToken(): String | null {
		let token = this.sessionService.getItem(session_key) as String;
		if (token == null || token == undefined) {
			return null;
		}
		if (token.trim() == "") {
			return null;
		}
		return token;
	}


	/**
	 * @deprecated use connectedUserB$ instead
	 *
	 * @todo delete it later
	 *
	 * @description
	 */
	public getConnectedUser() {
		if (this.connecteUser == undefined) {
			let req = this.apiService.get_connected_user<User_sign_res>() as Observable<User_sign_res>;
			req.subscribe((data) => {
				let user = data.data.user;
				let groupe =  data.data.groupeInfo.length ? data.data.groupeInfo[0] : '';
				let droit = data.data.droitGroupe;
				Object.keys(groupe).forEach((d) => { if (groupe[d] === null) { groupe[d] = ''; } });
				let userInfo = {
					...user,
					"Groupe": {
						...groupe,
						"DroitGroupe": {
							...droit
						}
					}
				}
				let jsonConvert: JsonConvert = new JsonConvert();
				jsonConvert.ignorePrimitiveChecks = false;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
				try {
					let connecte_user = jsonConvert.deserializeObject(userInfo, User);
					this.setConnectedUser(connecte_user);
					this.emitConnectedUser();
					if (connecte_user.Groupe?.GRP_CODE === admin) {
						this.emitGroupSuperAdmin(true);
					} else {
						this.emitGroupSuperAdmin(false);
					}
				} catch (e) {
					// console.log((<Error>e));
				}
			});
			return false;
		} else {
			this.emitConnectedUser();
			return this.connecteUser;
		}
	}

	public getConnectedOpeco(): void {
		let opeco = this.connectedOpeco$.getValue();
		if (opeco == undefined) {
			let req = this.apiService.get_connected_opeco<Opeco_sign_res>() as Observable<Opeco_sign_res>;
			req.subscribe((data) => {
				let user = data.data.user;
				let userInfo = {
					...user
				}
				let jsonConvert: JsonConvert = new JsonConvert();
				jsonConvert.ignorePrimitiveChecks = false;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
				jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
				try {
					// let connecte_user = jsonConvert.deserializeObject(userInfo, User);
					this.emmitConnectedOpeco(userInfo);
				} catch (e) {
					// console.log((<Error>e));
				}
			});
		} else {
			this.emmitConnectedOpeco(opeco);
		}
	}

	/**
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>

	 * getConnectedUserB
	 *
	 * @description Récupération de données de l'utilisateur connecté, si le retour un un instance d'observable il faut lancer la requête en faisant un subcrible.
	 *
	 * @returns {(I_User | Observable<User_sign_res>)} Objet représenta l'utilisateur connecté ou l'observable représentant la requête pour récupérer les données de l'utilisateur connecté
	 *
	 */
	public getConnectedUserB(): any {
		if (this.connectedUserB$.value) {
			return this.connectedUserB$.value;
		} else {
			return this.apiService.get_connected_user<User_sign_res>();
		}
	}


	/**
	 * @deprecated use connectedUserB$ instead
	 *
	 * @description
	 */
	public setConnectedUser(newUser: User) {
		this.connecteUser = newUser;
	}

	public emmitConnectedOpeco(opeco: I_Opeco) {
		this.connectedOpeco$.next(opeco);
	}


	public createNewUser() {
		this.spinner.show();
		this.apiService.saveUser<api_res>(this.openedForm$.value as I_User).subscribe(
			data => {
				if (data.status) {
					this.route.navigate(['/login/public']);
					this.toastr.success(" Votre compte a bien été créé !", "Compte créé avec succés");
					this.apiService.generateDemandeComptePDF(this.openedForm$.value.publicInfo).subscribe((res) => {
						const linkSource = `data:application/pdf;base64,${res.data}`;
						const downloadLink = document.createElement("a");
						const fileName = "demande_creation_compte.pdf";

						downloadLink.target = "_blank";
						downloadLink.href = linkSource;
						downloadLink.download = fileName;
						downloadLink.click();
					})
				} else {
					this.toastr.error("Echec lors de la création de votre compte, veillez réessayer !", "Erreur");
				}
				this.spinner.hide();
			}
		);
	}


	setOpenedForm(newForm: Register_data | any) {
		this.openedForm$.next(newForm);
	}


	updateForm(data: any) {
		this.openedForm$.next({
			...this.openedForm$.value,
			...data
		});
	}

	public getNotificationByUserConnected(){

		return this.apiService.getNotificationByUserConnected()

	}

	public getNotification(id: string) // id prmp
	{
		return this.apiService.getNotificationByprmpcode(id);
	}

	public getNotificationgac(id: string) // id gac code
	{
		// console.log('code gac user service', id);
		return this.apiService.getNotificationBygaccode(id);

	}
	public setNotificationLue(id?: string) {
		if (id != 'undefined') {
			return this.apiService.setNotificationLue(id);
		} else return false;
	}


	/**
	 * @author Michaël Jonathan
	 * @param q Donnée hasher contenant les données indispensable pour ce fonctionnalité. Ce donnée ne peut être decrypter que dans le API
	 * @returns Observable<api_res>
	 */
	public verifierInvitation(q: string): Observable<api_res> {
		return this.apiService.verifierInvitation(q);
	}
}

import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from './converter';
import { Groupe, I_Groupe } from "./groupe";
/**
 * @deprecated
 */
@JsonObject("User")
export class User {

	@JsonProperty("USR_CODE", String)
	USR_CODE?: String = undefined;

	@JsonProperty("USR_NOM", String)
	USR_NOM?: String = undefined;

	@JsonProperty("USR_PRENOM", String)
	USR_PRENOM?: String = undefined;

	@JsonProperty("USR_ENTITE_CODE", String)
	USR_ENTITE_CODE?: Number = undefined;

	@JsonProperty("USR_NOM_PRENOM", String)
	USR_NOM_PRENOM?: String = undefined;

	@JsonProperty("USR_LOGIN", String)
	USR_LOGIN?: String = undefined;

	@JsonProperty("USR_ADRESSE", String)
	USR_ADRESSE?: String = undefined;

	@JsonProperty("USR_TELEPHONE", String)
	USR_TELEPHONE?: String = undefined;

	@JsonProperty("USR_EMAIL", String)
	USR_EMAIL?: String = undefined;

	@JsonProperty("USR_CIN", String)
	USR_CIN?: String = undefined;

	@JsonProperty("USR_ACTE_NOMINATION", String)
	USR_ACTE_NOMINATION?: String = undefined;

	@JsonProperty("USR_ACTE_NOMINATION_DATE", DateConverter)
	USR_ACTE_NOMINATION_DATE?: Date = undefined;

	@JsonProperty("USR_ABROGATION_DATE", DateConverter)
	USR_ABROGATION_DATE?: Date = undefined;

	@JsonProperty("USR_CREATION_DATE", DateConverter)
	USR_CREATION_DATE?: Date = undefined;

	@JsonProperty("USR_CREATION_USER", String)
	USR_CREATION_USER?: String = undefined;

	@JsonProperty("PRMP_CODE", String)
	PRMP_CODE?: String = undefined;

	@JsonProperty("ENT_LIBELLE", String)
	ENT_LIBELLE?: String = undefined;

	@JsonProperty("REG_CODE", String)
	REG_CODE?: String = undefined;

	@JsonProperty("REG_LIBELLE", String)
	REG_LIBELLE?: String = undefined;

	@JsonProperty("ETAT_LIBELLE", String)
	ETAT_LIBELLE?: String = undefined;

	@JsonProperty("USR_GROUPE", String)
	USR_GROUPE?: String = undefined;

	@JsonProperty("GAC_CODE", String)
	GAC_CODE?: String = undefined;

	@JsonProperty("GAC_LIBELLE", String)
	GAC_LIBELLE?: String = undefined;

	@JsonProperty("PRMP_NOM", String)
	PRMP_NOM?: String = undefined;

	@JsonProperty("ENT_ADRESSE", String)
	ENT_ADRESSE?: String = undefined;

	@JsonProperty("ENT_CODE_MERE", String)
	ENT_CODE_MERE?: Number = undefined;

	@JsonProperty("USR_ENTITE_CODE", String)
	ENT_CODE?: String = undefined;

	@JsonProperty("LIBELLE_CODE_MERE", String)
	LIBELLE_CODE_MERE?: String = undefined;

	@JsonProperty("Groupe", Groupe)
	Groupe?: Groupe = undefined;

	constructor() { }
}


export class I_User {


	USR_CODE: String;


	USR_NOM?: String;

	USR_PRENOM?: String;

	USR_ENTITE_CODE?: Number;


	USR_NOM_PRENOM?: String;


	USR_LOGIN?: String;

	USR_ADRESSE?: String;


	USR_TELEPHONE?: String;


	USR_EMAIL?: String;


	USR_CIN?: String;


	USR_ACTE_NOMINATION?: String;

	USR_ACTE_NOMINATION_DATE?: Date;

	USR_ABROGATION_DATE?: Date;

	USR_CREATION_DATE?: Date;

	USR_CREATION_USER?: String;

	PRMP_CODE?: String;

	ENT_LIBELLE?: String;

	REG_CODE?: String;

	REG_LIBELLE?: String;

	ETAT_LIBELLE?: String;

	USR_GROUPE?: String;

	GAC_CODE?: String;

	GAC_LIBELLE?: String;

	PRMP_NOM?: String;

	ENT_ADRESSE?: String;

	ENT_CODE_MERE?: Number;

	ENT_CODE?: String;

	LIBELLE_CODE_MERE?: String;

	Groupe?: I_Groupe;

	/* Ajouté le 08/07/2021 */
	OPER_CODE?: string
}


export interface I_OPECO {

	OPER_CODE: String,
	OPER_NOM: String | null,
	OPER_PRENOM: String | null,
	OPER_NIMP: String | null,
	OPER_NIF: String | null,
	OPER_TEL: String | null,
	OPER_MAIL: String | null,
	OPER_STAT: String | null,
	OPER_ADRESSE1: String | null,
	OPER_ADRESSE2: String | null,
	OPER_CODE_POSTAL: String | null,

}

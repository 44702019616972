import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { hide_browser_route } from 'src/app/configs/app.json';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { Router } from '@angular/router';

import { secteur } from 'src/app/configs/storage_key.json';
import { secteur_prive, secteur_public } from 'src/app/configs/parametre.json';
import { SessionService } from 'src/app/shared/services/session/session.service';

import { type_AO_ACO, type_AO_AOO, type_AO_AMI, type_marche_code } from "src/app/configs/constants.json";
import { I_User, User } from 'src/app/shared/models';
import { StatusService } from 'src/app/shared/services/status/status.service';
import { ToastrService } from 'ngx-toastr';
import { messages, allModePassation, modeRestreint , rapportJustificatifMode, contratCadreCode, USER_GROUP_CODE, GRP_CODE_SUPER_ADMIN } from 'src/app/configs/constants.json';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { MyCartService } from 'src/app/modules/e-paiement/core/services/my-cart/my-cart.service';
declare var accordion: Function;


@Component({
	selector: 'app-main-header',
	templateUrl: './main-header.component.html',
	styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
	// tslint:disable-next-line: variable-name
	@ViewChild('stickyMenu') menuElement: ElementRef;

	public mesages = messages;
	public allModePassation = allModePassation;


	type_AO_ACO = allModePassation.ACO;
	type_AO_AOO = allModePassation.AOO;
	type_AO_AMI = allModePassation.AMI;
	modeRestreint = modeRestreint;
	rapportJustificatifMode = rapportJustificatifMode;
	contratCadreMode = contratCadreCode;

	elementPosition: any;
	isPrive: boolean = false;
	isPublic: boolean = false;
	isCivil = false;
	hide_browser_route;
	nombre_notification = "0";
	user_groupe_global = this.userService.connectedUserB$.value?.USR_GROUPE;
	constructor(
		public userService: UserService,
		private spinner: NgxSpinnerService,
		private sessionService: SessionService,
		public notificationService: NotificationService,
		public router: Router,
		public statusService: StatusService,
		public toastr: ToastrService,
		public apiService: ApiService,
		public myCartService: MyCartService
	) {

		this.hide_browser_route = hide_browser_route;

	}


	ngOnInit(): void {
		accordion();
		// this.user_groupe_global = this.userService.connectedUserB$.value?.USR_GROUPE;
		this.userService.connectedUser$.subscribe((user: User) => {
			this.userService.connectedUserB$.next(user as I_User);
			if (user) {
				this.user_groupe_global = user.USR_GROUPE;
			}
		});

		let user_secteur = this.sessionService.getItem(secteur);
		if (user_secteur == secteur_prive) {
			this.isPrive = true;
			this.isPublic = false;
			this.isCivil = false;

			if (localStorage.getItem('OPER_CODE') != this.userService.connectedOpeco$.value?.OPER_CODE) {

				localStorage.setItem('OPER_CODE', this.userService.connectedOpeco$.value?.OPER_CODE as string);
				if (localStorage.getItem('cartNumber')) {
					localStorage.removeItem('cartNumber');
					this.myCartService.cartNumber$.next(0);
				}
				localStorage.setItem('OPER_CODE', this.userService.connectedOpeco$.value?.OPER_CODE as string);
				this.myCartService._getCartNumber();

			} else if (localStorage.getItem('OPER_CODE') == this.userService.connectedOpeco$.value?.OPER_CODE) {

				if (localStorage.getItem('cartNumber')) {
					this.myCartService.cartNumber$.next(Number(localStorage.cartNumber));
				} else {
					this.myCartService._getCartNumber();
				}

			}


			// this.myCartService.getCartNumber();

		} else if (user_secteur == secteur_public) {
			this.isPrive = false;
			this.isPublic = true;
			this.isCivil = false;

		} else {
			this.isPrive = false;
			this.isPublic = false;
			this.isCivil = true;
		}
		this.notificationService.getAllNotifications();

	}


	@HostListener('window:scroll', ['$event'])
	handleScroll() {
		const windowScroll = window.pageYOffset;
	}


	ngAfterViewInit() {
		// Tolotriniaina : J'ai juste commenté cette ligne de code cas il y une erreur lors de le chargement
		// this.elementPosition = this.menuElement.nativeElement.offsetTop;

	}

	public navigate(url: string) {

		this.router.navigate([url], {
			skipLocationChange: hide_browser_route,
			state: { is_menu: true }
		});

	}

	// tslint:disable-next-line: typedef
	public logOut() {

		this.userService.logOut();

	}

	// modules access

	public EplanificationAccess() {
		return (this.user_groupe_global == "S-ADMIN" || this.user_groupe_global == "CNM" || this.user_groupe_global == "PRMP"|| this.user_groupe_global == "GAC" || this.user_groupe_global == "CRR" || this.isPrive || this.user_groupe_global == "VIP" || this.user_groupe_global == "SOC_CIV");
	}

	public EdossierAccess() {
		return (this.user_groupe_global == GRP_CODE_SUPER_ADMIN || this.user_groupe_global == "CNM" || this.user_groupe_global == USER_GROUP_CODE.PRMP || this.user_groupe_global == USER_GROUP_CODE.CRR);
	}

	public EsoumissionAccess() {
		return (this.user_groupe_global == "S-ADMIN" || this.user_groupe_global == "CNM" || this.user_groupe_global == "PRMP" || this.user_groupe_global == "CRR" || this.user_groupe_global == "VIP");
	}

	public EevaluationAccess() {
		return (this.user_groupe_global == GRP_CODE_SUPER_ADMIN || this.user_groupe_global == USER_GROUP_CODE.CNM || this.user_groupe_global == USER_GROUP_CODE.PRMP || this.user_groupe_global == USER_GROUP_CODE.CRR || this.user_groupe_global == USER_GROUP_CODE.CAO  || this.user_groupe_global == USER_GROUP_CODE.VIP || this.user_groupe_global == USER_GROUP_CODE.SOC_CIV || this.user_groupe_global == USER_GROUP_CODE.UGPM );
	}

	public EcontratAccess() {
		return (this.user_groupe_global == "S-ADMIN" || this.user_groupe_global == "CNM" || this.user_groupe_global == "PRMP" || this.user_groupe_global == "CRR" || this.user_groupe_global == "VIP" || this.user_groupe_global == USER_GROUP_CODE.SOC_CIV);
	}

	public EmanagementAccess() {
		return (this.user_groupe_global == "S-ADMIN" || this.user_groupe_global == "CNM" || this.user_groupe_global == "PRMP" || this.user_groupe_global == "CRR" || this.user_groupe_global == "VIP");
	}

	public RecoursAccess() {
		return (this.user_groupe_global == GRP_CODE_SUPER_ADMIN || this.user_groupe_global == "CNM" || this.user_groupe_global == USER_GROUP_CODE.PRMP || this.user_groupe_global == USER_GROUP_CODE.CRR || this.user_groupe_global == USER_GROUP_CODE.VIP || this.user_groupe_global == USER_GROUP_CODE.SOC_CIV);
	}

	/**
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * checkEdossierAccess
	 *
	 * @description Vérification de l'accès au menu e-dossier
	 *
	 */
	public checkEdossierAccess() {

		let user_groupe = this.userService.connectedUserB$.value?.USR_GROUPE;

		return (user_groupe == "S-ADMIN" || user_groupe == "CNM" || user_groupe == "PRMP" || user_groupe == "CRR" || user_groupe == "VIP");

	}


	/**
	 *
	 *
	 * checkEplDemandeAccess
	 *
	 * @description Vérification de l'accès au menu e-planification DEMANDE / REGROUPEMENT BESOIN / PLAN DE PASSATION DE MARCHE
	 *
	 */
	public checkEplDemandeAccess() {

		let user_groupe = this.userService.connectedUserB$.value?.USR_GROUPE;

		return (user_groupe == "S-ADMIN" || user_groupe == "PRMP" || user_groupe == "CRR" || user_groupe == "VIP" || user_groupe == "SOC_CIV" );

	}


	/**
	 *
	 *
	 * checkEplBesoinAccess
	 *
	 * @description Vérification de l'accès au menu e-planification BESOIN
	 *
	 */
	public checkEplBesoinAccess() {

		let user_groupe = this.userService.connectedUserB$.value?.USR_GROUPE;

		return (user_groupe == "S-ADMIN" || user_groupe == "GAC" || user_groupe == "CRR" || user_groupe == "VIP" || user_groupe == "SOC_CIV");

	}

	/**
	 *
	 *
	 * checkEplPPMAccess
	 *
	 * @description  Vérification de l'accès au menu e-planification Plan de Passation des Marchés
	 */
	public checkEplPPMAccess() {

		let user_groupe = this.userService.connectedUserB$.value?.USR_GROUPE;

		return (user_groupe == "S-ADMIN" || user_groupe == "PRMP" || user_groupe == "CNM" || user_groupe == "CRR" || user_groupe == "VIP" || user_groupe == "SOC_CIV");

	}


	get isSercteurPrive(){

		let user_secteur = this.sessionService.getItem(secteur);

		return user_secteur == secteur_prive;

	}


	// verifyCartNumber() {



	// }


	/* isDisabled(): boolean {

		if (this.statusService.isConnectedToNetwork$.value == false) {

			this.toastr.warning(messages.actionNotAvailableMessage, messages.networkStatusMessages.offlineMessage);

			return true;

		} else {

			return false;
		}

	} */

}

import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { EplCommonService } from '../common/epl-common.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { User } from 'src/app/shared/models';
import { ppm_filtreData } from 'src/app/shared/models/e-planification/ppm_filtre.interface';
import { I_UserInfoGroup, I_API_PPM_DTL } from '../../models/interfaces';
import { base_url, endpoints } from '../../../../../../assets/api.json';
import { HttpClient } from '@angular/common/http';
import { FILTRE } from 'src/app/shared/models/e-planification/ppm.interface';
import { api_res } from 'src/app/shared/interfaces';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { DataSource } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';

const INITD: any = [];

@Injectable({
  providedIn: 'root'
})
export class EplPpmService implements OnInit {

  userInfo: User;
  userInfoGroupe: I_UserInfoGroup;
  PRMP_CODE: any;
  Liste: any = [];
  FiltredData: FILTRE= {
    ENT_CODE: '',
    LOCALISATION: '',
    USR_CODE: '',
    ANNEE: '',

  };

  annee: "";


  private ListeLotOfPPM_DTL: Array<any> = [];
  Lot$ = new Subject<any[]>()

  filtrage : FILTRE= {
    ENT_CODE: '',
    LOCALISATION: '',
    USR_CODE: '',
    ANNEE: '',

  }

  //Stockage donnée utilisateur
  private DataStorage = new BehaviorSubject(INITD);
  data$: Observable<any> = this.DataStorage.asObservable();

  //Stockage donnée ppm
  private allPpmSubject = new BehaviorSubject(INITD);
  ppms$: Observable<any> = this.allPpmSubject.asObservable();

  //Stockage de donnée ppm détailé
  private allPPmDtlSubject = new Subject();
  ppmDTL$: Observable<any> = this.allPPmDtlSubject.asObservable();

  constructor(
    private commom$: EplCommonService,
    private http: HttpClient,
    private appTools: AppToolsService,
		private toastService:ToastrService


  ) {

    this.commom$.getCurrentUserInfo().subscribe(
      (data: any) => {
        this.DataStorage.next(data);
      }
    );
    // this.emitListPpm();

  }

  ngOnInit() {

  }

  Load() {
    this.commom$.getCurrentUserInfo().subscribe(
      (data: any) => {
        this.DataStorage.next(data);
        this.filtrage.USR_CODE = data.USR_CODE;
      }
    );
  }

	/**
	 * @description Récupération de la réponse API et les afficher en tant que message
	 * @param response Observable
	 */
	getResponse(res:any, callBack: () => any){

		if (res.status) {
			callBack();
			this.toastService.success(res.message as string, 'Succès');
		} else {
			this.toastService.error(res.message as string, 'Erreur');
		}

	}


  emitLotPpmDtl() {
    this.Lot$.next(this.ListeLotOfPPM_DTL.slice())
  }

  /*
      * emmission de donnée liste PPM HDR selon le
      * groupe de l'utilisateur connecté -
  */
  emitListPpm() {
    this.data$.subscribe(
      data => {
        this.filtrage.USR_CODE = data.USR_CODE;
      }
    );
    this.GetPpmByUser(this.filtrage)
      .then(
        (Liste: any) => {

         if(Liste.data){
          this.Liste = Liste.data;
          this.allPpmSubject.next(this.Liste);
          this.appTools.hideLoader();
         }else{
           this.appTools.hideLoader();
         }

        }
      );
  }

  /*
      * emmission de donnée liste PPM DTL par code HDR
  */
  emitListeppmdtl(codeHDR: string) {
    this.GetPpmDtlByCodeHdr(codeHDR).subscribe(
      (Liste: any) => {
        this.Liste = Liste.data;
        this.allPPmDtlSubject.next(this.Liste);
      }
    )
  }

  AddLotInPpmDtl(data: any) {
    this.ListeLotOfPPM_DTL = data;
    this.emitLotPpmDtl();
  }

  GeneratePPMHDRCODE(data: any): Observable<any> {

    // + '?ENT_CODE=' + data.ENT_CODE + '&ANNEE=' + data.ANNEE
    const url = base_url + endpoints.ePlanification.regroupementLots.genppmhdrcode
    return this.http.get<any>(url,{params:data});

  }

  AddPpm(CodeE: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.postCreatePpm
    return this.http.post<any>(url, { params: { codeEntite: CodeE } });

  }

  AddPpmHDR(data: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.postCreatePpmHdr
    return this.http.post<any>(url, data);

  }

  AddPpmDtl(data: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.postCreatePpmDtl;
    return this.http.post<any>(url, data);

  }

  GetPpmByUser(FiltreData: FILTRE){

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmbyuser;

    const promise = new Promise((resolve, reject) => {
      this.http.get(url, {
        params:{
          ENT_CODE: FiltreData.ENT_CODE ? FiltreData.ENT_CODE: '',
          LOCALISATION: FiltreData.LOCALISATION ?  FiltreData.LOCALISATION: '',
          PRMP_CODE: FiltreData.USR_CODE ?  FiltreData.USR_CODE: '',
          ANNEE: FiltreData.ANNEE ? FiltreData.ANNEE: ''
        }
      })
      .subscribe(
        (res:any)  => {
          resolve(res)
        });
    })

    return promise;

  }


  GetPpmHDR(user: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmhdrbyprmpcode
    return this.http.get(url, { params: { PRMP_CODE: user.USR_CODE } });

  }

  GetPpmHDRByCode(code: string): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmhdrbycode
    return this.http.get(url, { params: { PPM_HDR_CODE: code } });

  }



  GetBesoinByCode(code: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.determination.getBesoinByCode
    return this.http.get(url, { params: { BESOIN_CODE: code } });

  }

  GetBesoinDtlByCodeBesoin(code: any) {

    const url = base_url + endpoints.ePlanification.determination.listeBesoinDtl
    return this.http.get(url, { params: { BESOIN_CODE: code } });

  }

  GetAllPcop(): Observable<any> {

    const url = base_url + endpoints.getAllPCOP
    return this.http.get(url);

  }

  GetPpmDtlByHdrCode(code: string): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmdtlbycodehdr
    return this.http.get(url, { params: { PPM_HDR_CODE: code } });

  }


  GetPpmListFiltred(filtre: any): Observable<any> {

    // console.log(filtre);

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmbyfiltre
    return this.http.get(url, {
      params: {
        ENT_CODE: filtre.direction,
        PRMP_CODE: filtre.prmp,
        LOCALISATION: filtre.region,
        ANNEE: filtre.ANNEE
      }
    });

  }

  GetPpmListAll() {

    const url = base_url + endpoints.all_ppm
    return this.http.get(url);

  }

  GetPpmDtlByCodeHdr(code: string) {

    const url = base_url + endpoints.ePlanification.regroupementLots.getppmdtlbycodehdr
    return this.http.get(url, { params: { PPM_HDR_CODE: code } });

  }

  GetAllModePassation(): Observable<any> {

    const url = base_url + endpoints.getAllModePassation
    return this.http.get(url);

  }

  GetAllFinancement(): Observable<any> {

    const url = base_url + endpoints.ePlanification.determination.getAllFinancement
    return this.http.get(url);

  }

  GetAllTypeMarcher(): Observable<any> {

    const url = base_url + endpoints.getAllTypeMarche
    return this.http.get(url);

  }

  GetLotByBesoinCode(code: string): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.getLot
    return this.http.get(url, { params: { BESOIN_CODE: code } });

  }

  ExpedierPpm(data: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.expedierppm
    return this.http.post(url, data);

  }

  ActiverPpm(data: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.activerppm
    return this.http.post(url , data);

  }

  DesactiverPpm(data: any): Observable<any> {

    const url = base_url + endpoints.ePlanification.regroupementLots.desactiverppm
    return this.http.post(url , data);

  }

  ExportPpmPdf(data: any): Observable<api_res> {

    const url = base_url + endpoints.ePlanification.regroupementLots.exportppmpdf
    return this.http.post<api_res>(url , data);

  }

  ExportAGPMPdf(data: any) {

    const url = base_url + endpoints.ePlanification.regroupementLots.exportppmpdfagpm
    return this.http.post(url , data);

  }

  UpdatePPM(data: any) {

    const url = base_url + endpoints.ePlanification.regroupementLots.updateppmput
    return this.http.put(url , data)

  }

  getCalEtape():Observable<any>{

    const url = base_url + endpoints.ePlanification.GetCalEtape
    return this.http.get(url)
  }

  getCAPM(data:any){

    const url = base_url + endpoints.ePlanification.GetCalendrierPpm
    return this.http.get<any>(url , {params:{PPM_DTL_CODE: data}});

  }

  CreateCalendrierPpm(data: any){

    const url = base_url + endpoints.ePlanification.PostCalendrierPpm
    return this.http.post(url, data)
  }

  UpdateCalendrierPpm(data: any){

    const url = base_url + endpoints.ePlanification.PutCalendrierPpm
    return this.http.put(url, data)
  }


  getPpmDtlByCode(code:string){

      const url = base_url + endpoints.ePlanification.regroupementLots.getppmdtlbycode
      return this.http.get(url, {params:{PPM_DTL_CODE: code}})

  }

  exportCapmService(data:any){

      const url = base_url + endpoints.ePlanification.regroupementLots.exportCapmPdf;
      return this.http.post(url, data)
  }

  activerPpmDtl(data:any){

    const url = base_url + endpoints.ePlanification.regroupementLots.activerPpmDtl;
    return this.http.post(url, data)
  }

  desactiverPpmDtl(data:any){

    const url = base_url + endpoints.ePlanification.regroupementLots.desactiverPpmDtl;
    return this.http.post(url, data)
  }

  expedierPpmDtl(data:any){

    const url = base_url + endpoints.ePlanification.regroupementLots.expedierPpmDtl;
    return this.http.post(url, data)
  }


  getAllEtatForCNM(){
    const url = base_url + endpoints.CNM.getAllEtatCNM;
    return this.http.get<api_res>(url);
  }

  getAllPPMByEtat(etat:string, annee:string){

    const param = {
      ETAT:etat,
      ANNEE:annee
    }
    const url = base_url + endpoints.CNM.getAllEtatCNM;
    return this.http.get(url, {params: param});

  }

  getListPPMinProcess(code_user:any){

    const url = base_url + endpoints.CNM.getListPPMInProcess;
    return this.http.get<api_res>(url, {params :{ USER_CODE:code_user} })

  }



}

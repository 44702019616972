import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppToolsService } from '../tools/app-tools.service';
import { auto_save_interval_time } from "src/app/configs/app.json";

@Injectable({
	providedIn: 'root'
})
export class StatusService {

	editing$ = new BehaviorSubject<Boolean>(false);

	// auto-save-system TODO: [Mettre des commentaires]
	isAutoSavingStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isAutoSaving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	launchNextAutoSave$: Subject<any> = new Subject();
	canLunchAutoSave$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private currentSavingTimeout: NodeJS.Timeout;
	private activityTimeout: NodeJS.Timeout;
	// auto-save-system


	/**
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * @description Evènement de changement de status de connexionà internet, quand c'est true on est connecté,
	 * si non on est pas connecté.
	 *
	 * Attention: Ne jamais changer la valeur manuellement
	 *
	 */
	isConnectedToNetwork$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	activatedConnexionStatusMessage: ActiveToast<any> | undefined = undefined;

	private firstBeaviourNetworkInit = true;

	constructor(
		private connectionService: ConnectionService,
		private toolsService: AppToolsService,
		public toastr: ToastrService,
	) {

		this.isConnectedToNetwork$.next(window.navigator.onLine);

		this.connectionService.monitor().subscribe(isConnected => {

			this.isConnectedToNetwork$.next(isConnected);

		});


		this.isConnectedToNetwork$.subscribe(networkStatus => {

			if (networkStatus == false) {

				if (!this.firstBeaviourNetworkInit) {

					this.activatedConnexionStatusMessage = this.toastr.error("Vous êtes déconnecté d'internet 🙁", 'CONNEXION PERDUE', {
						disableTimeOut: true,
						tapToDismiss: true,
						positionClass: 'toast-top-left',
						closeButton: true
					});

				}


				// console.log("Auto Save inactive due à une déconnexion internet...");

				this.isAutoSavingStarted$.next(false);

				clearTimeout(this.currentSavingTimeout);

			} else {

				if (this.activatedConnexionStatusMessage) {

					this.toastr.clear(this.activatedConnexionStatusMessage.toastId);

				}


				if (!this.firstBeaviourNetworkInit) {

					this.toastr.success("Connexion internet rétablie 😃", "CONNECTE", {
						positionClass: 'toast-top-left',
					});

				}

			}

			this.firstBeaviourNetworkInit = false;

		});

		this.toolsService.menuNavigationChange$.subscribe(data => {

			this.reinitAutoSave();

		});

	}


	startAutoSave() {

		if (this.isAutoSavingStarted$.value == false && this.isConnectedToNetwork$.value == true) {

			this.isAutoSavingStarted$.next(true);

			this.emitNextAutoSave();

		}

	}


	emitNextAutoSave() {

		this.isAutoSaving$.next(false);

		clearTimeout(this.currentSavingTimeout);

		this.currentSavingTimeout = setTimeout(() => {

			if (this.isAutoSavingStarted$.value && this.isConnectedToNetwork$.value == true) {

				this.isAutoSaving$.next(true);
				this.launchNextAutoSave$.next();

			}

		}, 1000 * auto_save_interval_time);


	}


	public reinitAutoSave() {

		this.isAutoSavingStarted$.next(false);
		clearTimeout(this.activityTimeout);
		clearTimeout(this.currentSavingTimeout);

	}


	public stopSavingTimeout() {

		clearTimeout(this.currentSavingTimeout);

	}


	startActivityTimer() {

		// clearTimeout(this.activityTimeout);

		// this.activityTimeout = setTimeout(() => {

		// 	console.log("Auto Save inactive due à une inactivité...");

		// 	this.reinitAutoSave()

		// }, 1000 * 60 * 5);

	}

}

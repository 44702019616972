import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { DataSource } from '@angular/cdk/collections'
import { I_ElementRecours } from '../../interfaces/element-recours'
import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'app-liste-demande-element',
  templateUrl: './liste-demande-element.component.html',
  styleUrls: ['./liste-demande-element.component.scss']
})
export class ListeDemandeElementComponent implements OnInit {

	dataSource: any = new MatTableDataSource<I_ElementRecours>([])
	@ViewChild(MatSort) sort: MatSort

	@Input() traitementRecoursCode: string
  constructor() { }

  ngOnInit(): void {
		// this.dataSource.sort = this.
  }

}

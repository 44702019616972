import { ToastrService } from 'ngx-toastr';
import { I_User, User } from './../../../../../shared/models/user';
import { UserService } from 'src/app/shared/services/user/user.service';
import {
	I_API_Entite,
	I_API_Direction,
	I_API_Region,
} from './../../models/interfaces';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { base_url, endpoints } from 'src/assets/api.json';
import { api_res } from './../../../../../shared/interfaces/api';
import { C } from '../../configs/constant';

@Injectable({
	providedIn: 'root',
})
export class EplCommonService {
	private baseURL = `${base_url}`;

	constructor(
		private http: HttpClient,
		private user$: UserService,
		private toastr: ToastrService
	) {}

	public getCurrentUserInfo(): Observable<User> {
		return new Observable((obs) => {
			this.user$.connectedUser$.subscribe((userInfo) => {
				obs.next(userInfo);
				obs.complete();
			});
			this.user$.getConnectedUser();
		});
	}

	/**
	 * @description vérifier le groupe d'utilisateur actuel
	 */
	public isGroup(groupName: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.getCurrentUserInfo().subscribe(
				(user) => {
					const isSuperAdmin = this.isGroupSuperAdmin(user as I_User);
					const isPRMP = this.isGroupPRMP(user as I_User);
					const isGAC = this.isGroupGAC(user as I_User);
					const isCNM = this.isGroupCNM(user  as I_User);
					const isUGPM = this.isGroupUGPM(user as I_User);
					const isCAO = this.isGroupCAO(user  as I_User);
					const isCRM = this.isGroupCRM(user as I_User);
					const isSOA = this.isGroupSOA(user as I_User);
					const isVIP = this.isGroupVIP(user as I_User);

					switch (groupName) {
						case `SUPER_ADMIN`: {
							resolve(isSuperAdmin);
							break;
						}
						case `PRMP`: {
							resolve(isPRMP);
							break;
						}
						case `GAC`: {
							resolve(isGAC);
							break;
						}
						case `CNM`: {
							resolve(isCNM);
							break;
						}
						case `UGPM`: {
							resolve(isUGPM);
							break;
						}
						case `CAO`: {
							resolve(isCAO);
							break;
						}
						case `CRM`: {
							resolve(isCRM);
							break;
						}
						case `SOA`: {
							resolve(isSOA);
							break;
						}
						default: {
							resolve(isVIP);
						}
					}
				},
				(error) => {
					reject('error');
				}
			);
		});
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe SUPER ADMIN
	 * for local use
	 */
	public isGroupSuperAdmin(user: I_User): boolean {
		return user.USR_GROUPE === 'S-ADMIN';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe PRMP
	 */
	public isGroupPRMP(user: any): boolean {
		return user.USR_GROUPE === 'PRMP';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe GAC
	 */
	public isGroupGAC(user: I_User): boolean {
		return user.USR_GROUPE === 'GAC';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe CNM
	 */
	public isGroupCNM(user: I_User): boolean {
		return user.USR_GROUPE === 'CNM';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe UGPM
	 */
	public isGroupUGPM(user: I_User): boolean {
		return user.USR_GROUPE === 'UGPM';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe CAO
	 */
	public isGroupCAO(user: I_User): boolean {
		return user.USR_GROUPE === 'CAO';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe CRM
	 */
	public isGroupCRM(user: I_User): boolean {
		return user.USR_GROUPE === 'CRM';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe VIP
	 */
	public isGroupVIP(user: I_User): boolean {
		return user.USR_GROUPE === 'VIP';
	}

	/**
	 * @description vérifier si l'utilisateur connecté actuel est de groupe SOA
	 */
	public isGroupSOA(user: I_User): boolean {
		return user.USR_GROUPE === 'SOA';
	}

	/**
	 * POST requete
	 */
	public doPost(url: string, data: any) {
		const urlPost = `${this.baseURL}${url}`;
		return this.http.post<api_res>(urlPost, data);
	}

	/**
	 * @description Envoi requette GET
	 */
	public doGet(url: string): Observable<api_res> {
		const options = {
			headers: new HttpHeaders({
				// 'Content-Type': 'application/json'
			}),
		};
		const urlGet = `${this.baseURL}${url}`;
		return this.http.get<api_res>(urlGet, options);
	}

	public doPut(url: string, data?: any): Observable<api_res> {
		const urlPut = `${this.baseURL}${url}`;
		return this.http.put<api_res>(urlPut, data);
	}

	public getPRMPByEntite(entCode: string) {}

	/**
	 *
	 * @param {api_res} response
	 * @description Vérifier si la réponse d'une requette vers l'API est OK
	 */
	public isResponseApiOK(response: api_res): boolean {
		const { status } = response;
		return status === C.API_RESPONSE_OK;
	}

	/**
	 * TOASTR Info Message
	 */
	public showInfoMessage(message: string): void {
		this.toastr.info(message, C.TOASTR_INFO_TITLE);
	}

	/**
	 * TOASTR Success Message
	 */
	public showSuccessMessage(message: string): void {
		this.toastr.success(message, C.TOASTR_SUCCESS_TITLE);
	}

	/**
	 * TOASTR Warning Message
	 */
	public showWarningMessage(message: string): void {
		this.toastr.warning(message, C.TOASTR_WARNING_TITLE);
	}

	/**
	 * TOASTR Error Message
	 */
	public showErrorMessage(message: string): void {
		this.toastr.error(message, C.TOASTR_ERROR_TITLE);
	}

	/**
	 * TOASTR Server Error Message
	 */
	public showServerErrorMessage(): void {
		this.toastr.error(C.MSG_SERVER_ERROR, C.TOASTR_ERROR_TITLE);
	}

	/**
	 * @author Jonathan
	 * @description Convertion base64 en Blob
	 * @param base64
	 */
	public getPDFArrayBufferFromBase64(base64: string) {
		try {
			var binary_string = window.atob(base64);
			var l = binary_string.length;
			var bytes = new Uint8Array(l);
			for (var i = 0; i < l; i++) {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes;
		} catch (error) {
			return undefined;
		}
	}

	/**
	 * @author Jonathan
	 * @description Téléchargement d'un fichier à partir d'un blob de type Uint8Array
	 * @param blob Contenu du fichier en Blob
	 * @param filename Nom du fichier avec l'extension
	 * @param fileType Type du fichier (application/pdf)
	 */
	public downloadFile(
		blob: Uint8Array | undefined,
		filename: string = 'fichier.pdf',
		fileType: string = 'application/pdf'
	) {
		if (blob !== undefined) {
			const blobWithType = new Blob([blob], { type: fileType });
			let blobUrl: string = window.URL.createObjectURL(blobWithType);

			let link: any = window.document.createElement('a');

			if ('download' in link) {
				link.setAttribute('href', blobUrl);

				link.setAttribute('download', filename);

				let event: any = window.document.createEvent('MouseEvents');
				event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null );
				link.dispatchEvent(event);
			}
		} else {
			this.toastr.error('Téléchargement échoué');
		}
	}
}

<div>
	<pdf-viewer class="main-spinner" [show-all]="true" [stick-to-page]="true" *ngIf="pdfFile$.value!=undefined" [src]="pdfFile$.value" [render-text]="true">
			<app-spinner-layouts *ngIf="loadingPdfFile"></app-spinner-layouts>
	</pdf-viewer>
	<div class="card-action iconpositionf2">

			<button matTooltip="Exporter le fichier" matTooltipPosition="right" class="btn btn-cancel-form fIconeStyleAction bottomannulmarg rectif-paddingBtnIcone" (click)='downloadFile()'>
				<span  class="armp-ico-pdf-table"></span>
			</button>

			<button matTooltip="fermer" matTooltipPosition="right" class="btn btn-cancel-form fIconeStyleAction bottomannulmarg" (click)="close()">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
							<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
					</svg>
			</button>

	</div>
</div>

<div *ngIf="fileNotExist && !loadingPdfFile">
	Aucun fichier n'a été trouvé.
</div>

<div class="center-foot" [ngClass]="{
				'connected': statusService.isConnectedToNetwork$.value,
				'no-connected': !statusService.isConnectedToNetwork$.value
			}">
	<div>
		Statut internet : <span class="statut">{{statusService.isConnectedToNetwork$.value ? "Connecté": "Non connecté"}}</span>
		<span  *ngIf="statusService.isConnectedToNetwork$.value"><span class="connect"><span class="iconify" data-icon="carbon:connection-signal"></span></span></span>
		<span  *ngIf="!statusService.isConnectedToNetwork$.value"><span class="not-connect"><span class="ico-warn">!</span></span></span>
	</div>
</div>

import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "./converter";

@JsonObject("PPM")
export class PPM {

	@JsonProperty("PPM_HDR_CODE", String)
	PPM_HDR_CODE?: String = undefined;

	@JsonProperty("PPM_HDR_ADRESSE_PRMP", String)
	PPM_HDR_ADRESSE_PRMP?: String = undefined;

	@JsonProperty("PPM_HDR_ANNEE", String)
	PPM_HDR_ANNEE?: String = undefined;

  @JsonProperty("PPM_HDR_INIT_DATE", DateConverter)
  PPM_HDR_INIT_DATE?: DateConverter = undefined;

  @JsonProperty("PPM_HDR_DATE_MAJ", DateConverter)
  PPM_HDR_DATE_MAJ?: DateConverter = undefined;

  @JsonProperty("PPM_HDR_USER_MAJ", String)
  PPM_HDR_USER_MAJ?: String = undefined;

  @JsonProperty("PPM_HDR_ETAT_MAJ", String)
  PPM_HDR_ETAT_MAJ?: String = undefined;

  @JsonProperty("PPM_HDR_ETAT", String)
  PPM_HDR_ETAT?: String = undefined;

  @JsonProperty("PRMP_CODE", String)
  PRMP_CODE?: String = undefined;

  @JsonProperty("PRMP_NOM", String)
  PRMP_NOM?: String = undefined;

  @JsonProperty("PRMP_PRENOM", String)
  PRMP_PRENOM?: String = undefined;

  @JsonProperty("PRMP_TEL", String)
  PRMP_TEL?: String = undefined;

  @JsonProperty("PRMP_MAIL", String)
  PRMP_MAIL?: String = undefined;

  @JsonProperty("PRMP_ADRESSE", String)
  PRMP_ADRESSE?: String = undefined;

  @JsonProperty("PRMP_CREATION_DATE", DateConverter)
  PRMP_CREATION_DATE?: DateConverter = undefined;

  @JsonProperty("PRMP_CREATION_USER", String)
  PRMP_CREATION_USER?: String = undefined;

  @JsonProperty("PRMP_ACTE_DATE", DateConverter)
  PRMP_ACTE_DATE?: DateConverter = undefined;

  @JsonProperty("PRMP_CODE_PARENT", String)
  PRMP_CODE_PARENT?: String = undefined;

  @JsonProperty("PRMP_ACTE_NOMINATION", String)
  PRMP_ACTE_NOMINATION?: String = undefined;

  @JsonProperty("ENT_CODE", String)
  ENT_CODE?: Number = undefined;

  @JsonProperty("ENT_CAT_CODE", String)
  ENT_CAT_CODE?: String = undefined;

  @JsonProperty("ENT_ABREVIATION", String)
  ENT_ABREVIATION?: String = undefined;

  @JsonProperty("ENT_LIBELLE", String)
  ENT_LIBELLE?: String = undefined;

  @JsonProperty("ENT_CREATION_DATE", DateConverter)
  ENT_CREATION_DATE?: DateConverter = undefined;

  @JsonProperty("ENT_CREATION_USER", String)
  ENT_CREATION_USER?: String = undefined;

  @JsonProperty("ENT_ADRESSE", String)
  ENT_ADRESSE?: String = undefined;

  @JsonProperty("ENT_MAIL", String)
  ENT_MAIL?: String = undefined;

  @JsonProperty("ENT_TEL", String)
  ENT_TEL?: String = undefined;

  @JsonProperty("ENT_CODE_MERE", String)
  ENT_CODE_MERE?: Number = undefined;

  @JsonProperty("ENT_ETAT_CODE", String)
  ENT_ETAT_CODE?: String = undefined;

  @JsonProperty("ENT_DESCRIPTION", String)
  ENT_DESCRIPTION?: String = undefined;

  @JsonProperty("REG_CODE", String)
  REG_CODE?: String = undefined;

  @JsonProperty("REG_LIBELLE", String)
  REG_LIBELLE?: String = undefined;

  @JsonProperty("REG_CREATION_DATE", DateConverter)
  REG_CREATION_DATE?: DateConverter = undefined;

  @JsonProperty("REG_CREATION_USER", String)
  REG_CREATION_USER?: String = undefined;

  @JsonProperty("PPM_DTL_CODE", String)
  PPM_DTL_CODE?: String = undefined;

  @JsonProperty("PPM_DTL_OBJET", String)
  PPM_DTL_OBJET?: String = undefined;

  @JsonProperty("PPM_DTL_CREATION_DATE", DateConverter)
  PPM_DTL_CREATION_DATE?: DateConverter = undefined;

  @JsonProperty("PPM_DTL_CREATION_USER", String)
  PPM_DTL_CREATION_USER?: String = undefined;

  @JsonProperty("PPM_DTL_NB_LOT", String)
  PPM_DTL_NB_LOT?: Number = undefined;

  @JsonProperty("PPM_DTL_NB_LOT_PRETENDU", String)
  PPM_DTL_NB_LOT_PRETENDU?: Number = undefined;

  @JsonProperty("PPM_DTL_MONTANT", String)
  PPM_DTL_MONTANT?: String = undefined;

  @JsonProperty("TYPE_CODE_FK", String)
  TYPE_CODE_FK?: String = undefined;

  @JsonProperty("MODE_PASS_CODE_FK", String)
  MODE_PASS_CODE_FK?: String = undefined;

  @JsonProperty("CAPM_RUBRIQUE_CODE_FK", String)
  CAPM_RUBRIQUE_CODE_FK?: String = undefined;

  @JsonProperty("PPM_DTL_LANCEMENT_DATE", DateConverter)
  PPM_DTL_LANCEMENT_DATE?: DateConverter = undefined;

  @JsonProperty("PPM_DTL_OUVERTURE_DATE", DateConverter)
  PPM_DTL_OUVERTURE_DATE?: DateConverter = undefined;

  @JsonProperty("PPM_DTL_ATTRIBUTION_DATE", DateConverter)
  PPM_DTL_ATTRIBUTION_DATE?: DateConverter = undefined;

  @JsonProperty("MODE_PASS_CODE", String)
  MODE_PASS_CODE?: String = undefined;

  @JsonProperty("MODE_PASS_LIBELLE", String)
  MODE_PASS_LIBELLE?: String = undefined;

	@JsonProperty("MOD_EXE_FK", String)
  MOD_EXE_FK?: String = undefined;

  @JsonProperty("TYPE_LIBELLE", String)
  TYPE_LIBELLE?: String = undefined;

  @JsonProperty("LOT_SOA", String)
  LOT_SOA?: String = undefined;

  @JsonProperty("LOT_PCOP", String)
  LOT_PCOP?: String = undefined;

  @JsonProperty("LOT_MONTANT", String)
  LOT_MONTANT?: String = undefined;

  @JsonProperty("PPM_DTL_ETAT", String)
  PPM_DTL_ETAT?: String = undefined;

  @JsonProperty("PPM_DTL_DESCRIPTION", String)
  PPM_DTL_DESCRIPTION?: String = undefined;

  @JsonProperty("FINANCEMENT", String)
  FINANCEMENT?: String = undefined;

  @JsonProperty("S_PUB_CODE", String)
  S_PUB_CODE?: String = undefined;

  @JsonProperty("S_PUB_LIBELLE", String)
  S_PUB_LIBELLE?: String = undefined;

  @JsonProperty("PPM_PUB_CODE", String)
  PPM_PUB_CODE?: String = undefined;

  @JsonProperty("PPM_HDR_FK", String)
  PPM_HDR_FK?: String = undefined;

  @JsonProperty("SUPPORT_PUB_FK", String)
  SUPPORT_PUB_FK?: String = undefined;

    @JsonProperty("S_PUB_DATE", DateConverter)
    S_PUB_DATE?: DateConverter = undefined;

  @JsonProperty("S_PUB_HEURE", DateConverter)
  S_PUB_HEURE?: DateConverter = undefined;
}


export interface I_PPM {

	PPM_HDR_CODE: String ,

	PPM_HDR_ADRESSE_PRMP: String | null ,

	PPM_HDR_ANNEE: String | null ,

	PPM_HDR_INIT_DATE: String | null ,

	PPM_HDR_DATE_MAJ: String | null ,

	PPM_HDR_USER_MAJ: String | null ,

	PPM_HDR_ETAT_MAJ: String | null ,

	PPM_HDR_ETAT: String | null ,

	PRMP_CODE: String | null ,

	PRMP_NOM: String | null ,

	PRMP_PRENOM: String | null ,

	PRMP_TEL: String | null ,

	PRMP_MAIL: String | null ,

	PRMP_ADRESSE: String | null ,

	PRMP_CREATION_DATE: String | null ,

	PRMP_CREATION_USER: String | null ,

	PRMP_ACTE_DATE: String | null ,

	PRMP_CODE_PARENT: String | null ,

	PRMP_ACTE_NOMINATION: String | null ,

	ENT_CODE: Number ,

	ENT_CAT_CODE: String | null ,

	ENT_ABREVIATION: String | null ,

	ENT_LIBELLE: String | null ,

	ENT_CREATION_DATE: String | null ,

	ENT_CREATION_USER: String | null ,

	ENT_ADRESSE: String | null ,

	ENT_MAIL: String | null ,

	ENT_TEL: String | null ,

	ENT_CODE_MERE: Number ,

	ENT_ETAT_CODE: String | null ,

	ENT_DESCRIPTION: String | null ,

	REG_CODE: String | null ,

	REG_LIBELLE: String | null ,

	REG_CREATION_DATE: String | null ,

	REG_CREATION_USER: String | null ,

	PPM_DTL_CODE: String | null ,

	PPM_DTL_OBJET: String | null ,

	PPM_DTL_CREATION_DATE: String | null ,

	PPM_DTL_CREATION_USER: String | null ,

	PPM_DTL_NB_LOT: Number ,

	PPM_DTL_NB_LOT_PRETENDU: Number ,

	PPM_DTL_MONTANT: String | null ,

	TYPE_CODE_FK: String | null ,

	MODE_PASS_CODE_FK: String | null ,

	CAPM_RUBRIQUE_CODE_FK: String | null ,

	PPM_DTL_LANCEMENT_DATE: String | null ,

	PPM_DTL_OUVERTURE_DATE: String | null ,

	PPM_DTL_ATTRIBUTION_DATE: String | null ,

	MODE_PASS_CODE: String | null ,

	MODE_PASS_LIBELLE: String | null ,

	TYPE_LIBELLE: String | null ,

	LOT_SOA: String | null ,

	LOT_PCOP: String | null ,

	LOT_MONTANT: String | null ,

	PPM_DTL_ETAT: String | null ,

	PPM_DTL_DESCRIPTION: String | null ,

	FINANCEMENT: String | null ,

	S_PUB_CODE: String | null ,

	S_PUB_LIBELLE: String | null ,

	PPM_PUB_CODE: String | null ,

	PPM_HDR_FK: String | null ,

	SUPPORT_PUB_FK: String | null ,

	S_PUB_DATE: String | null ,

	S_PUB_HEURE: String | null ,

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'conversion'
})
export class ConversionPipe implements PipeTransform {

	transform(
		value: any,
		arg: 'castDateFrom-dd/mm/yyyy_hh:mm:ss' | 'castDateFrom-yyyy-mm-dd'
	): any {
		if (value) {

			switch (arg) {

				case `castDateFrom-dd/mm/yyyy_hh:mm:ss`: {

					let day = value.includes(' ') ? value.split(' ')[0].split('/')[0] : value.split('/')[0];
					day = parseInt(day);
					let month = value.includes(' ') ? value.split(' ')[0].split('/')[1] : value.split('/')[1];
					month = parseInt(month) - 1;
					let year = value.includes(' ') ? value.split(' ')[0].split('/')[2] : value.split('/')[2];
					year = parseInt(year);

					return new Date(year, month, day);

				}

				case `castDateFrom-yyyy-mm-dd`: {

					const year = parseInt(value.split('-')[0]);
					const month = parseInt(value.split('-')[1]) - 1;
					const day = parseInt(value.split('-')[2]);

					return new Date(year, month, day);

				}
			}
		} else {

			return null;

		}
	}

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortailComponent } from './pages/portail/portail.component';

import { AuthLayoutComponent } from './../../layouts/auth-layout/auth-layout.component';
import { InscriptionLayoutComponent } from './../../layouts/inscription-layout/inscription-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { OpecoInscriptionComponent } from './pages/opeco-inscription/opeco-inscription.component';
import { UserVisualisationComponent } from './pages/user-visualisation/user-visualisation.component';

const routes: Routes = [
  {
    path: '', component: AuthLayoutComponent,
    children: [
        { path: '', component: PortailComponent }
    ]
  },
  {
    path: 'login/civil', component: AuthLayoutComponent,
    children: [
        { path: '', component: LoginComponent }
    ]
  },
  {
    path: 'login/public', component: AuthLayoutComponent,
    children: [
        { path: '', component: LoginComponent }
    ]
  },
  {
    path: 'login/prive', component: AuthLayoutComponent,
    children: [
        { path: '', component: LoginComponent }
    ]
  },
  {
    path: 'forgot-password', component: AuthLayoutComponent,
    children: [
        { path: '', component: ForgotPasswordComponent }
    ]
  },
  {
    path: 'inscription', component: InscriptionLayoutComponent,
    children: [
        { path: '', component: InscriptionComponent }
    ]
  },
	{
    path: 'opeco-inscription', component: InscriptionLayoutComponent,
    children: [
        { path: '', component: OpecoInscriptionComponent }
    ]
  },
	{
		path: 'user-visualisation', component: UserVisualisationComponent,
	}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthentificationRoutingModule { }

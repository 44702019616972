import { Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
	title = 'ARMP-FRONT';
	timedOut = false;
  lastPing?: Date = undefined;
	timeoutValue: number = 60 * 30;

  constructor(private idle: Idle, private keepalive: Keepalive, private userSvc: UserService) {
		idle.setIdle(1);
    idle.setTimeout(this.timeoutValue);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      // userSvc.logOut();
    });

    idle.onIdleStart.subscribe(() => {
        // console.log('IDLE Started');
    });

    idle.onTimeoutWarning.subscribe((countdown: number) => {
			if (countdown <= 5) {
				console.log(`En cours de timeout ${countdown}`);
			}
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
	}

  ngOnInit(): void {
  }

	reset() {
    this.idle.watch();
    this.timedOut = false;
  }

}

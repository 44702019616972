import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OtpService } from '../../services/otp/otp.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {

  tick : any;
  timeLeft: number = 300;

  nombreTentative: number;
  tentative : any;

  code = new FormGroup({
    digit0 : new FormControl(''),
    digit1 : new FormControl(''),
    digit2 : new FormControl(''),
    digit3 : new FormControl(''),
    digit4 : new FormControl(''),
    digit5 : new FormControl(''),
  })

  Code:any = {
    d0: '',
    d1: '',
    d2: '',
    d3: '',
    d4: '',
    d5: ''
  }

  verification: boolean = true; 
  autorised: boolean = false;
  error: boolean = false;
  wait: boolean = false;

  info: boolean = true;
  renvoyer: boolean = false;

  close:boolean = false;

  constructor(
    public toaster: ToastrService,
    private OtpService: OtpService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
  }

  ngOnInit(): void {
    this.GetPar();
  }

  GetPar(){
    if (this.data.indice == 1) {
      
      this.OtpService.GetParamOtpLogin().subscribe(
        param => {
          if(param){
            // //console.log(param);
            // //console.log(param.data[0].TENTATIVE, ' et ', param.data[0].MINUTE);
            this.nombreTentative = param.data[0].TENTATIVE as number;
            const minute = param.data[0].MINUTE as number;
            // //console.log(minute)
            this.timeLeft = minute*60;
            // //console.log('seconde',this.timeLeft);
            this.Timer();
            this.verifyTentative();
          }
        }
      );
    } else {
        
      this.OtpService.GetParamOtp().subscribe(
        param => {
          if(param){
            // //console.log(param);
            // //console.log(param.data[0].TENTATIVE, ' et ', param.data[0].MINUTE);
            this.nombreTentative = param.data[0].TENTATIVE as number;
            const minute = param.data[0].MINUTE as number;
            // //console.log(minute)
            this.timeLeft = minute*60;
            // //console.log('seconde',this.timeLeft);
            this.Timer();
            this.verifyTentative();
          }
        }
      );
    }
  }

  Timer(){
    const source = timer(0,1000);
    const timeinterval = source.subscribe((t:any) => {
      let chrono = this.timeLeft - t;
      let timetoshow = this.timeLeft*3/5; 
      if (chrono > 0) this.tick = this.toHHMMSS(chrono);
      else this.tick = this.toHHMMSS(0);

      if (chrono < timetoshow) {
        this.info = false;
        this.renvoyer = true;
      }
    });
  }

  toHHMMSS (secs:any){
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
  }

  verifyTentative(){
    // //console.log('Firy zao :',this.nombreTentative);
    
    if (this.nombreTentative > 1 ){
      this.tentative = this.nombreTentative.toString() + " tentatives";
    } else if (this.nombreTentative == 1 ) {
      this.tentative = this.nombreTentative.toString() + " tentative";
    } else {
      // //console.log('Tafiditra rehefa :',this.nombreTentative);
      this.close = true;
    }
  }

  onDigitInput(event:any){

    let element
    if (event.code == 'ArrowRight') {
      element = event.srcElement.nextElementSibling;
    }
    
    if (event.code == 'ArrowLeft') {
      element = event.srcElement.previousElementSibling;
    }

    if (event.srcElement.value.length >= event.srcElement.maxLength) {
      element = event.srcElement.nextElementSibling;
    }

    if (event.srcElement.value.length > event.srcElement.maxLength) {
      this.Code = [];
      let j = [];
      let id: string;
      let n: any;
      for (let i = 0; i <event.srcElement.value.length; i++){
        j.push({ digit: event.srcElement.value[i]});
         n = i+1;
      }
      id =  n.toString;
      element = document.getElementById(id);
      // //console.log(j)
      this.Code.d0 = j[0]?.digit as number;
      this.Code.d1 = j[1]?.digit as number;
      this.Code.d2 = j[2]?.digit as number;
      this.Code.d3 = j[3]?.digit as number;
      this.Code.d4 = j[4]?.digit as number;
      this.Code.d5 = j[5]?.digit as number;
      // //console.log(this.Code);
      
    }

    if (event.code == 'Backspace' && event.srcElement.value.length < event.srcElement.maxLength){
      element = event.srcElement.previousElementSibling;
    }

    if(element == null)
        return;
    else
        element.focus();
    }

  onSubmit(){

    const n0 =  this.code.value.digit0.toString();
    const n1 =  this.code.value.digit1.toString();
    const n2 =  this.code.value.digit2.toString();
    const n3 =  this.code.value.digit3.toString();
    const n4 =  this.code.value.digit4.toString();
    const n5 =  this.code.value.digit5.toString();
    const Value = n0 + n1 + n2 + n3 + n4 + n5;
    this.verify(Value);
    this.verification = false;
    this.wait = true;
    this.nombreTentative = this.nombreTentative - 1;
    this.verifyTentative();

    this.Code = {
      d0: '',
      d1: '',
      d2: '',
      d3: '',
      d4: '',
      d5: ''
    }

  }

  verify(code:string){

    if (this.data.indice == 1) {

      let getData: any = {
        Code : code,
        Login : this.data.login,
        Indice : this.data.indice
      }
  
      this.OtpService.VerifierCodeLogin(getData).subscribe(
        res => {
          if (res) {
            // //console.log('Response after verification : ',res);
            if (res.autorisation) {
              this.wait = false;
              this.autorised = res.autorisation;
              this.toaster.success(res.message, 'Succès')
            } else {
              this.wait = false;
              this.error = true;
              this.toaster.error(res.message, 'Erreur')
            }
          }
        }
      );
      
    } else {

      let getData: any = {
        Code : code,
        Indice : this.data.indice
      }
  
      this.OtpService.VerifierCode(getData).subscribe(
        res => {
          if (res) {
            // //console.log('Response after verification : ',res);
            if (res.autorisation) {
              this.wait = false;
              this.autorised = res.autorisation;
              this.toaster.success(res.message, 'Succès')
            } else {
              this.wait = false;
              this.error = true;
              this.toaster.error(res.message, 'Erreur')
            }
          }
        }
      );
      
    }

  }

  renvoyerCode(){

    this.verification = false;
    this.error = false;
    this.wait = true;

    this.OtpService.SendCodebyMail(this.data.indice).subscribe(
      res => { 
        if (res) {
          this.OtpService.OtpToaster(res);
          this.verifyTentative();
          this.actualiser();
          this.actualiserTimer();
        }
      }
    );

  }

  reessayer(){
    this.error = false;
    this.wait = true;
    setTimeout(()=>{
      this.actualiser();
    }, 1200);
  }

  actualiser(){
    this.verification = true; 
    this.autorised = false;
    this.error = false;
    this.wait= false;

    this.info = true;
    this.renvoyer = false;
  }

  actualiserTimer(){
    this.timeLeft = 300;
    this.Timer();
  }

  information(){
    if (this.nombreTentative < 1 && this.close == true ) {
      this.toaster.info('Vous avez epuisé toutes vos tentatives.', 'Information')
    }
  }
    
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user/user.service';
import { hide_browser_route } from "src/app/configs/app.json";

import { SessionService } from "src/app/shared/services/session/session.service";

import { secteur } from 'src/app/configs/storage_key.json';
import { secteur_prive, secteur_public, secteur_civil } from 'src/app/configs/parametre.json';
import { Router } from '@angular/router';
import { InscriptionOpecoService } from '../../services/inscription-opeco.service';

@Component({
	selector: 'app-form-login',
	templateUrl: './form-login.component.html',
	styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit {

	private form_builder = new FormBuilder();
	public login_form: FormGroup;
	user_secteur: any = null;
	secteur_prive = secteur_prive;

	hide_browser_route:any;

	constructor(public userService: UserService,
		private sessionService: SessionService,
		private router: Router,
		public inscriptionOpecoService: InscriptionOpecoService
		) {

		this.hide_browser_route = hide_browser_route;

		this.login_form = this.form_builder.group({
			identity: ['', Validators.required],
			password: ['', Validators.required]
		});

	}


	get actiRoute() { let segment = this.router.url.split('/'); let len = segment.length; return segment[len-1];  }

	ngOnInit(): void {

		this.user_secteur = this.sessionService.getItem( secteur );
		// console.log(this.user_secteur);
		if (this.user_secteur == secteur_prive) {

			this.login_form.controls["identity"].setValidators([Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]);

		}

		if (!this.user_secteur){
			if (this.actiRoute == secteur_prive) {
				this.sessionService.setItem(secteur, secteur_prive);
				this.login_form.controls["identity"].setValidators([Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]);

			} else if (this.actiRoute == secteur_public) {
				this.sessionService.setItem(secteur, secteur_public);
				this.login_form.controls["identity"].setValidators([Validators.required]);

			} else {
				this.sessionService.setItem(secteur, secteur_prive);
				this.login_form.controls["identity"].setValidators([Validators.required]);

			}
			this.user_secteur = this.sessionService.getItem( secteur );
			console.log( [secteur_prive, this.user_secteur] );
		}


	}

	get loginFormControl() {
		return this.login_form.controls;
	}


	get identityValue() {

		return this.login_form.controls["identity"].value;

	}

	submit_login() {
		// console.log( [secteur_prive, this.user_secteur] );
		if ( this.user_secteur == secteur_prive ) {

			this.userService.verifyOpeco(this.login_form.value);

		} else if (this.user_secteur == secteur_public) {

			this.userService.login(this.login_form.value);

		} else {

			this.userService.loginCivil(this.login_form.value);

		}

	}

}

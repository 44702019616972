<ul class="breadcrumbs">
  <li *ngFor="let menu_item of menu">
    <a [routerLink]="[menu_item.url]" [skipLocationChange]="hide_browser_route">{{ menu_item.libelle }}</a>
  </li>
  <!--     <li>
    <li *ngFor="let menu_item of menu">
        <a [routerLink]="['', menu_item.url]" *ngIf="!menu_item.actif" [skipLocationChange]="hide_browser_route">{{ menu_item.libelle }}</a>
        <a *ngIf="menu_item.actif">{{ menu_item.libelle }}</a>
    </li>
<!--     <li>
        <a href="#">Liste demande</a>
    </li>
 -->
</ul>

import { Component, OnInit } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalVisualisationPreRequisComponent } from 'src/app/modules/e-planification/features/regroupement-lots/modal-visualisation-pre-requis/modal-visualisation-pre-requis.component';
import { I_PARAM_PIECE } from 'src/app/shared/interfaces/cnm';
import { BehaviorCnmService } from 'src/app/shared/services/cnm/behavior-cnm.service';
import { CnmService } from 'src/app/shared/services/cnm/cnm.service';

@Component({
  selector: 'app-table-param-piece',
  templateUrl: './table-param-piece.component.html',
  styleUrls: ['./table-param-piece.component.scss']
})
export class TableParamPieceComponent implements OnInit {

  PARAM_PIECE:I_PARAM_PIECE[]

  displayedColumns: string[] = [];

	tables = [0];

  dataSource: any[] = [

    {CODE_PIECE:'PAGP',LIBELLE_PIECE: "Projet d’AGPM et/ou PPM non daté ni signé", IS_UPLOAD_PIECE:false , IS_UPLOAD_MULTIPLE: false, IS_OBLIGATOIRE:false, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},

  ];

  constructor(
    private CnmService:CnmService,
    public BehaviorCnm$:BehaviorCnmService,
    private toastr : ToastrService,
    public dialog : MatDialog
  ) { }

  ngOnInit() {
		switch (this.BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT) {
      case 'PRMP':
          this.getParamPiece()

          if(this.BehaviorCnm$.data_for_cnm$.value?.IS_MAJ){

            this.displayedColumns = ['libelle', 'maj_fichier', 'actuelle']
            this.PARAM_PIECE.map((el:any) => {
                this.dataSource.push(el);
            })

          }else{
            this.displayedColumns = ['libelle', 'actuelle']
            this.PARAM_PIECE.filter((el:any) => {
              if(el.CODE_PIECE != 3 &&
                el.CODE_PIECE != 4 &&
                el.CODE_PIECE != 5 )
                this.dataSource.push(el);
            })
          }
          break;
      default:
        break;
    }
  }


  /** Whether the button toggle group contains the id as an active value. */
  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }

  onUploadfile(pdfFile: any, piece:number) {

    let fileList:FileList =  pdfFile.target.files;
      let fichier:File = fileList[0]
      let reader = new FileReader();
      reader.readAsDataURL(fichier);
      reader.onload = () => {

        const fileToString = reader.result?.toString()
        const file = fileToString?.split(',')[1]

        this.CnmService.uploadPJ(file, this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE as string , piece).subscribe((res:any) => {

            if(res.status){
              this.toastr.success(res.message, 'Succès')
            }

        })

      }

  }

  onDownloadfile(){


  }


  // Modal de visualisation pre-requis
  openDialog(type_doc:string){

    const data = this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE ;
    this.dialog.open(ModalVisualisationPreRequisComponent,{
      width:'1074px',
      height:'90%',
      data:{code_ppm:data, type:type_doc}
    });
    // this.dialogRef.close("CLOSE_MODAL");
  }


  getParamPiece(){
		// this.CnmService.getListParamPiece(this.DATA.ppm_code).subscribe((res:any) =>{
		//   this.PARAM_PIECE =  res.data
		//   console.log(res)
		// })
		this.PARAM_PIECE = [
			{CODE_PIECE:1,LIBELLE_PIECE: "Fiche de Présentation pour le PPM/AGPM", IS_UPLOAD_PIECE:true , IS_UPLOAD_MULTIPLE: false, IS_OBLIGATOIRE:true, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},
			{CODE_PIECE:2,LIBELLE_PIECE: "Décision du Gouvernement ou autre document justificatif", IS_UPLOAD_PIECE:true , IS_UPLOAD_MULTIPLE: false, IS_OBLIGATOIRE:false, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},
			{CODE_PIECE:3,LIBELLE_PIECE: "Tous les Avis Général des Passations de Marchés antérieurs signés par la PRMP avec au moins une copie de la publication dans les journaux", IS_UPLOAD_PIECE:false , IS_UPLOAD_MULTIPLE: true, IS_OBLIGATOIRE:true, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},
			{CODE_PIECE:4,LIBELLE_PIECE: "Tous les Plans de Passation des Marchés antérieurs signés par la PRMP", IS_UPLOAD_PIECE:false , IS_UPLOAD_MULTIPLE: true, IS_OBLIGATOIRE:true, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},
			{CODE_PIECE:5,LIBELLE_PIECE: "Les PV de la Commission des Marchés antérieurs y afférent", IS_UPLOAD_PIECE:false , IS_UPLOAD_MULTIPLE: true, IS_OBLIGATOIRE:true, TYPE_DOSSIER_PIECE:'PPM', STATUS:'OUI/NON'},
		]

	}


}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InscriptionOpeco } from '../../models/inscription-opeco';
import { InscriptionOpecoService } from '../../services/inscription-opeco.service';

@Component({
  selector: 'app-inscription-opeco',
  templateUrl: './inscription-opeco.component.html',
  styleUrls: ['./inscription-opeco.component.scss']
})
export class InscriptionOpecoComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  fgInscription: FormGroup;

  constructor(
    private inscriptionOpecoService: InscriptionOpecoService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.fgInscription.valueChanges
      .subscribe((values: InscriptionOpeco) => {
        this.inscriptionOpecoService.isValidForm.next(this.fgInscription.valid);
        if (this.fgInscription.valid) {
          this.inscriptionOpecoService.data.next(values);
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.fgInscription = new FormGroup({
      nif: new FormControl('', [Validators.required]),
      nimp: new FormControl('', [Validators.required]),
      rib: new FormControl('', [Validators.required]),
      login: new FormControl('', [Validators.required]),
      key: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  get nif() { return this.fgInscription.get('nif') }
  get nimp() { return this.fgInscription.get('nimp') }
  get rib() { return this.fgInscription.get('rib') }
  get login() { return this.fgInscription.get('login') }
  get key() { return this.fgInscription.get('key') }
  get password() { return this.fgInscription.get('password') }

}

import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session/session.service';

@Component({
  selector: 'app-egp-auth',
  templateUrl: './egp-auth.component.html',
  styleUrls: ['./egp-auth.component.scss']
})
export class EgpAuthComponent implements OnInit {

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

}

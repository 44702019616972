import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OTPComponent } from '../../pages/otp/otp.component';
import { OtpService } from './otp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtpModalService {

  public otpSubject = new Subject();

  constructor(
    public OTPdialog: MatDialog,
    private otpservice: OtpService,
    public spinner: NgxSpinnerService
    ) { }

  openOTPModal(indice:any):any {

		this.spinner.show();

    this.otpservice.SendCodebyMail(indice).subscribe(
      (ver: any) => {
        if (ver) {

          this.otpservice.OtpToaster(ver);

          //console.log('Sent mail :', ver);

          this.spinner.hide();

          const otpRef = this.OTPdialog.open(OTPComponent, {
            data: { indice: indice },
            disableClose: true,
            width: "380px",
            panelClass: "custom-container"
          });

          otpRef.afterClosed().subscribe(
            (verify: any) => {

              if (verify) {
                this.otpSubject.next(verify)
              }

            }
          );


        }
      }
    );

  }

  openLoginOTPModal(userdata: any, indice:any):any {
    //console.log(userdata);

    this.spinner.show();
    
    let data = {
      LOGIN: userdata.LOGIN,
      NOM: userdata.OPER_NOM,
      PRENOM: userdata.OPER_PRENOM,
      EMAIL:  userdata.OPER_MAIL,
      INDICE: indice
    }

    this.otpservice.SendCodeLoginbyMail(data).subscribe(
      (ver: any) => {
        if (ver) {
    
          this.otpservice.OtpToaster(ver);
    
          //console.log('Sent mail :', ver);
    
          this.spinner.hide();
    
          const otpRef = this.OTPdialog.open(OTPComponent, {
            data: { indice: indice, login: userdata.LOGIN },
            disableClose: true,
            width: "380px",
            panelClass: "custom-container"
          });
    
          otpRef.afterClosed().subscribe(
            (verify: any) => {
    
              if (verify) {
                this.otpSubject.next(verify)
              }
              
            }
          );
    
    
        }
      }
    );

  }
  
}

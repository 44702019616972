import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { CnmService } from 'src/app/shared/services/cnm/cnm.service';
import { StatusService } from 'src/app/shared/services/status/status.service';
import { messages } from "src/app/configs/constants.json";


@Component({
  selector: 'app-downloas-pr',
  templateUrl: './downloas-pr.component.html',
  styleUrls: ['./downloas-pr.component.scss']
})
export class DownloasPrComponent implements OnInit {


  constructor(
    public apiService: ApiService,
    public cnmService: CnmService,
    public statusService: StatusService,
    public toastr: ToastrService
  ) { }


  ngOnInit(): void {

  }


  /**
   * Génération/Récupération de l'api de download
   */
  getDownloadRequest() {

    return this.apiService.downloadPreRequis();

  }

}

<div class="container auth-container">
    <app-back-navigation></app-back-navigation>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-6">
          <app-egp-auth></app-egp-auth>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 sect-right-login">
            <app-main-logo></app-main-logo>
            <app-form-login></app-form-login>
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EssentialsDataLoaderResolver } from './shared/resolvers/essentialsDataLoader/essentials-data-loader.resolver';
import { AuthGuard } from './shared/services/guard/auth.guard';
import { emplacement } from "src/app/configs/constants.json";


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/authentification/authentification.module').then(m => m.AuthentificationModule)
	},
	{
		path: 'welcome',
		loadChildren: () => import('./modules/welcome-page/welcome-page.module').then(m => m.WelcomePageModule),
		canLoad: [AuthGuard],
		data: {
			breadcrumb:[	{
				label: emplacement.welcome,
				url: '/welcome'
			}]
		}
	},
	{
		path: 'e-planification',
		loadChildren: () => import('./modules/e-planification/e-planification.module').then(m => m.EPlanificationModule),
		canLoad: [AuthGuard],
	},
	{
		path: 'e-dossier',
		loadChildren: () => import('./modules/e-dossier/e-dossier.module').then(m => m.EDossierModule),
		canLoad: [AuthGuard],
		resolve: {
			essentialsData: EssentialsDataLoaderResolver
		},
		data: {
			breadcrumb:[	{
				label: emplacement.welcome,
				url: '/welcome'
			}]
		}
	},
	{
		path: 'e-evaluation',
		loadChildren: () => import('./modules/e-evaluation/e-evaluation.module').then(m => m.EEvaluationModule),
		canLoad: [AuthGuard],
	},
	{
		path: 'e-soummission',
		loadChildren: () => import('./modules/e-soummission/e-soummission.module').then(m => m.ESoummissionModule),
		canLoad: [AuthGuard],
	},
	{
		path: 'e-paiement',
		loadChildren: () => import('./modules/e-paiement/e-paiement.module').then(m => m.EPaiementModule),
	},
	{
		path: 'e-contrat',
		loadChildren: () => import('./modules/e-contrat/e-contrat.module').then(m => m.EContratModule),
		canLoad: [AuthGuard],
		resolve: {
			essentialsData: EssentialsDataLoaderResolver
		}
	},
	{
		path: 'e-management',
		loadChildren: () => import('./modules/e-management/e-management.module').then(m => m.EManagementModule),
		canLoad: [AuthGuard],
	},
	{
		path: 'recours',
		loadChildren: () => import('./shared/modules/recours/recours.module').then(m => m.RecoursModule),
		canLoad: [AuthGuard],
		resolve: {
			essentialsData: EssentialsDataLoaderResolver
		}
	},
	{
		path: 'parametrages',
		loadChildren: () => import('./modules/parametrages/parametrage.module').then(m => m.ParametrageModule),
		canLoad: [AuthGuard],
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		anchorScrolling: "enabled",
		scrollOffset: [0, 90],
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }

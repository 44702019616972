import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface I_WP_STT{
	WMP_LIBELLE: string
	WP_ID: number
	WP_LIBELLE: string
}

let paramPath: string;
declare var $: any;
@Component({
  selector: 'app-action-proces-verbal',
  templateUrl: './action-proces-verbal.component.html',
  styleUrls: ['./action-proces-verbal.component.scss']
})
export class ActionProcesVerbalComponent implements OnInit {

	@Input() PROJET_PV_LR_CODE: string;
	@Input() DOSSIER_CODE: string;
	public PPM_CODE:string;
	// public actionPPM: action = {
	// 	codePPM: '',
	// 	annee: '',
	// 	Ajout: false,
	// 	Modification: false,
	// 	PrintPPM: false,
	// 	PrintAGPM: false
	//   };
	userConnected: string;
	WTStatus:I_WP_STT;
	WMP:string;


  constructor(
	) { }

  ngOnInit(): void {
		alert(this.PROJET_PV_LR_CODE);
  }

	onValiderCorrection(PROJET_PV_LR_CODE: string) {

	}


	onConfirmerCorrection(PROJET_PV_LR_CODE: string) {

	}


	onReexamenDossier(PROJET_PV_LR_CODE: string) {

	}


	onDecision(PROJET_PV_LR_CODE: string) {

	}


	onCorrectionAvecReserve(PROJET_PV_LR_CODE: string) {

	}

}

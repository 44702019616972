<!-- <span class="choisir">Choisir une action</span> -->
<div class="btn-action-pv">
  <button type="button" class="btn btn-cancel-form">Annuler</button>
  <button type="button" class="btn btn-send-form">Enregistrer</button>
  <button type="button" class="btn btn-send-form">Imprimer</button>

  <button type="button" class="btn btn-send-form">Archiver</button>
  <button type="button" class="btn btn-send-form">Délivrer</button>
  <button type="button" class="btn btn-send-form" (click)="onDecision(PPM_CODE)">Décision PV/LR</button>
  <button type="button" class="btn btn-send-form" (click)="onReexamenDossier(PROJET_PV_LR_CODE)">Ré-examen</button>
  <button type="button" class="btn btn-send-form">Conclure</button>
  <button type="button" class="btn btn-send-form">Valider conclusion</button>
  <button type="button" class="btn btn-send-form">Finaliser</button>
  <button type="button" class="btn btn-send-form">Viser</button>
  <button type="button" class="btn btn-send-form" (click)="onCorrectionAvecReserve(PPM_CODE)">Corriger avec réserve</button>
  <button type="button" class="btn btn-send-form" (click)="onConfirmerCorrection(PROJET_PV_LR_CODE)">Confirmer la correction</button>
  <button type="button" class="btn btn-send-form" (click)="onValiderCorrection(PROJET_PV_LR_CODE)">Valider la correction</button>
</div>


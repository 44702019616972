<form [formGroup]="fgInscription" autocomplete="off">
	<div class="mb-2">
		<div class="mb-2 mt-4 label ">
			<label for="" class="form-label mb-3">Mes informations</label>
			<div class="row">
				<div class="col-md-6">
					<input type="text" class="form-control mb-3"
						[ngClass]="{'border border-danger': nif?.touched && nif?.errors}" formControlName="nif"
						placeholder="NIF">
				</div>
				<div class="col-md-6">
					<input type="text" class="form-control mb-3"
						[ngClass]="{'border border-danger': nimp?.touched && nimp?.errors}" formControlName="nimp"
						placeholder="NIMP">
				</div>
			</div>
			<input type="text" class="form-control mb-3"
				[ngClass]="{'border border-danger': rib?.touched && rib?.errors}" formControlName="rib"
				placeholder="RIB">
			<div class="row">
				<div class="col-md-6">
					<input type="text" class="form-control mb-3"
						[ngClass]="{'border border-danger': login?.touched && login?.errors}" autocomplete="false"
						formControlName="login" placeholder="Login">
					</div>
					<div class="col-md-6">
						<input type="text" class="form-control mb-3"
							[ngClass]="{'border border-danger': key?.touched && key?.errors}" autocomplete="false"
							formControlName="key" placeholder="Clé">
				</div>
			</div>
			<input type="password" class="form-control mb-3" autocomplete="new-password"
				[ngClass]="{'border border-danger': password?.touched && password?.errors}" formControlName="password"
				placeholder="Mot de passe">
				<span></span>
		</div>
	</div>
</form>

import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from './converter';

@JsonObject("Entite")
export class Entite {
    @JsonProperty("ENT_CODE", String)
    ENT_CODE?: Number = undefined;

    @JsonProperty("ENT_CAT_CODE", String)
    ENT_CAT_CODE?: String = undefined;

    @JsonProperty("ENT_ABREVIATION", String)
    ENT_ABREVIATION?: String = undefined;

    @JsonProperty("ENT_LIBELLE", String)
    ENT_LIBELLE?: String = undefined;

    @JsonProperty("ENT_CREATION_DATE", DateConverter)
    ENT_CREATION_DATE?: DateConverter = undefined;

    @JsonProperty("ENT_CREATION_USER", String)
    ENT_CREATION_USER?: String = undefined;

    @JsonProperty("ENT_ADRESSE", String)
    ENT_ADRESSE?: String = undefined;

    @JsonProperty("ENT_MAIL", String)
    ENT_MAIL?: String = undefined;

    @JsonProperty("ENT_TEL", String)
    ENT_TEL?: String = undefined;

    @JsonProperty("ENT_CODE_MERE", String)
    ENT_CODE_MERE?: Number = undefined;

    @JsonProperty("ENT_ETAT_CODE", String)
    ENT_ETAT_CODE?: String = undefined;

    @JsonProperty("ENT_DESCRIPTION", String)
    ENT_DESCRIPTION?: String = undefined;

    @JsonProperty("REG_CODE", String)
    REG_CODE?: String = undefined;

}

export interface I_Entite {

	ENT_CODE: Number,

	ENT_CAT_CODE: String | null,

	ENT_ABREVIATION: String | null ,

	ENT_LIBELLE: String | null ,

	ENT_CREATION_DATE: String | null ,

	ENT_CREATION_USER: String | null ,

	ENT_ADRESSE: String | null ,

	ENT_MAIL: String | null ,

	ENT_TEL: String | null ,

	ENT_CODE_MERE: Number ,

	ENT_ETAT_CODE: String | null ,

	ENT_DESCRIPTION: String | null ,

	REG_CODE: String | null ,

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'romanize'
})
export class RomanizePipe implements PipeTransform {

	private LOOKUP_TABLE: { [key: string]: number } = {
		M: 1000,
		CM: 900,
		D: 500,
		CD: 400,
		C: 100,
		XC: 90,
		L: 50,
		XL: 40,
		X: 10,
		IX: 9,
		V: 5,
		IV: 4,
		I: 1
	};

	private SEARCH_KEYS_ORDERED: string[] = [
		"M",
		"CM",
		"D",
		"CD",
		"C",
		"XC",
		"L",
		"XL",
		"X",
		"IX",
		"V",
		"IV",
		"I"
	];


	transform(value: number, ...args: unknown[]): unknown {

		let romanNumber = "";

		for (const index in this.SEARCH_KEYS_ORDERED) {
			if (this.SEARCH_KEYS_ORDERED.hasOwnProperty(index)) {
				const romanNumberSymbolKey: string = this.SEARCH_KEYS_ORDERED[index];
				while (value >= this.LOOKUP_TABLE[romanNumberSymbolKey]) {
					romanNumber += romanNumberSymbolKey;
					value -= this.LOOKUP_TABLE[romanNumberSymbolKey];
				}
			}
		}

		return romanNumber

	}

}

<div class="section-head-modal">
	<h4 class="title-form-modal">AFFECTATION</h4>
</div>
<div class="text-center">
	<div>
		<p class="mb-0">Voulez-vous vous attribuer ce dossier?</p>
	</div>
</div>
<div class="row col-md-12">
	<div class="card-action d-flex justify-content-center mb-3 mb-3">
		<button mat-dialog-close class="btn btn-cancel-form">NON</button>
		<button class="btn btn-send-form" (click)="onAffecter(DATA)">OUI</button>
	</div>
</div>

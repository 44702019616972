<div class="container">
    <h1>Inscription</h1>
    <app-breadcrumbs></app-breadcrumbs>

    <div class="row clearfix">
        <div class="col-md-4">
            <div class="card-bg">
                <h3>Formulaire</h3>
                <app-form-inscription
                (setradioClicked)="receiveRadioClick($event)"
                (formInvalid)="updateInscription($event)"
                ></app-form-inscription>
                <h3></h3>
            </div>
        </div>
        <div class="col-md-8 mMarg-top">
            <div class="card-bg">
                <app-info-inscription
                 [receivedRadio]="receivedRadio"
                ></app-info-inscription>
            </div>
        </div>
    </div>

    <div class="mt-4">
      <div class="card-action">
        <button type="button" class="btn btn-cancel-form" (click)="onAnnule()">Annuler</button>
        <button type="button" [disabled]="invalid_form" class="btn btn-send-form" (click)="save()">Enregistrer</button>
      </div>
    </div>
</div>

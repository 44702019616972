import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

import { TokenInterceptorService } from 'src/app/shared/interceptors/token-interceptor.service';
import { ErrorHandlerInterceptor } from './interceptors/errorHandler/error-handler.interceptor';

import { CheckboxShitchComponent } from './components/checkbox-shitch/checkbox-shitch.component';
import { SpinnerLayoutsComponent } from './components/spinner-layouts/spinner-layouts.component';
import { AppToolsService } from './services/tools/app-tools.service';
import { UtilsService } from './services/utils/utils.service';

import { PaginationTableComponent } from './components/pagination-table/pagination-table.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MontantLettrePipe } from './pipes/montant-lettre.pipe';
import { ConnexionStatusComponent } from './components/connexion-status/connexion-status.component';
import { SavingStatusComponent } from './components/saving-status/saving-status.component';
import { AppNotificationComponent } from './components/app-notification/app-notification.component';
import { MyCustomPaginatorIntl } from './directives/CustomPaginatorConfiguration';
import { RomanizePipe } from './pipes/romanize.pipe';
import { ConversionPipe } from './pipes/conversion.pipe';
import { PopupPreRequisComponent } from './components/cnm/modals/popup-pre-requis/popup-pre-requis.component';
import { ProcesVerbaleComponent } from './components/cnm/modals/proces-verbale/proces-verbale.component';
import { TableParamPieceComponent } from './components/cnm/features/pre-requis/table-param-piece/table-param-piece.component';
// import { CreationPvComponent } from './components/cnm/features/creation-pv/creation-pv.component';
import { ActionPopupPreRequisComponent } from './components/cnm/features/pre-requis/action-popup-pre-requis/action-popup-pre-requis.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialFileInputModule } from 'ngx-material-file-input';
// import { PdfReaderModalComponent } from './components/pdf-reader-modal/pdf-reader-modal.component';
import { ExportPvComponent } from './components/cnm/features/pv/export-pv/export-pv.component';
import { UploadsPrComponent } from './components/cnm/features/pre-requis/uploads-pr/uploads-pr.component';
import { DownloasPrComponent } from './components/cnm/features/pre-requis/downloas-pr/downloas-pr.component';
import { SelfAffectationComponent } from './components/cnm/features/pre-requis/self-affectation/self-affectation.component';
import { PrevPdfComponent } from './components/prev-pdf/prev-pdf.component';
import { ListePreRequisComponent } from './components/cnm/pages/liste-pre-requis/liste-pre-requis.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActionProcesVerbalComponent } from './components/cnm/features/action-proces-verbal/action-proces-verbal.component';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SocketServiceService } from './services/socket/socket-service.service';
import { ListeDemandeElementComponent } from './components/liste-demande-element/liste-demande-element.component';
// import { RecoursModule } from './modules/recours/recours.module';


@NgModule({
	declarations: [
		BreadcrumbsComponent,
		CheckboxShitchComponent,
		SpinnerLayoutsComponent,
		PaginationTableComponent,
		MontantLettrePipe,
		ConnexionStatusComponent,
		SavingStatusComponent,
		AppNotificationComponent,
		RomanizePipe,
		ConversionPipe,
		PopupPreRequisComponent,
		ProcesVerbaleComponent,
		TableParamPieceComponent,
		ActionPopupPreRequisComponent,
		// PdfReaderModalComponent,
		ExportPvComponent,
		UploadsPrComponent,
		DownloasPrComponent,
		SelfAffectationComponent,
		PrevPdfComponent,
		ListePreRequisComponent,
		DownloadFileComponent,
		UploadFileComponent,
		ActionProcesVerbalComponent,
		DownloadFileComponent,
		UploadFileComponent,
  ListeDemandeElementComponent,

	],
	exports: [
		BreadcrumbsComponent,
		CheckboxShitchComponent,
		SpinnerLayoutsComponent,
		PaginationTableComponent,
		MontantLettrePipe,
		ConnexionStatusComponent,
		AppNotificationComponent,
		SavingStatusComponent,
		RomanizePipe,
		ConversionPipe,
		ProcesVerbaleComponent,
		PopupPreRequisComponent,
		ActionPopupPreRequisComponent,
		PrevPdfComponent,
		ActionProcesVerbalComponent
	],
	imports: [
		CommonModule,
		HttpClientModule,
		RouterModule,
		MatPaginatorModule,
		MatDialogModule,
		MatTooltipModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatTableModule,
		MatFormFieldModule,
		MaterialFileInputModule,
		PdfViewerModule,
		NgxFileDropModule,
		/* RecoursModule */
	],
	providers: [
		// AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true
		},
		{
			provide: MatPaginatorIntl,
			useValue: MyCustomPaginatorIntl()
		},
		DatePipe,
		SocketServiceService,
		MontantLettrePipe
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

	constructor(private toolsService: AppToolsService, private utilsService: UtilsService) {

	}

}

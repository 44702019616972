import { Component, Input, OnInit, Inject } from '@angular/core';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { codesParam } from 'src/app/shared/interfaces/cnm';
@Component({
  selector: 'app-self-affectation',
  templateUrl: './self-affectation.component.html',
  styleUrls: ['./self-affectation.component.scss']
})
export class SelfAffectationComponent implements OnInit {

	DATA: codesParam; // Données transmise depuis le component d'origine


  constructor(
		@Inject(MAT_DIALOG_DATA) public codes: { ACTEUR_CODE: string, DOSSIER_CODE: string },
		@Inject(MAT_DIALOG_DATA) public ppmHdrCode: string,
		public appTools: AppToolsService,
		public toastr: ToastrService
	) {
		this.DATA = codes;
	 }

  ngOnInit(): void {

	}

	onAffecter(codes: any) {
		this.toastr.info(codes);
		this.toastr.info('Le dossier vous a été affecté.');
	}

}

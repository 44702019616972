import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { I_Notification, I_NotificationAPIRES } from '../../interfaces/notification';
import { UserService } from '../user/user.service';
import { HttpClient } from '@angular/common/http';
import { base_url, endpoints } from "src/assets/api.json";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notifications$ = new BehaviorSubject<I_NotificationAPIRES|undefined>(undefined);


  constructor(public userService: UserService, private spinner: NgxSpinnerService, private http : HttpClient) { }


  	getAllNotifications(){

    	let usergrp = this.userService.connectedUserB$.value?.USR_GROUPE;

		switch(usergrp){
			case 'PRMP':

				let usercode = this.userService.connectedUserB$.value?.USR_CODE  as string;
				this.userService.getNotification(usercode).subscribe(
					data => {
            			this.notifications$.next(data.data);
					}
				);
				break;

			case 'GAC':// à corigé

				let usercodegac = this.userService.connectedUserB$.value?.GAC_CODE  as string;

					this.userService.getNotificationgac(usercodegac).subscribe(
					data => {
            			this.notifications$.next(data.data);
					}

				);
				break;
				
			default:
				this.userService.getNotificationByUserConnected().subscribe(
					data => {
						this.notifications$.next(data.data);
					}
				)
				break;

		}


  	}

   	setNotificationLue(id? : string): Observable<any>
	{
		return this.http.put(`${base_url}${endpoints.notification.setNotificationLue}`, {
			NOTIFICATION_CODE: id
		});

	}

	getNotificationRecours(dmd_element_code:string): Observable<any>{

		const param:any = {
			DMD_ELEM_CODE: dmd_element_code
		}
		
		return this.http.get(`${base_url}${endpoints.recours.getRecourByDemandeElement}`, {params:param})

	}

	getRecoursByCode(recours_code:string): Observable<any>{

		return this.http.get(`${base_url}${endpoints.recours.getRecoursByRecoursCode}`, {params:{
			RECOURS_CODE:recours_code
		}})

	}

	getRecoursByReponseDemandeInfo(reponse_demande_info_code:string): Observable<any>{

		return this.http.get(`${base_url}${endpoints.recours.getRecoursByReponseDemandeInfo}`, {params:{
			REP_CODE:reponse_demande_info_code
		}})

	}

	getRecoursByDecisionSrec(decision_srec_code:string): Observable<any>{

		return this.http.get(`${base_url}${endpoints.recours.getRecoursByDecisionSrec}`, {params:{
			DECISION_SREC_CODE:decision_srec_code
		}})

	}

	getRecoursByDemandeInfo(demande_info_code:string): Observable<any>{

		return this.http.get(`${base_url}${endpoints.recours.getRecoursByDemandeInfo}`, {params:{
			DMD_INFO_CODE:demande_info_code
		}})

	}

	// "getDemandeByBesoinCode":"/e-Planification/DEMANDE/GET_DEMANDE_BY_BESOIN_CODE"
	// "getBesoinByDemdandeCode":"/e-Planification/BESOIN/GET_BESOIN_BY_DMD_CODE"

	getDemandeByBesoinCode(besoin_code:string){

		return this.http.get(`${base_url}${endpoints.ePlanification.demande.getDemandeByBesoinCode}`, {params:{
			BESOIN_CODE:besoin_code
		}})

	}

	getBesoinByDemandeCode(dmd_code:string){
		return this.http.get(`${base_url}${endpoints.ePlanification.determination.getBesoinByDemdandeCode}`, {params:{
			DMD_CODE:dmd_code
		}})

	}
	
}

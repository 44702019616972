import { Injectable } from '@angular/core';
import { api_res, http_request_options, Login_data, Opeco_sign_res, User_sign_res } from "src/app/shared/interfaces";
import { base_url, endpoints } from "src/assets/api.json";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { dossierFilterData } from 'src/app/modules/e-dossier/interfaces';
import { Dossier, I_Dossier } from '../../models/dossier';
import { ppm_filtreData } from '../../models/e-planification/ppm_filtre.interface';
import { ParamGetFilesDepot } from 'src/app/modules/e-soummission/interfaces/param-get-files-depot';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(public http: HttpClient) { }

	autheticate_user<T>(loginParams: Login_data) {
		return this.http.post<User_sign_res | T>(base_url + endpoints.auth, loginParams);
	}


	autheticate_civil<T>(loginParams: Login_data) {
		return this.http.post<User_sign_res | T>(base_url + endpoints.auth_civil, loginParams);
	}


	autheticate_opeco<T>(loginParams: Login_data) {

		return this.http.post<User_sign_res | T>(base_url + endpoints.auth_opeco, loginParams);

	}


	verify_opeco<T>(loginParams: Login_data) {

		return this.http.post<User_sign_res | T>(base_url + endpoints.exist_opeco, loginParams);

	}

	logout_user<T>() {
		return this.http.post<User_sign_res | T>(base_url + endpoints.logout, {});
	}

	get_ppm_list_all<T>() {

		return this.http.get<api_res | T>(base_url + endpoints.all_ppm);

	}

	get_ppm_list_all_by_mode_passation<T>(modePassation: string) {

		return this.http.get<api_res | T>(base_url + endpoints.all_ppm, {
			params: {
				MODE_PASS_CODE: modePassation
			}
		});

	}


	get_ppm_by_filtre<T>(params: ppm_filtreData) {

		// console.log(params);

		let httpParams = new HttpParams();

		httpParams.set("PPM_DTL_CODE", params.PPM_DTL_CODE);
		httpParams.set("LOCALISATION", params.LOCALISATION);
		httpParams.set("PRMP_CODE", params.PRMP_CODE);
		httpParams.set("ANNEE", params.ANNEE);

		return this.http.get<api_res | T>(base_url + endpoints.ePlanification.regroupementLots.getppmbyfiltre, {
			params: {
				PPM_DTL_CODE: params.PPM_DTL_CODE ? params.PPM_DTL_CODE : "",
				LOCALISATION: params.LOCALISATION ? params.LOCALISATION : "",
				PRMP_CODE: params.PRMP_CODE ? params.PRMP_CODE : "",
				ANNEE: params.ANNEE ? params.ANNEE : "",
			}
		});

	}

	get_ppm_hdr<T>() {
		return this.http.get<api_res | T>(base_url + endpoints.ePlanification.regroupementLots.getppmbyuser)
	}

	get_ppm_hdr_by_prmpcode<T>(Code: any) {

		const url = base_url + endpoints.ePlanification.regroupementLots.getppmhdrbyprmpcode;

		return this.http.get(url, { params: { PRMP_Code: Code } });

	}

	public get_besoindtl_by_lot_code(lot_code: string): Observable<any> {

		const url = base_url + endpoints.ePlanification.regroupementBesoin.getbesoindtl
		return this.http.get(url, { params: { LOT_CODE: lot_code }});
		
	}

	get_connected_user<T>(options?: http_request_options) {
		return this.http.post<User_sign_res | T>(`${base_url}${endpoints.get_connected_user}`, options ? options : {});
	}

	get_connected_opeco<T>(options?: http_request_options) {
		return this.http.post<Opeco_sign_res | T>(`${base_url}${endpoints.get_connected_user}`, options ? options : {});
	}

	getDossierByFilter<T>(params: dossierFilterData) {
		return this.http.get<api_res | T>(`${base_url}${endpoints.e_dossier.getDossierByFilter}`, {
			params: {
				objet: params.objet,
				entite: params.direction ? params.direction : params.autoriteContractante,
				annee: params.annee,
				mode: params.modePass,
				type: params.typeMarhe,
				etat_dossier: params.etat_dossier ? params.etat_dossier : "",
				region: params.region ? params.region : ""
			}
		});

	}

	getDossierBySearchLimit<T>(params: dossierFilterData) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_dossier.getDossierBySearchLimit}`, {
			params: {
				objet: params.objet,
				entite: params.direction ? params.direction : params.autoriteContractante,
				annee: params.annee,
				mode: params.modePass,
				type: params.typeMarhe,
				etat_dossier: params.etat_dossier ? params.etat_dossier : "",
				region: params.region ? params.region : "",
				limit: params.limit ? params.limit : 0,
				page: params.page ? params.page : 0,
				total_rows: params.total_rows ? params.total_rows : 0,
				rows: params.rows ? params.rows : 0,
				cc: params.isCC ? "1" : "0"
			}
		});

	}


	getRapportJustificatifBySearchLimit<T>(params: dossierFilterData){

		return this.http.get<T>(`${base_url}${endpoints.e_dossier.getRAPJBySearchLimit}`, {
			params: {
				objet: params.objet,
				entite: params.direction ? params.direction : params.autoriteContractante,
				annee: params.annee,
				mode: params.modePass,
				type: params.typeMarhe,
				etat_dossier: params.etat_dossier ? params.etat_dossier : "",
				region: params.region ? params.region : "",
				limit: params.limit ? params.limit : 0,
				page: params.page ? params.page : 0,
				total_rows: params.total_rows ? params.total_rows : 0,
				rows: params.rows ? params.rows : 0
			}
		});

	}


	getDossierByCode<T>(code_dossier: string) {

		return this.http.get<api_res | T>(
			`${base_url}${endpoints.e_dossier.getDossierByCode}`, {
			params: {
				DOSSIER_CODE: code_dossier
			}
		}
		);
	}

	getAllModePassation<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllModePassation}`, {});
	}

	getAllTypeMarche<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllTypeMarche}`, {});
	}

	getAllEntite<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllEntite}`, {});
	}
	getAllEntiteWithNoToken<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.get_all_entite_direction}`, {});
	}

	getDirectionByEntCode<T>(ENT_CODE: string) {

		let httpParam = new HttpParams();
		httpParam.set("ENT_CODE", ENT_CODE)

		return this.http.get<api_res | T>(`${base_url}${endpoints.getDirectionByEntCode}`, {
			params: { ENT_CODE }
		});
	}

	getDirectionByEntCodeInscription<T>(code: string) {

		//	let httpParam = new HttpParams();

		return this.http.get<api_res | T>(`${base_url}${endpoints.getDirectionByEntCodeInscription}`, {
			params: { "ENT_CODE": code }
		});
	}

	getAllRegion<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllRegion}`, {});
	}

	getAllRegionWithNoToken<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllRegionWithNoToken}`, {});
	}

	getAllPRMP<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.getAllPRMP}`, {});
	}

	getPRMPByEntCode<T>(ENT_CODE: string) {

		let httpParam = new HttpParams();
		httpParam.set("ENT_CODE", ENT_CODE);

		return this.http.get<api_res | T>(`${base_url}${endpoints.getPRMPByEntite}`, {
			params: { ENT_CODE }
		});
	}

	change_activation_status_dossier<T>(dossierCode: String) {
		return this.http.put<api_res | T>(`${base_url}${endpoints.e_dossier.activate_or_desactivate_dossier}`, {
			DOSSIER_CODE: dossierCode
		});
	}


	get_all_modalite_execution<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.get_all_modalite_exec}`);
	}

	save_dossier<T>(datas: Dossier | I_Dossier) {

		return this.http.post<T>(`${base_url}${endpoints.e_dossier.update_dossier}`, {
			DOSSIER_CODE: datas.DOSSIER_CODE,
			DOSSIER_ATTACHE_2: datas.DOSSIER_ATTACHE_2,
			DOSSIER_ATTACHE_3: datas.DOSSIER_ATTACHE_3,
			DOSSIER_ATTACHE_4: datas.DOSSIER_ATTACHE_4,
			DOSSIER_ATTACHE_5: datas.DOSSIER_ATTACHE_5,
			DOSSIER_NUMERO: datas.DOSSIER_NUMERO,
			DOSSIER_OBJET: datas.DOSSIER_OBJET,
			// MOD_EXE_CODE_FK: datas.MOD_EXE_CODE_FK,
			ETAT_CODE_FK: datas.ETAT_DOSSIER_CODE
		});
	}

	getLotsByCodeDossier<T>(DOSSIER_CODE: string) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.liste_lots_par_code_dossier}`, {
			params: {
				DOSSIER_CODE: DOSSIER_CODE
			}
		});
	}

	getOffresByCodeDossier<T>(DOSSIER_CODE: string) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.liste_offres_par_code_dossier}`, {
			params: {
				DOSSIER_CODE: DOSSIER_CODE
			}
		});
	}


	getAAOOByCodeDossier<T>(DOSSIER_CODE: string) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.getAaooByDossierCode}`, {
			params: {
				CODE_DOSSIER: DOSSIER_CODE
			}
		});
	}

	getAACOByCodeDossier<T>(DOSSIER_CODE: string) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		// AACO_CODE == CODE_DOSSIER

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_dossier.getAACOByCode}`, {
			params: {
				AACO_CODE: DOSSIER_CODE
			}
		});
	}

	getOffreByLotCode<T>(options: any) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		return this.http.post<api_res | T>(`${base_url}${endpoints.e_soummission.getOffreByLotCode}`, options ? options : {});
	}


	getNotificationByprmpcode(idprm: string) {
		return this.http.get<api_res>(`${base_url}${endpoints.notification.getNotificationByprmpcode}`,
			{
				params: {
					PRMP_CODE: idprm
				}
			}
		);

	}

	getNotificationByUserConnected() {

		return this.http.get<api_res>(`${base_url}${endpoints.notification.getNotificationByUserConnected}`);

	}

	getNotificationBygaccode(idprm: string) {
		return this.http.get<api_res>(`${base_url}${endpoints.notification.getNotificationBygaccode}`,
			{
				params: {
					GAC_CODE: idprm
				}
			}
		);

	}

	getBesoinDTLByLot<T>(LOT_CODE: string) {
		// let httpParam = new HttpParams();
		// httpParam.set("DOSSIER_CODE", DOSSIER_CODE)

		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.getBesoinDTLByLot}`, {
			params: {
				LOT_CODE: LOT_CODE
			}
		});
	}

	setNotificationLue(NOTIFICATION_CODE?: string) {
		// console.log('tong api service', NOTIFICATION_CODE);

		return this.http.put(`${base_url}${endpoints.notification.setNotificationLue}`, {
			NOTIFICATION_CODE: NOTIFICATION_CODE
		});
	}

	getListeAvis<T>() {
		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.getListeAvis}`);
	}
	
	getListeLettreInvitaion<T>(OPER_CODE: string) {
		return this.http.get<api_res | T>(`${base_url}${endpoints.e_soummission.getliOpeco}`, {
			params: {
				OPER_CODE: OPER_CODE
			}
		});
	}

	saveSoumissionPSI<T>(datas: any) {
		// let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});	// )
		let headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });	// )
		headers.append('processData', 'false');

		return this.http.post<T>(`${base_url}${endpoints.e_soummission.postOffrePSI}`, datas); //, {headers: headers});
	}
	saveSoumissionFRN<T>(datas: any) {

		return this.http.post<T>(`${base_url}${endpoints.e_soummission.postOffreFRN}`, datas);
	}
	saveSoumissionTVX<T>(datas: any) {

		return this.http.post<T>(`${base_url}${endpoints.e_soummission.postOffreTVX}`, datas);
	}


	getAllUniteDeMesure<T>() {

		return this.http.get<T>(`${base_url}${endpoints.getAllUniteMesure}`);

	}


	getAllSupportPub<T>() {

		return this.http.get<T>(`${base_url}${endpoints.ePlanification.GetAllSupportPub}`);

	}

	getAllSuppportPubOfPPM<T>(code: string) {

		return this.http.get<T>(`${base_url}${endpoints.ePlanification.GetAllSupportPubOfPPM}`, {
			params: {
				PPM_DTL_CODE: code
			}
		});

	}


	getDateLimitOffre<T>(dossierCode: string) {

		return this.http.get<T>(`${base_url}${endpoints.e_dossier.getDateLimitOffre}`, {

			params: {

				DOSSIER_CODE: dossierCode

			}

		});

	}


	getFilesDepots<T>(data: ParamGetFilesDepot) {

		return this.http.post<T>(`${base_url}${endpoints.e_soummission.getFilesDepots}`, data);

	}


	getServerCurrentDate() {

		return this.http.get(`${base_url}${endpoints.getServerCurrentDate}`)

	}


	saveUser<T>(data: any) {

		return this.http.post<T>(`${base_url}${endpoints.parametrages.general.utilisateur.insertUtilisateur}`, data);

	}


	downloadDossier<T>(dossier_code: any, actionType: number) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.e_dossier.getfilebase64}`, {
			DOSSIER_CODE: dossier_code,
			TYPE_ACTION: actionType
		});

	}


	insertWorkFlow<T>(wp_id: number, code_ppm: string | null, code_dossier: string | null, cnm_user_code: string) {

		return this.http.post<api_res | T>((base_url + endpoints.CNM.workFlow.insertWorkflow), {
			DATA_WORKFLOW_TRANSITION: {
				WP_ID_FK: wp_id,
				PPM_HDR_CODE_FK: code_ppm,
				DOSSIER_CODE_FK: code_dossier,
				CNM_CODE_FK: cnm_user_code
			}
		});


	}


	getCurrentWorkflow<T>(code: any) {

		return this.http.get<api_res | T>((base_url + endpoints.CNM.workFlow.getCurrentWorkflow), {
			params: { CODE: code }
		})

	}


	getPreviousWorkflow<T>(wp_id: any) {

		return this.http.get<api_res | T>((base_url + endpoints.CNM.workFlow.getPreviousWorkflow), {
			params: { WP_ID: wp_id }
		})

	}


	getNextWorkflow<T>(wp_id: string, pass: string | null) {

		return this.http.post<api_res | T>((base_url + endpoints.CNM.workFlow.getNextWorkFlow), {
			WP_ID: wp_id,
			PASS: pass
		})

	}


	verifierInvitation(q: string): Observable<api_res> {
		return this.http.post<api_res>(base_url + endpoints.e_evaluation.verifierInvitation, { q });
	}


	get_proposition_ae_spectech<T>(code_dossier: string) {

		return this.http.get<api_res | T>(base_url + endpoints.e_evaluation.get_proposition_ae_spectech, {
			params:{DOSSIER_CODE:code_dossier}
		});

	}


	downloadPreRequis() {

		return this.http.get<api_res>(base_url + endpoints.CNM.downloadPrerequis);

	}

	// valable pour docs autres que e-evaluation
	downloadDocument<T>( path: string ){
		return this.http.post<api_res|T>(`${base_url}${endpoints.downloadDocument}`, { fichier: path });
	}


	/**
	  	* @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
		*
		* getAllModeSelection
		*
		* Requête pour récupérer la liste des mode de sélection
		*
	*/
	getAllModeSelection<T>() {

		return this.http.get<api_res>(`${base_url}${endpoints.getAllModeSelection}`);

	}
	/**
	  	* @author Roland. RASOANAIVO <rrasoanaivo@ingenosya.mg>
		*
		* getAllModeSelection
		*
		* Requête pour récupérer la liste du classement offres
		*
	*/
	getlisteOfferClassement<T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.getlisteOfferClassement
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code,
		});
	}

	getlisteCandidatByDossier<T>(dossier_code: string) {
		let url = base_url + endpoints.e_evaluation.getlisteCandidatByDossier
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	exportPvAooAcoAor <T>(dossier_code: string) {
		let url = base_url + endpoints.e_evaluation.exportPV_AOO_ACO_AOR
		return this.http.post<api_res | T>(url, {
			DOSSIER_CODE: dossier_code
		});
	}

	exportPvAooAcoAorRapEval <T>(dossier_code: string) {
		let url = base_url + endpoints.e_evaluation.exportPV_AOO_ACO_AOR_Reval
		return this.http.post<api_res | T>(url, {
			DOSSIER_CODE: dossier_code
		});
	}


	exportListeliste_annee <T>() {
		let url = base_url + endpoints.e_evaluation.exportListeliste_annee
		return this.http.get<api_res>(url);
	}

	getAllTypemAaooCc <T>() {
		let url = base_url + endpoints.e_evaluation.getAllTypemAaooCc
		return this.http.get<api_res>(url);
	}

	exportListe_objet <T>() {
		let url = base_url + endpoints.e_evaluation.exportListe_objet
		return this.http.get<api_res>(url);
	}

	exportListe_ami <T>(annee: string,objet: string, data: any) {
		let url = base_url + endpoints.e_evaluation.exportListe_ami
		return this.http.post<api_res| T>(url,{
			annee: annee,
			objet: objet,
			data: data
		});
	}

	valider_proposition <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.validationDelaProposition
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	valider_proposition_verif <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.validationDelaPropositionVerif
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	export_evaluation_doc_qualif <T>(dossier_code: string,type: string) {
		let url = base_url + endpoints.e_evaluation.export_EVALUATION_DOC_QUALIF
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code,
			type: type
		});
	}

	export_eval_avis <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.export_EVAL_AVIS
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	eval_avis_update_step <T>(data: any) {
		let url = base_url + endpoints.e_evaluation.eval_AVIS_UPDATE_STEP
		return this.http.post<api_res| T>(url,{
			data: data
		});
	}

	recup_lot_eval_avis <T>(dossier_code: string) {
		let url = base_url + endpoints.e_evaluation.recup_LOT_EVAL_AVIS
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	exportAmitoPRMP <T>(dossier_code: string) {
		let url = base_url + endpoints.e_evaluation.exportAmitoPRMP
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	AmiByDossier <T>(dossier_code: string) {
		let url = base_url + endpoints.e_dossier.AmiByDossier
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	AmiUpdateStep <T>(data: any) {
		let url = base_url + endpoints.e_evaluation.AmiUpdateStep
		return this.http.post<api_res| T>(url,{
			data: data
		});
	}

	GetAmiByLotCode <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.GetAmiByLotCode
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	AmiLotByDossier <T>(dossier_code: string) {
		let url = base_url + endpoints.e_dossier.AmiLotByDossier
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	AmiInsertInfoEvaluation <T>(data: any) {
		let url = base_url + endpoints.e_evaluation.AmiInsertInfoEvaluation
		return this.http.post<api_res| T>(url,{
			data: data
		});
	}

	AmiListeEvaluationCandidat <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.AmiListeEvaluationCandidat
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	InsertEvaluationAmi <T>(
			offres_code: any,
			lot_code: any,
			oper_code: any,
			oper_nom: any,
			oper_prenom: any,
			dossier_code: any
		) {
		let url = base_url + endpoints.e_evaluation.InsertEvaluationAmi
		return this.http.post<api_res| T>(url,{
			offres_code: offres_code,
			lot_code: lot_code,
			oper_code: oper_code,
			oper_nom: oper_nom,
			oper_prenom: oper_prenom,
			dossier_code: dossier_code
		});
	}

	AmiDeleteEvaluationCandidat <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.AmiDeleteEvaluationCandidat
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	AmiCandidatRestreint <T>(lot_code: string) {
		let url = base_url + endpoints.e_evaluation.AmiCandidatRestreint
		return this.http.post<api_res| T>(url,{
			lot_code: lot_code
		});
	}

	ChoixCandidatRestreintAmi <T>(offer_code: string,choix: string) {
		let url = base_url + endpoints.e_evaluation.ChoixCandidatRestreintAmi
		return this.http.post<api_res| T>(url,{
			offer_code: offer_code,
			choix:choix
		});
	}

	RaisonCandidatRestreintAmi <T>(offer_code: string,raison: string) {
		let url = base_url + endpoints.e_evaluation.RaisonCandidatRestreintAmi
		return this.http.post<api_res| T>(url,{
			offer_code: offer_code,
			raison:raison
		});
	}

	UpdateAmiEvaluationDossier <T>(data: any) {
		let url = base_url + endpoints.e_evaluation.UpdateAmiEvaluationDossier
		return this.http.post<api_res| T>(url,{
			data: data
		});
	}

	expedieAMItoPRMP <T>(dossier_code: any,EtatCode: any,dossier_type:any,aviscode:any) {
		let url = base_url + endpoints.e_dossier.expedieAMItoPRMP
		return this.http.put<api_res| T>(url,{
			AVIS_CODE: aviscode,
			ETAT_CODE_FK: EtatCode,
			DOSSIER_TYPE: dossier_type,
			DOSSIER_CODE:dossier_code
		});
	}

	AmiPropositionAttributaire <T>(annee: any) {
		let url = base_url + endpoints.e_evaluation.AmiPropositionAttributaire
		return this.http.post<api_res| T>(url,{
			annee: annee
		});
	}

	getOuvertureDcCandidatAmi <T>(dossier_code: any) {
		let url = base_url + endpoints.e_evaluation.getOuvertureDcCandidatAmi;
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	getAaooDossierCc <T>(annee:any,objet:any,type_m: any) {
		let url = base_url + endpoints.e_evaluation.getAaooDossierCc
		return this.http.post<api_res| T>(url,{
			annee: annee,
			objet: objet,
			type_m: type_m
		});
	}

	UpdateAaooCcPlisByDossier <T>(dossier_code:any) {
		let url = base_url + endpoints.e_evaluation.UpdateAaooCcPlisByDossier
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	UpdateAaooSceanceCcByDossier <T>(dossier_code:any) {
		let url = base_url + endpoints.e_evaluation.UpdateAaooSceanceCcByDossier
		return this.http.post<api_res| T>(url,{
			dossier_code: dossier_code
		});
	}

	GetEvalDossierCC <T>() {
		let url = base_url + endpoints.e_evaluation.GetEvalDossierCC
		return this.http.get<api_res>(url);
	}
/* Fin API Roland. RASOANAIVO */
	// Request without token
	// -> W H Y !? Cuz public inscription need this -ok
	public getAllGroupe(): Observable<any> {
		let url = base_url + endpoints.get_all_groupe;
		return this.http.get<api_res>(url);
	}

	public getPRMPByEntCodeWT(code_ent: string): Observable<api_res> {
		let url = base_url + endpoints.get_prmp_by_ent_code + '?ENT_CODE=' + code_ent;
		return this.http.get<api_res>(url);
	}

	public generateDemandeComptePDF(data: any): Observable<any>  {
		let url = base_url + endpoints.export_pdf_demande_creation;
		return this.http.post<api_res>(url, data);
	}

	public get_user_droit_group(login: String | undefined): Observable<any> {
		let url = base_url + endpoints.parametrages.general.groupe_et_droit.get_droit_by_login + '?LOGIN=' + login;
		return this.http.get<api_res>(url);
	}

	getDodssierEvaluation( dossier_code: string ): Observable<api_res>{

		return this.http.get<api_res>( `${base_url}${endpoints.e_evaluation.getDossierEvaluation}`, { params: { DOSSIER_CODE: dossier_code } } );
	}

	getCritereSousCritereDossier( dossier_code: string ): Observable<api_res>{

		return this.http.get<api_res>( `${base_url}${endpoints.e_evaluation.CritereSousCritereDossier}`, { params: { DOSSIER_CODE: dossier_code } } );
	}

	enregistrer_rapport_evaluation( donnees_formulaire: any ): Observable<api_res>{

		return this.http.post<api_res>( `${base_url}${endpoints.e_evaluation.enregistrer_rapport_evaluation}`, donnees_formulaire );
	}

	getDonneesComparaisonOffre( dossier_code: string ): Observable<api_res>{

		return this.http.get<api_res>( `${base_url}${endpoints.e_evaluation.donnees_evaluation_comparaison}`, { params: { DOSSIER_CODE: dossier_code } } );
	}

	enregistrer_evaluation_comparaison( donnees_formulaire: any ): Observable<api_res>{

		return this.http.post<api_res>( `${base_url}${endpoints.e_evaluation.enregistrer_evaluation_comparaison}`, donnees_formulaire );
	}


		/**
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 *  getCadidatList
	 *
	 * @param {string} codeDossier
	 *
	 * @description Requête pour la récupération de liste de candidat séléctionné pour un dossier
	 *
	*/
	getCadidatList(codeDossier:string){

		return this.http.get<api_res>( `${base_url}${endpoints.e_dossier.candidatList}`, { params: { DOSSIER_CODE: codeDossier } } );

	}


	enregistrer_evaluation_proposition_technique( donnees_formulaire: any ): Observable<api_res>{
		return this.http.post<api_res>( `${base_url}${endpoints.e_evaluation.enregistrer_evaluation_proposition_technique}`, donnees_formulaire );
	}


	/**
	 *
	 * 	Récupération de la liste des etat/statu de document et process
	 *
	 */
	getAllEtat(){

		return this.http.get<api_res>( `${base_url}${endpoints.get_all_etat}` );

	}


	getMarcheSubsequent(codeDossier: string){

		return this.http.get<api_res>(
			`${base_url}${endpoints.get_marche_subsequent_by_dossier_code}`,
			{ params: { DOSSIER_CODE_FK: codeDossier } } );

	}


	getMarcheSubsequentByCode(codeMarche: string){

		return this.http.get<api_res>(
			`${base_url}${endpoints.get_marche_subsequent_by_code}`,
			{ params: { CONTRAT_CADRE_NUM: codeMarche } } );

	}

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionService } from '../services/session/session.service';
import { session_key } from "src/app/configs/storage_key.json";
import { token_key } from "src/assets/api.json";



@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log("requiesting for ", request.url);

    // console.log("Authorization: ", `${token_key}${token_request_separator}${this.sessionService.getItem(session_key)}`);

    request = request.clone({
      setHeaders: {
        Authorization: `${token_key} ${this.sessionService.getItem(session_key)}`
      }
    })
    return next.handle(request);
  }

}

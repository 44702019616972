import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
    serialize(date: Date): any {
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    }
    deserialize(date: any): Date {
        return new Date(date);
    }
}
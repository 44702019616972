import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user/user.service';
import { EventEmitter } from '@angular/core';
import { Groupe, I_Groupe, User } from 'src/app/shared/models';
import { Observable, Subscription } from 'rxjs';
import { Entite } from 'src/app/shared/models/entite';
import { I_Direction } from 'src/app/shared/models/e-planification/direction.interface';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { api_res, Register_data } from 'src/app/shared/interfaces';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { I_Prmp } from 'src/app/shared/interfaces/prmp';
import { UtilisateurService } from 'src/app/modules/parametrages/core/service/general/utilisateur/utilisateur.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { secteur } from 'src/app/configs/storage_key.json';
import { secteur_prive, secteur_public, secteur_civil } from 'src/app/configs/parametre.json';

@Component({
	selector: 'app-form-inscription',
	templateUrl: './form-inscription.component.html',
	styleUrls: ['./form-inscription.component.scss']
})

export class FormInscriptionComponent implements OnInit {
	@Output() public setradioClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() public formInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('loginInput') input: ElementRef;

	entites: Entite[] = [];
	directions: I_Direction[] = [];
	prmps: I_Prmp[];
	lieu: string = "";
	listGroups: I_Groupe[];

	subscriptions: Subscription[] = [];
	user_group: string;
	openedForm: Subscription;
	date_now: Date = this.utils.FullDateServer.value;
	now: string = this.date_now.getDate() + '/' + (this.date_now.getMonth() + 1) + '/' + this.date_now.getFullYear();

	public signUpForm: FormGroup;
	public gacUserForm: FormGroup;
	public caoUserForm: FormGroup;
	public codeMere: string;

	constructor(
		private formBuilder: FormBuilder,
		public userService: UserService,
		public apiService: ApiService,
		private utils: UtilsService,
		private utilisateurService: UtilisateurService,
		private spinner: NgxSpinnerService,
		private toaster: ToastrService,
		private renderer: Renderer2,
		private sessionService: SessionService
	) {

		if (this.sessionService.getItem(secteur) === secteur_civil) {

			this.signUpForm = this.formBuilder.group({
				USR_LOGIN: ['', Validators.required],
				USR_NOM: ['', Validators.required],
				USR_PRENOM: [''],
				USR_ADRESSE: ['', Validators.required],
				USR_TELEPHONE: ['', Validators.required],
				USR_EMAIL: ['', Validators.required],
				USR_CIN: ['', Validators.required],
				USR_ENTITE_CODE: ['', Validators.required],
				USR_ACTE_NOMINATION: [''],
				USR_ACTE_NOMINATION_DATE: [''],
				USR_REGION_CODE: ['', Validators.required],
				USR_ETAT_CODE: ['EN_ATTENTE'],
				USR_MDP: ['', Validators.required],
				USR_CREATION_DATE: [this.now.split('/').reverse().join('-')],
				USR_GROUPE: [''],
				USR_PRMP_CODE: [''],
				USR_GAC_CODE: ['']
			});

		} else {

			this.signUpForm = this.formBuilder.group({
				USR_LOGIN: ['', Validators.required],
				USR_NOM: ['', Validators.required],
				USR_PRENOM: [''],
				USR_ADRESSE: ['', Validators.required],
				USR_TELEPHONE: ['', Validators.required],
				USR_EMAIL: ['', Validators.required],
				USR_CIN: ['', Validators.required],
				USR_ENTITE_CODE: ['', Validators.required],
				USR_ACTE_NOMINATION: ['', Validators.required],
				USR_ACTE_NOMINATION_DATE: ['', Validators.required],
				USR_REGION_CODE: ['', Validators.required],
				USR_ETAT_CODE: ['EN_ATTENTE'],
				USR_MDP: ['', Validators.required],
				USR_CREATION_DATE: [this.now.split('/').reverse().join('-')],
				USR_GROUPE: [''],
				USR_PRMP_CODE: [''],
				USR_GAC_CODE: ['']
			});

		}

		this.gacUserForm = this.formBuilder.group({
			GAC_LIBELLE: ['', Validators.required],
			GAC_MAIL: [this.signUpForm.get("USR_EMAIL")?.value],
			GAC_TEL: [this.signUpForm.get("USR_TELEPHONE")?.value],
			GAC_CREATION_DATE: [this.now.split('/').reverse().join("-")]
		});

		this.caoUserForm = this.formBuilder.group({
			CAO_FONCTION: ['', Validators.required]
		});

	}

	async ngOnInit() {
		this.getAllEntites();
		this.getAllRegion();

		this.listGroups = (await this.apiService.getAllGroupe().toPromise()).data as I_Groupe[];
		this.listGroups = this.listGroups.filter((gp: I_Groupe) => { return gp.GRP_CODE !== 'S-ADMIN' && gp.GRP_ETAT_CODE === 'ACTIVE' })
	}

	async onLoginFormChanged() {
		this.spinner.show();
		const user_login_form = this.signUpForm.get("USR_LOGIN");
		if (!user_login_form?.value) {
			this.toaster.error("Login obligatoire", 'Erreur');
			this.renderer.selectRootElement(this.input.nativeElement).focus();
		}
		const exist_user = await this.utilisateurService.verifutilisateur(user_login_form?.value).toPromise();
		user_login_form?.setErrors(null);
		if (exist_user.data) {
			this.toaster.error("L'utilisateur existe déjà", 'Erreur');
			this.renderer.selectRootElement(this.input.nativeElement).focus();
			user_login_form?.setErrors(Validators.pattern);
		}
		this.spinner.hide();
	}

	onSelectGroup(e: any) {
		this.user_group = e;
	}

	setFormValues(sourceData: any) {

		this.signUpForm.patchValue({
			USR_LOGIN: sourceData?.USR_LOGIN,
			USR_NOM: sourceData?.USR_NOM,
			USR_PRENOM: sourceData?.USR_PRENOM,
			USR_ADRESSE: sourceData?.USR_ADRESSE,
			USR_TELEPHONE: sourceData?.USR_TELEPHONE,
			USR_EMAIL: sourceData?.USR_EMAIL,
			USR_CIN: sourceData?.USR_CIN,
			USR_ENTITE_CODE: sourceData?.USR_ENTITE_CODE,
			USR_ACTE_NOMINATION: sourceData?.USR_ACTE_NOMINATION,
			USR_ACTE_NOMINATION_DATE: sourceData?.USR_ACTE_NOMINATION_DATE,
			USR_REGION_CODE: sourceData?.USR_REGION_CODE,
			USR_MDP: sourceData?.USR_MDP,
			USR_GROUPE: sourceData?.USR_GROUPE,
			USR_PRMP_CODE: sourceData?.USR_PRMP_CODE,
			USR_GAC_CODE: sourceData?.USR_GROUPE == 'GAC' ? sourceData?.USR_GAC_CODE : ''
		});


	}

	async OnSelectEntites(e: any) {
		this.spinner.show();
		const code_ent: string = e.target ? e.target.value : '';
		this.prmps = (await this.userService.getPRMPByEnt(code_ent).toPromise()).data;
		this.spinner.hide();
	}

	setradio(code: String, libelle: String): void {
		this.setradioClicked.emit({ code, libelle });
	}

	public getAllEntites() {

		let jsonConvert: JsonConvert = new JsonConvert();
		jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
		jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null

		this.subscriptions.push(this.apiService.getAllEntiteWithNoToken<api_res>().subscribe(data => {
			let serializedData = (data.data as Object[]).map(el => {
				return jsonConvert.deserializeObject(el, Entite);
			});
			this.entites = serializedData;
			this.userService.entites$.next(serializedData);
		}));


	}


	public getDirectionByEntCode() {
		if (this.signUpForm.value['USR_ENTITE_CODE'] != "") {
			this.apiService.getDirectionByEntCodeInscription<api_res>(this.signUpForm.value['USR_ENTITE_CODE']).subscribe(data => {
				this.directions = data.data;
				this.userService.direction$.next(data.data);
				this.update();
			});
		}

	}


	public getAllRegion() {
		this.apiService.getAllRegionWithNoToken<api_res>().subscribe(data => {
			this.userService.region$.next(data.data);
			this.update();
		});

	}


	update() {
		let updateForm: { dataUser: any, infoSuppl: any, publicInfo: any } = {
			dataUser: { ...this.signUpForm.value },
			infoSuppl: this.user_group == 'GAC' ? { ...this.gacUserForm.value } : this.user_group == 'CAO' ? { ...this.caoUserForm.value } : {},
			publicInfo: { LOGIN: this.signUpForm.get("USR_LOGIN")?.value, LIEU: this.lieu }
		}
		this.userService.updateForm(updateForm);
		this.formInvalid.emit(updateForm.publicInfo.LIEU === "" || this.signUpForm.invalid || (this.user_group == 'GAC' && this.gacUserForm.invalid) || (this.user_group == 'CAO' && this.caoUserForm.invalid));
	}


	ngOnDestroy() {

		this.subscriptions.forEach(
			el => {
				el.unsubscribe();

			}
		)
	}

}




import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { hide_browser_route } from "src/app/configs/app.json";

import { SessionService } from "src/app/shared/services/session/session.service";
import { secteur } from "src/app/configs/storage_key.json";

@Component({
  selector: 'app-portail',
  templateUrl: './portail.component.html',
  styleUrls: ['./portail.component.scss']
})
export class PortailComponent implements OnInit {

  constructor(private router: Router, private sessionService: SessionService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.sessionService.hasSession()) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.router.navigate(['/welcome'], { queryParams: params });
      })
    }
  }

  RedirectTOhome(user_secteur: string) {

    user_secteur = user_secteur || '';

    switch (user_secteur) {
      case "public":
        this.sessionService.currentPortail$.next({
          type: user_secteur,
          link: "/" + user_secteur,
          textLabel: user_secteur
        });

        break;

      case "prive":
        this.sessionService.currentPortail$.next({
          type: user_secteur,
          link: "/" + user_secteur,
          textLabel: "privé"
        });

        break;

      case "civil":
        this.sessionService.currentPortail$.next({
          type: user_secteur,
          link: "/" + user_secteur,
          textLabel: "Société civil et VIP"
        });

        break;

      default:

        break;
    }
    this.sessionService.setItem(secteur, user_secteur);
    this.router.navigateByUrl(`/login${this.sessionService.currentPortail$.value.link}`, { skipLocationChange: hide_browser_route });
  }

}

<form [formGroup]="signUpForm">
	<div class="mb-2">
		<div class="d-flex radio-custom mb-2" *ngFor="let gp of listGroups">
			<input id="grp_{{gp.GRP_CODE}}" type="radio" (change)="update();onSelectGroup(gp.GRP_CODE)"
				formControlName="USR_GROUPE" value="{{gp.GRP_CODE}}"
				(click)="setradio(gp.GRP_CODE ? gp.GRP_CODE: '', gp.GRP_LIBELLE ? gp.GRP_LIBELLE: '')">
			<label for="grp_{{gp.GRP_CODE}}" class="form-label">{{gp.GRP_CODE}}</label>
		</div>
	</div>

	<div class="mb-2 mt-4 label ">
		<label for="demande" class="form-label mb-3">Je soussigné(e)s</label>

		<input type="text" class="form-control mb-3" id="demande1" placeholder="Nom" formControlName="USR_NOM"
			(keyup)="update()">
		<input type="text" class="form-control mb-3" id="demande2" placeholder="Prénom" formControlName="USR_PRENOM"
			(keyup)="update()">
		<input type="text" class="form-control mb-3" id="demande3" placeholder="Acte de nomination"
			formControlName="USR_ACTE_NOMINATION" (keyup)="update()">
		<div class="icon-date-picker-form">
			<input type="date" id="datepicker" class="form-control mb-3" placeholder="Date"
				formControlName="USR_ACTE_NOMINATION_DATE" (change)="update()" />
		</div>
		<form [formGroup]="gacUserForm" *ngIf="user_group == 'GAC'">
			<input type="text" class="form-control" id="demande2" placeholder="GAC libellé" formControlName="GAC_LIBELLE"
				(keyup)="update()">
		</form>
		<form [formGroup]="caoUserForm" *ngIf="user_group == 'CAO'">
			<input type="text" class="form-control" id="demande2" placeholder="Fonction" formControlName="CAO_FONCTION"
				(keyup)="update()">
		</form>
	</div>

	<div class="mb-5 mt-4 label">
		<label for="demande" class="form-label mb-3">Mes informations</label>
		<div class="mb-3 arrow-select arrow-NoneLabel">
			<select #entite id="entite" class="form-control" formControlName="USR_ENTITE_CODE"
			(change)="OnSelectEntites($event);update()">
			<option value="" disabled>Entité</option>
			<option *ngFor="let entite of userService.entites$.value" [value]="entite.ENT_CODE">{{entite.ENT_LIBELLE}}
			</option>
		</select>
		</div>
		<div class="mb-3 arrow-select arrow-NoneLabel">
			<select id="entite" class="form-control" formControlName="USR_PRMP_CODE"
			(change)="update()" *ngIf="user_group != 'PRMP'">
			<option value="" disabled>Personne Responsable des Marchés Publics</option>
			<option *ngIf="!entite.value" disabled>-- Veuillez choisir l'éntité --</option>
			<option *ngFor="let prmp of prmps" [value]="prmp.PRMP_CODE">{{prmp.PRMP_NOM}} {{prmp.PRMP_PRENOM}}
			</option>
		</select>
		</div>
		<input type="text" #loginInput (blur)="onLoginFormChanged()" class="form-control mb-3" id="demande4"
			placeholder="Login (Matricule)" formControlName="USR_LOGIN" (keyup)="update()">
		<input type="password" class="form-control mb-3" id="demande4" placeholder="Mot de passe" formControlName="USR_MDP"
			(keyup)="update()">
		<textarea placeholder="Adresse" class="form-control mb-3" formControlName="USR_ADRESSE"></textarea>
		<div class="mb-3 arrow-select arrow-NoneLabel">
			<select id="region" class="form-control mb-3" formControlName="USR_REGION_CODE" (change)="update()">
				<option value="" selected disabled>Région</option>
				<option *ngFor="let region of userService.region$.value" [value]="region.REG_CODE">{{region.REG_LIBELLE}}</option>
			</select>
		</div>
		<input type="text" class="form-control mb-3" id="demande4" placeholder="CIN" formControlName="USR_CIN"
			(keyup)="update()">
		<input type="tel" class="form-control mb-3" id="demande6" placeholder="Téléphone" formControlName="USR_TELEPHONE"
			(keyup)="update()">
		<input type="email" class="form-control" id="demande7" placeholder="E-Mail" formControlName="USR_EMAIL"
			(keyup)="update()">
	</div>
</form>
<div class="mt-5 mb-6">
	<input [(ngModel)]="lieu" type="text" class="form-control mb-3" id="demande8" placeholder="Fait à"
		(keyup)="update()">
	<div class="icon-date-picker-form">
		<input type="text" id="datepicker" disabled="disabled" value="{{date_now | date : 'dd/MM/yyyy'}}"
			class="form-control" name="datepicker" placeholder="" />
	</div>
</div>


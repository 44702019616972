import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { I_Server_times } from '../../interfaces/serverTimes';
import { ApiService } from '../api/api.service';
import { signature_fichier } from "src/app/configs/constants.json";
import { ConfirmButtonConfig, ConfirmConfig, NgxMatAlertConfirmService } from 'ngx-mat-alert-confirm';
import { boutton_confirmation } from 'src/app/configs/constants.json';
import { api_res } from '../../interfaces';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

// import { toRomanNumber } from "@stembord/romanize";

@Injectable({
	providedIn: 'root'
})
export class UtilsService {


	paginator$ = new BehaviorSubject<string>("");

	loading$ = new BehaviorSubject<boolean>(true);

	status = new BehaviorSubject<boolean>(false);

	// valider fichier - valeur : { id: true|false }
	typeFichierValid$ =new BehaviorSubject<any>(undefined);

	public DateTimeServer$: I_Server_times;

	public DateSErver$: Date;

	public FullDateServer = new BehaviorSubject<Date>(new Date);

	constructor(
		private apiService: ApiService,
		private spinner: NgxSpinnerService,
		public alertConfirmService: NgxMatAlertConfirmService,
		private toastr: ToastrService
	) {

		this.getServerCurrentTimes();
		this.DateSErver$ = this.FullDateServer.value;
	}


	/**
	 * @author Jonathan
	 * @description Téléchargement d'un fichier à partir d'un blob de type Uint8Array
	 * @param blob Contenu du fichier en Blob
	 * @param filename Nom du fichier avec l'extension
	 * @param fileType Type du fichier (application/pdf)
	 */
	public downloadFile(
		blob: Uint8Array  | undefined,
		filename: string = 'fichier.pdf',
		fileType: string = 'application/pdf'
	) {

		if (blob !== undefined) {


			const blobWithType = new Blob([blob], { type: fileType });
			let blobUrl: string = window.URL.createObjectURL(blobWithType);

			let link: any = window.document.createElement('a');

			if ('download' in link) {
				link.setAttribute('href', blobUrl);

				link.setAttribute('download', filename);

				let event: any = window.document.createEvent('MouseEvents');
				event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null );
				link.dispatchEvent(event);
			}
		} else {
			this.toastr.error('Téléchargement échoué');
		}

	}

	/**
	 *
	 * isEmptyString
	 *
	 * @author A. Manda Ny Aina <mandrianaivo@ingenosya.mg>
	 *	 *
	 * @returns { Observable }
	 *
	 * @description Emmetre un évenement pour reinitialiser mat-paginator
	 *
	 *
	*/
	resetPaginator() {
		this.paginator$.next("");
	}

	/**
	 *
	 * isEmptyString
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * @param { string | String | null | undefined} value  valeur à vérifier
	 *
	 * @returns { boolean } True si la valeur passée en paramètre est considéré comme vide
	 *
	 * @description Vérifie si une valeur est vide ou non
	 *
	 *
	*/
	isEmptyString(value: string | String | null | undefined) {

		if (value?.trim() == "" || value == null || value == undefined) {
			return true;
		}

		return false;

	}


	/**
	 *
	 * formatDate
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * @param { string | null} dateString  date string à parser
	 *
	 * @returns { string | null } Retourne la date parsé sour format fr et retourne null si la date passé en paramètre est null
	 *
	 * @description parse une date string en format dd-MM-yyyy
	 *
	 *
	*/
	formatDate(dateString: string | any) {

		if (dateString == null) {

			return null;

		}

		dateString = dateString.split(" ")[0];

		dateString = dateString.replace("/", "-");

		return formatDate(new Date(dateString), 'dd-MM-yyyy', "fr");

	}


	/**
	 *
	 * parseObjectToArray
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * @param { any } toParseObject  object à parser en array
	 *
	 * @returns { T[] } Onbjet parsé en liste
	 *
	 * @description cette methode permet de parser un objet en liste avec des instance de type <T> si spécifié. Le est appliqué au premier niveau seulement
	 *
	 *
	*/
	parseObjectToArray<T>(toParseObject: any) {

		var resultArray = Object.keys(toParseObject).map(function (objIndex) {

			let person = toParseObject[objIndex] as T;

			return person;

		});

		return resultArray;

	}


	/**
	 *
	 * reformatDate
	 *
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * @param { string | String | null | undefined } date  date à reformater
	 *
	 * @returns { string }
	 *
	 * @description transforme une date string dd/MM/yyyy en format yyyy-MM-dd pour qu'il soit valide pour l'input de type date
	 *
	 *
	*/
	reformatDate(date: string | String | null | undefined): string {

		if (!this.isEmptyString(date)) {

			date = date as string;

			if (date.includes('-')) {

				return date as string;

			}

			date = date.split(" ")[0];

			let dt = date.split('/');

			return dt[2] + "-" + dt[1] + "-" + dt[0];

		} else {

			return "";

		}

	}


	/**
	 *
	 * reformatDateToSend
	 *
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * @param { string | String | null | undefined } date  date à reformater
	 *
	 * @returns { string | null }
	 *
	 * @description transforme une date string yyyy-MM-dd venant de l'input de type date en format dd/MM/yyyy pour soit valide du coté serveur
	 *
	 *
	*/
	reformateDateToSend(date: string | String | null | undefined): string | null {

		if (this.isEmptyString(date)) {

			return null;

		}

		date = date as string;

		date = date.split(" ")[0];

		let d = date?.split('-');

		if (d.length == 3) {
			return d[2] + "/" + d[1] + "/" + d[0];
		}
		else {

			return date as string;

		}

	}


	/**
	 *
	 * isEmptyNumber
	 *
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * @param { number | null | undefined} value  valeur à vérifier
	 *
	 * @returns { boolean } True si la valeur passée en paramètre est considéré comme vide
	 *
	 * @description Vérifie si une valeur est vide ou non
	 *
	 *
	*/
	isEmptyNumber(value: number | Number | null | undefined): boolean {

		if (value == null || value == undefined) {
			return true;
		}

		return false;

	}


	getServerCurrentTimes() {

		this.apiService.getServerCurrentDate().subscribe((res: any) => {

			if (res.status == 200) {
				this.FullDateServer.next(new Date(res.data.date))
				this.DateTimeServer$ = res.data
			} else {
				this.FullDateServer.next(new Date());
			}

		})

	}


	/**
	 *
	 * substractDayFromDate
	 *
	 * @description fonction pour soustraire une date par un nombre de jours donner
	 *
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * @param {string | String | undefined | null} date : la date qu'on veut soustraire
	 *
	 * @param {number} day : nombre de jours à enlever à la date
	 *
	 * @returns {string} "dd/mm/yyyy"
	 *
	*/
	substractDayFromDate(date: string | String | null | undefined, day: number): string {

		if (date != null || date != undefined) {

			/* convert "dd/mm/yyyy" to javascript date format "mm/dd/yyyy" */
			let dateSplited = date.split("/");
			let javascriptFormatDate = dateSplited[1] + "/" + dateSplited[0] + "/" + dateSplited[2];

			/* Create date Object */
			let dateObject = new Date(javascriptFormatDate);

			/* Substract day from this date */
			dateObject.setDate(dateObject.getDate() - day);

			let substractedDate = dateObject.toISOString().slice(0, 10);

			/* convert substractedDate "yyyy-mm-dd" to "dd/mm/yyyy" */
			let substractedDateSplited = substractedDate.split("-");
			let newDate = substractedDateSplited[2] + "/" + substractedDateSplited[1] + "/" + substractedDateSplited[0];

			return newDate as string;
		}

		return "00/00/0000";

	}


	/**
	 *
	 * addDayFromDate
	 *
	 * @description fonction pour ajouter un nombre de jours donner à une date
	 *
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * @param {string | String | undefined | null} date : la date
	 *
	 * @param {number} day : nombre de jours à ajouter à la date
	 *
	 * @returns {string} "dd/mm/yyyy"
	 *
	*/
	addDayFromDate(date: string | String | null | undefined, day: number): string {

		if (date != null || date != undefined) {

			/* convert "dd/mm/yyyy" to javascript date format "mm/dd/yyyy" */
			let dateSplited = date.split("/");
			let javascriptFormatDate = dateSplited[1] + "/" + dateSplited[0] + "/" + dateSplited[2];

			/* Create date Object */
			let dateObject = new Date(javascriptFormatDate);

			/* Add day from this date */
			dateObject.setDate(dateObject.getDate() + day);

			let addedDate = dateObject.toISOString().slice(0, 10);

			/* convert addedDateSplited "yyyy-mm-dd" to "dd/mm/yyyy" */
			let addedDateSplited = addedDate.split("-");
			let newDate = addedDateSplited[2] + "/" + addedDateSplited[1] + "/" + addedDateSplited[0];

			return newDate as string;
		}

		return "00/00/0000" as string;

	}


	//TODO: ajout de commentaire
	romanizeNumber(numberToRomanize: number) {

		// return toRomanNumber(numberToRomanize) + "";
		return numberToRomanize;

	}


	//TODO: ajout de commentaire
	characterizeNumber(numberToCharacterize: number) {

		return String.fromCharCode(97 + numberToCharacterize);

	}


	/**
 *
 * downloadDossier
 *
 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
 *
 * @param { string } dossier_code  code du dossier à télécharger
 * @param { 1 | 2 } exportType  Type de téléchargement, si 1 le dossier seras téléchargé en entier (DAO,DCO,DC)
 * si c'est 2 se l'avis seras téléchargé (ACO, AAOO, AMI)
 * @param { boolean } showSpinner variable de controle d'affichage de spinner pendant le chargement de la téléchargement
 *
 * @returns { FILE } Ficher pdf directement téléchargé
 *
 * @description cette methode permet de télécharger(génération et/ou export) un dossier en PDF.
 *
 *
*/
	downloadDossier(dossier_code: string, exportType: 1 | 2, showSpinner: boolean = true) {

		if (showSpinner) {


			//this.spinner.show();
			//	this.loading$.next(false);
		}

		this.apiService.downloadDossier<any>(dossier_code, exportType).subscribe(
			data => {


				let base64string = data.data;
				this.downloadPdf(base64string, dossier_code);
				this.loading$.next(true);
				this.status.next(false);
				//	this.spinner.hide();
				return data.data;

			}
		);


	}


	/**
	 * Téléchargement à partir d'un base64
	 * @param base64String
	 * @param fileName
	 * @param extension
	 */
	downloadPdf(base64String: string, fileName: string, extension = ".pdf") {
		return new Promise((resolve, reject) => {
			try {
				const source = `data:application/pdf;base64,${base64String}`;
				const link = document.createElement("a");
				link.href = source;
				link.download = `${fileName}${extension}`;
				link.click();
				resolve(true)
			} catch (e) {
				console.error(e)
				reject(false)
			}
		})
	}


	/**
	 * randomString
	 * @author MTH
	 * @returns un string random - utilisable comme unique id
	 */
	randomString(): string{
		return Math.random().toString(36).substring(3);
	}


	/**
	 * validerTypeFichier
	 * @author MTH
	 * @param type type du fichier( pdf, png, ... )
	 * @param fichier le fichier à vérifier( blob )
	 * @param id id unique -pour permettre la réutilisation du service dans plusieurs champs du formulaire
	 * @todo validation fichier multiple - alefa amzao alo fa maikamaika ny mpilalao
	 */
	validerTypeFichier( type: string, fichier: any, id?: string ){
		// prendre signature du fichier : 25 50 44 46 2D ( signature des fichiers pdf  -source wikipedia)
		// generer un string random
		let unique_id =id;

		if( unique_id ==''||unique_id ==undefined ){
			unique_id ='unique_id';	// interessant ve ra atao random string ?
		}

		let fileReader = new FileReader();
		fileReader.onloadend = (e: any) => {

			let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
			let header = "";
			for(var i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}

			// console.log([ 'header', header]);

			switch( type ){
				case 'pdf':

					if( signature_fichier.pdf.indexOf( header ) !=-1 ){
						// console.log( 'type fichier ok -pdf' );
						this.typeFichierValid$.next( { id: unique_id, valid: true } );
					}else{
						// console.log( 'type fichier non ok -pdf' );
						this.typeFichierValid$.next( { id: unique_id, valid: false } );
					}

					break;

				default:
					// console.log( 'type fichier non specifié' );
					this.typeFichierValid$.next( { id: unique_id, valid: false } );

					break;
			}

		};

		// vérification fichier valide
		fileReader.readAsArrayBuffer(fichier);
	}


	/**
	 * @author I. RATSIMBAZAFY <iratsimbazafy@ingenosya.mg>
	 *
	 * ConfimBox
	 *
	 * Modal pour une confirmation avant de changer quelque chose
	*/
	ConfirmBox(message: string) {

		let confirmConfig: ConfirmConfig = {
			title: 'Confirmation',
			titleSize: 20,
			message: message,
			messageSize: 16,
			//matIcon: 'access_alarm',
			//iconAnimation: 'shake',
			iconColor: '',
			buttons: [],
			disableClose: true,
			autoFocus: true,
			restoreFocus: true,
			width: 500
		}

		let buttonArr: Array < ConfirmButtonConfig > = [
			{
				id: boutton_confirmation.NON.id,
				text: boutton_confirmation.NON.text,
				color: 'primary',
				type: 'basic',
				icon: ''
			},
			{
				id: boutton_confirmation.OUI.id,
				text: boutton_confirmation.OUI.text,
				color: 'primary',
				type: 'basic',
				icon: ''
			}
		]

		confirmConfig.buttons = buttonArr;

		let dialogRef = this.alertConfirmService.confirm(confirmConfig);

		return dialogRef.afterClosed();

	}


	/**
	 *
	 * reqDataToObservable
	 *
	 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * @param { any } data  object à parser en observable
	 *
	 * @returns { Observable } Observable avec les données de la requête
	 *
	 * @description cette methode permet parser des objet de requête en observable, si l'objet est déjà un observable
	 * ça retourneras l'observable avec les données de résultat de la requête si non ça crée un observable avec avec le données
	 * en objet
	 *
	 *
	 *
	*/
	reqDataToObservable(data: object | Observable<api_res>){

		if (data instanceof Observable){

			return data.pipe(map(d => d.data));

		}else{

			return of(data);

		}

	}


	/**
	 * @author R.J. Christian
	 *
	 * @description cette methode permet de supprimer un ou des form controll des la FormGrop form passé en paramètre
	 * si le controllname n'est pas parmis les controllNames passés en paramètres
	 *
	 * @param { FormGroup } form formulaire source
	 * @param { string[] } controllNames controllNames à vérifier
	 */
		deleteControllIfNotMached(form: FormGroup, controllNames: string[] = []) {

			for (const key in form.controls) {

				if (Object.prototype.hasOwnProperty.call(form.controls, key)) {

					if (controllNames.findIndex(el => el == key) == -1) {

						form.removeControl(key);

					}
				}

			}

		}


		// TODO: mettre un commentaire
		isFormInvalid(form: FormGroup, controllName: string, validatorName: 'required' | 'min' | 'max' | 'hourMinMax' | 'email' | 'dateMinMax' = "required") {

			let control = form.get(controllName);

			if (control == undefined || control == null) return false;

			// console.log("controllName Error ", control.errors != null ? control.errors[validatorName] : "null");

			if (validatorName != 'hourMinMax' && validatorName != 'dateMinMax') {

				return (control.touched && (control.errors == null ? false : (control.errors[validatorName]) != undefined));

			}

			// console.log("Heure ", (control.touched && (control.errors === null ? false : (control.errors[validatorName]) === null ? false : !control.errors[validatorName])));

			return (control.touched && (control.errors === null ? false : (((control.errors[validatorName]) === null || control.errors[validatorName]) === undefined) ? false : !control.errors[validatorName]));

		}

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { I_CNM } from 'src/app/shared/interfaces/cnm';
import { I_User } from 'src/app/shared/models';
import { BehaviorCnmService } from 'src/app/shared/services/cnm/behavior-cnm.service';
import { CnmService } from 'src/app/shared/services/cnm/cnm.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-popup-pre-requis',
  templateUrl: './popup-pre-requis.component.html',
  styleUrls: ['./popup-pre-requis.component.scss']
})
export class PopupPreRequisComponent implements OnInit, OnDestroy {

  titleModal: string

  subscription: Subscription[] = []

  List_Secretaire: I_CNM[] = []

  constructor(
    private dialogRef: MatDialogRef<PopupPreRequisComponent>,
    public BehaviorCnm$: BehaviorCnmService,
    private UserService: UserService,
    private CnmService: CnmService
  ) { }


  ngOnInit() {

    console.log(' Data Modal ... ', this.BehaviorCnm$.data_for_cnm$.value)

    /**Emission de la données de l'utililsateur connecté dans le behavior  */

    this.BehaviorCnm$.getGroupeUserConnected(this.UserService.connectedUserB$.value as I_User, (callBack: any) => {

			this.titleModal = 'Vérification de l’exhaustivité des PJ nécessaires du PRMP'

    })

    this.getListSecretaire();

  }


  /**Récupérer la liste des secretaire  */
  getListSecretaire() {

    if (!this.BehaviorCnm$.list_CNM$.value) {
      this.subscription.push(this.CnmService.getListCNM().subscribe((res: any) => {

        if (res.data) {
          this.BehaviorCnm$.emitListCNM(res.data)

          this.List_Secretaire = res.data.filter((el: I_CNM | any) => {

            if (el.TYPE_COMPTE_CNM_FK == 'SECR') return el;

          })
        }

      }))
    } else {

      this.List_Secretaire = this.BehaviorCnm$.list_CNM$.value.filter((el: I_CNM | any) => {

        if (el.TYPE_COMPTE_CNM_FK == 'SECR') return el;

      })

    }

  }


  onCheckValidate() {

    this.BehaviorCnm$.validation_document_in_popup.isValidateByPRMP = true

  }


  onCloseModal() {

    this.dialogRef.close()

  }


  exportFichePresentation() {

  }


  ngOnDestroy() {

    this.subscription.forEach(element => {

      element.unsubscribe();

    })

  }

}

import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from './converter';
import { DroitGroupe, I_DroitGroupe } from "./droitGroupe";

@JsonObject("Groupe")
export class Groupe {

	@JsonProperty("GRP_CODE", String)
	GRP_CODE?: String = undefined;

	@JsonProperty("GRP_LIBELLE", String)
	GRP_LIBELLE?: String = undefined;

	@JsonProperty("GRP_ETAT_CODE", String)
	GRP_ETAT_CODE?: String = undefined;

	@JsonProperty("GRP_CREATION_DATE", DateConverter)
	GRP_CREATION_DATE?: DateConverter = undefined;

	@JsonProperty("GRP_CREATION_USER", String)
	GRP_CREATION_USER?: String = undefined;

	@JsonProperty("GRP_DESCRIPTION", String)
	GRP_DESCRIPTION?: String = undefined;

	@JsonProperty("DroitGroupe", [DroitGroupe])
	DroitGroupe?: [DroitGroupe] = undefined;

	constructor() { }
}

export class I_Groupe {

	GRP_CODE?: String;

	GRP_LIBELLE?: String;

	GRP_ETAT_CODE?: String;

	GRP_CREATION_DATE?: DateConverter;

	GRP_CREATION_USER?: String;

	GRP_DESCRIPTION?: String;

	DroitGroupe?: [I_DroitGroupe];
}


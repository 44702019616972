import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { I_ACTEUR, I_CNM, I_DATA } from '../../interfaces/cnm';
import { I_User } from '../../models';
import { CnmService } from './cnm.service';

@Injectable({
  providedIn: 'root'
})
export class BehaviorCnmService {

  list_CNM$ = new BehaviorSubject<any | []>([]);

  list_param_piece$ = new BehaviorSubject<any | []>([]);

  data_for_cnm$ = new BehaviorSubject<I_DATA | undefined>({ ACTEUR:null, DOC_TYPE:null,DOC_CODE:null,DOC_STATUS:null, IS_MAJ:false });

  data_acteur_connected$ = new BehaviorSubject<I_ACTEUR | undefined>(undefined)

  status_workflow_actuel$ = new BehaviorSubject<any | undefined>([]);

  validation_document_in_popup = {

      isValidateByPRMP : false,
      isValidateBySECR : false

  }

  constructor(
    private CnmService:CnmService,
    private toastr:ToastrService
  ) { }


    public emit_data_for_cnm(data:I_DATA){

        this.data_for_cnm$.next(data);

    }

    public emit_acteur_data(data:I_ACTEUR){

        this.data_acteur_connected$.next(data);

    }


    public emitListCNM( listCNM:any[] ){ /** */

      this.list_CNM$.next(  listCNM );

    }

    public emitListParamPiece( ParamPiece:any[] ){

        this.list_param_piece$.next( ParamPiece );

    }

    /**========================= Observable ======================== */


    public getGroupeUserConnected(user:I_User, callBack:any){

      switch (user.USR_GROUPE){
          case 'CNM':

            this.CnmService.getCNMByCode(user.USR_CODE).subscribe((res:any) => {

              if(!this.data_acteur_connected$.value){

                  this.emit_acteur_data({ GROUPE_ACT:'CNM', CODE_USER:res.CNM_CODE,  TYPE_ACT:res.TYPE_COMPTE_CNM_FK,  NOM_ACT:res.CNM_NOM,  PRENOM_ACT:res.CNM_PRENOM })
                  callBack(this.data_acteur_connected$.value)
                  console.log(this.data_acteur_connected$.value)

              }

            });
            break;

          case 'PRMP':

            this.emit_acteur_data({
                GROUPE_ACT: 'PRMP',
                CODE_USER: user.USR_CODE as string,
                TYPE_ACT:null,
                NOM_ACT:user.USR_NOM as string,
                PRENOM_ACT:user.USR_NOM_PRENOM as string
            })
            callBack(this.data_acteur_connected$.value)
            console.log(this.data_acteur_connected$.value)

            break;

          default:
            break;
      }

    }

    private getStatusWorkflow(doc_code:string, user:I_User){

      this.CnmService.getCurrentWorkflow(doc_code).subscribe((res:any)=> {

        console.log(doc_code, res)
        this.status_workflow_actuel$.next(res.data)
        if(res.data.length == 0){

          this.CnmService.insertWorkFlow(1, doc_code, null, user.USR_CODE as string).subscribe((res:any) => {
            if(res.status == 200){
                this.toastr.success('Le dossier est entré dans CNM', 'WORKFLOW')
            }
          })

        }else{

            switch (res.data[0]?.WP_ID) {
              case '1':this.toastr.success('Le dossier est en cours de vérification des pièces ', 'WORKFLOW')

                break;
              case '2':this.toastr.success('Le dossier est en cours de dispatch au PCNM  ', 'WORKFLOW')

                break;
              case '3':this.toastr.success('Le dossier est en cours d\'Examen', 'WORKFLOW')

                break;
              case '4':this.toastr.success('Le dossier est en cours de Vérification', 'WORKFLOW')

                break;
              case '5':this.toastr.success('Le dossier est en cours de validation', 'WORKFLOW')

                break;
              default:
                break;
            }

        }


      })


    }




}


<table mat-table [dataSource]="dataSource" matSort class="mat-custom-table">
	<ng-container matColumnDef="code">
			<th mat-header-cell mat-sort-header *matHeaderCellDef> Code de la demande </th>
			<td mat-cell *matCellDef="let demande"> {{ demande.DMD_ELEM_CODE }} </td>
	</ng-container>

	<ng-container matColumnDef="etat">
		<th mat-header-cell *matHeaderCellDef> Etat </th>
		<td mat-cell *matCellDef="let demande"> {{ demande.ETAT_CODE_FK }} </td>
	</ng-container>

	<!-- <ng-container matColumnDef="nom_prenom">
			<th mat-header-cell *matHeaderCellDef> Personne Responsable du Marché Publique </th>
			<td mat-cell *matCellDef="let element"> {{ element.PRMP_NOM_PRENOM }} </td>
	</ng-container> -->

	<!-- <ng-container matColumnDef="etat">
		<th mat-header-cell *matHeaderCellDef> Etat </th>
		<td mat-cell *matCellDef="let element"> {{ element.ETAT_CODE_FK }} </td>
</ng-container> -->

	<!-- <ng-container matColumnDef="date_create">
			<th mat-header-cell *matHeaderCellDef>  </th>
			<td mat-cell *matCellDef="let ppm"> {{ ppm.PPM_HDR_INIT_DATE | conversion: 'castDateFrom-dd/mm/yyyy_hh:mm:ss' | date: 'dd MMMM y' }}
			</td>
	</ng-container> -->
<!--
	<ng-container matColumnDef="annee">
			<th mat-header-cell *matHeaderCellDef> Année </th>
			<td mat-cell *matCellDef="let ppm"> {{ ppm.PPM_HDR_ANNEE }} </td>
	</ng-container> -->

	<!-- <ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef> Statut </th>
			<td mat-cell *matCellDef="let demande"> {{ demande.ETAT_CODE_FK }} </td>
	</ng-container> -->

	<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef> Action </th>
			<td mat-cell *matCellDef="let demande">
					<ul class="table-actions">
							<li matTooltip="Edition" matTooltipPosition="above" >
									<span class="armp-ico-edit-table"></span>
							</li>
							<!-- <li *ngIf="!isCNM"  matTooltip="Export PPM en PDF" matTooltipPosition="above">
									<span class="armp-ico-pdf-table" [routerLink]="['pdf-viewer',ppm.PPM_HDR_CODE]"></span>
							</li> -->
							<!-- <li *ngIf="!isCNM"  matTooltip="Export AGPM en PDF" matTooltipPosition="above">
									<span class="armp-ico-file-table"  [routerLink]="['pdf-AGPM-viewer',ppm.PPM_HDR_CODE]"></span>
							</li> -->
							<li matTooltip="Expedition" matTooltipPosition="above">
									<span class="armp-ico-diffusion-table"></span>
							</li>
							<!-- <li *ngIf="isCNM"  matTooltip="Passer à la validation du procès verbal" matTooltipPosition="above">
									<span class="armp-book" (click)="createPV(ppm.PPM_HDR_CODE)"></span>
							</li> -->
							<!-- <li *ngIf="!isCNM"  matTooltip="Activation/Modification" matTooltipPosition="above">
									<app-checkbox-shitch   [Id]="ppm.PPM_HDR_CODE" class="switch" [ngClass]="{'desactive': ppm.ETAT_CODE_FK == 'EXPEDIE' || ppm.ETAT_CODE_FK == 'ACCORDE'}" [disabled] ="ppm.ETAT_CODE_FK == 'EXPEDIE' || ppm.ETAT_CODE_FK == 'ACCORDE'" [checked]="ppm.ETAT_CODE_FK != 'DESACTIVE'" (onChecked)="onChangeState(ppm.PPM_HDR_CODE, ppm.ETAT_CODE_FK)"></app-checkbox-shitch>
							</li> -->
					</ul>
			</td>
	</ng-container>
</table>


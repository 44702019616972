<div class="bannier-content offset">
    <img src="assets/images/banner_v2.jpg" alt="Bannier">
</div>
<header class="sticky offset">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-xl navbar-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#primaryNavbarArmp" aria-controls="primaryNavbarArmp" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

            <div class="collapse navbar-collapse" id="primaryNavbarArmp">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item dropdown dmenu " *ngIf="EplanificationAccess()">
                        <a class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown">
													e-Planification
						</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM">
                            <a class="dropdown-item" [routerLink]="['/e-planification/demande']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEplDemandeAccess()">Demande de besoin</a>
                            <a class="dropdown-item" [routerLink]="['/e-planification/besoin']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEplBesoinAccess()">Besoins</a>
                            <a class="dropdown-item" [routerLink]="['/e-planification/regroupement']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEplDemandeAccess()">Regroupement des besoins</a>
                            <a class="dropdown-item" [routerLink]="['/e-planification/plan-passation']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEplPPMAccess() || userService.connectedUserB$.value?.OPER_CODE ">Plan de Passation de Marchés</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown dmenu " *ngIf="(isPublic && EdossierAccess()) || isCivil">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown">
													e-Dossier
												</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM min-widthMenu">

                            <li class="dropdown-sous-menu sous-menu-edossier">
                                <a class="dropdown-toggle dropdown-item sous-sm" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Présélection</a>
                                <!-- Pré-séléction sous menu -->
                                <ul class="dropdown-menu sub-dossier">
                                    <li><a class="dropdown-item" [routerLink]="['/e-dossier/particulier'+'/'+allModePassation.AMI+'/'+modeRestreint]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">AMI</a></li>
                                    <li><a class="dropdown-item" [routerLink]="['/e-dossier/particulier'+'/'+allModePassation.APQ+'/'+modeRestreint]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">APQ</a></li>
                                    <li><a class="dropdown-item" [routerLink]="['/e-dossier/particulier'+'/'+allModePassation.AOR+'/'+modeRestreint]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">AOR</a></li>
                                </ul>
                            </li>

                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+allModePassation.ACO]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">Dossier de consultation ouverte</a>
                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+allModePassation.AOO]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">Dossier d'appel d'offres</a>
                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+allModePassation.AMI]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">Dossier de consultation (PI)</a>
                            <!-- APQ / AOR -->
                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+allModePassation.APQ]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">Dossier de Pré-qualification</a>
                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+allModePassation.AOR]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">Dossier d'appel d'offres restreint</a>
                            <!-- --------- -->


                            <li class="dropdown-sous-menu sous-menu-edossier">
                                <a class="dropdown-toggle dropdown-item sous-sm" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Contrat cadre</a>
                                <!-- Contrat cadre sous menu -->
                                <ul class="dropdown-menu sub-dossier2">
                                    <li><a class="dropdown-item" [routerLink]="['/e-dossier/particulier'+'/'+contratCadreMode+'/'+modeRestreint]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Dossier (Appel à Candidature)</a></li>
                                    <li><a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+contratCadreMode]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Remise en concurrence (Lettre d'Invitation)</a></li>
                                </ul>
                            </li>

                            <!-- AOR(???) / AD / GG -->
                            <a class="dropdown-item" [routerLink]="['/e-dossier'+'/'+rapportJustificatifMode]" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLinkActive]="['is-active']" *ngIf="checkEdossierAccess()" [ngClass]="{'disabled-link': statusService.isConnectedToNetwork$.value ? false : true}">
															Rapport justificatif (AD,GG)
														</a>

                        </div>
                    </li>
                    <!-- <li class="nav-item">
						<a class="nav-link" [routerLink]="['#']" [state]="{ is_menu: true }"
							[routerLinkActive]="['is-active']">e-Paiement</a>
					</li> -->
                    <li class="nav-item dropdown dmenu " *ngIf="isPrive">
                        <a class="nav-link dropdown-toggle" id="" data-toggle="dropdown">
													{{ isSercteurPrive ? "e-dossier" :"e-Paiement"}}
												</a>
                        <div class="dropdown-menu sm-menu">
                            <a class="dropdown-item" [routerLink]="['/e-paiement/liste-avis']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Liste des avis</a>
                            <a class="dropdown-item" [routerLink]="['/e-paiement/mon-panier']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Mon panier</a>
                            <a class="dropdown-item" [routerLink]="['/e-paiement/historique-achat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Mon historique d'achats</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown dmenu " *ngIf="isPrive || EsoumissionAccess()">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown">
											e-Soumission
										</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM">
                            <a class="dropdown-item" *ngIf="isPrive" [routerLink]="['/e-soummission/liste-avis']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Liste des avis</a>
                            <a class="dropdown-item" [routerLink]="isPrive ? ['/e-soummission/liste-dossier'] : ['/e-soummission']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">{{isPrive ? 'Mes dossiers' : 'Listes des offres reçues'}}</a>
                            <a class="dropdown-item" [routerLink]="['/e-soummission/mes-dossiers']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">{{isPrive ? 'Liste des demandes d\'éclaircissement' : 'Demande d\'éclaircissement'}}</a>
                            <a class="dropdown-item" *ngIf="isPrive" [routerLink]="['/e-soummission/mes-depots']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Mes dépôts</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown dmenu " *ngIf="isPublic && EevaluationAccess() || isCivil && EevaluationAccess()">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown">
													e-Evaluation
												</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM">
                            <li>
                                <a class="dropdown-item" [routerLink]="['/e-evaluation/dossier-pret-traiter/preparation']" data-toggle="dropdown" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Préparation ouverture des plis</a>
                            </li>
                            <a class="dropdown-item sous-sm" data-toggle="dropdown" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Ouverture des plis</a>
                            <ul class="dropdown-menu sub-eval">
                                <li>
                                    <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/ouverture-ami']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">AMI</a>
                                    <a class="dropdown-item" [routerLink]="['/e-evaluation/AOPQ/liste-candidatures']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">APQ</a>
                                    <a class="dropdown-item" [routerLink]="['/e-evaluation/dossier-pret-traiter']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">OFFRES (AOO, AOR, ACO)</a>
                                    <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/contrat-cadre']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Contrat cadre</a>
                                    <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/proposition-candidat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Propositions techniques et financières</a>
                                    <!-- <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/proposition-candidat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Propositions financières</a> -->
                                    <!-- <a class="dropdown-item" [routerLink]="" [state]="{ is_menu: true }">Préparartion Ouverture de plis</a> -->
                                </li>
                            </ul>
                            <!--
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/dossier-pret-traiter']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Ouverture des plis</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/liste-dossier-evaluer']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Evaluation AOO/ACO/AOR</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/proposition-candidat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Proposition candidat</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/AOPQ/liste-candidatures']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Liste des candidatures AOPQ</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/ouverture-ami']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Ouverture AMI</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/evaluation-dc']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Evaluation DAO/DCO/DAOR</a>
                            <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/proposition-candidat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Proposition candidat</a>
                            -->
                            <li class="dropdown-sous-menu">
                                <a class="dropdown-toggle dropdown-item sous-sm" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Evaluation</a>
                                <ul class="dropdown-menu sous-menu sub-eval2">
                                    <li>
                                        <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/liste-dossier-evaluer']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">OFFRES (AOO, AOR, ACO)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/contrat-cadre/liste-dossier-evaluer']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Contrat cadre</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/evaluation-dc']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Propositions techniques et financières</a>
                                    </li>
                                    <!-- <li>
                                        <a class="dropdown-item" [routerLink]="['/e-evaluation/evaluation/evaluation-dc']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Propositions financières</a>
                                    </li> -->
                                </ul>
                            </li>

                            <li>
                                <a class="dropdown-item" [routerLink]="['/e-evaluation/proposition-candidat']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Proposition candidat (AD, GG, AOR)</a>
                            </li>
                        </div>
                    </li>
                    <li class="nav-item dropdown dmenu " *ngIf="isPrive || EcontratAccess()">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">
							e-Contrat
						</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM">
                            <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLink]="['/e-contrat/' + allModePassation.AOO]">
								Marché sur appel d'offres
							</a>
                            <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLink]="['/e-contrat/' + allModePassation.AMI]">
								Marché sur PI
							</a>
                            <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLink]="['/e-contrat/' + allModePassation.GG]">
								Marché de gré à gré
							</a>
                            <a class="dropdown-item sous-sm" data-toggle="dropdown" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">
								Contrat cadre
							</a>
                            <ul class="dropdown-menu sub-menu sub-contrat">
                                <li>
                                    <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Contrat cadre (Marché)</a>
                                    <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Marché subséquent</a>
                                </li>
                            </ul>
                            <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLink]="['/e-contrat/' + allModePassation.ACO]">
								Convention
							</a>
                            <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']" [routerLink]="['/e-contrat/' + allModePassation.AD]">
								Achat direct
							</a>
                            <!-- <a class="dropdown-item sous-sm" data-toggle="dropdown" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">
								Avenant
							</a>
                            <ul class="dropdown-menu sub-menu">
                                <li>
                                    <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif au marché</a>
                                    <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif au marché subséquent</a>
                                    <a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif à une convention</a>
                                </li>
                            </ul> -->
                            <li class="dropdown-sous-menu">
                                <a class="dropdown-toggle dropdown-item sous-sm" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Avenant</a>
                                <ul class="dropdown-menu sous-menu sub-contrat2">
                                    <li><a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif au marché</a></li>
                                    <li><a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif au marché subséquent</a></li>
                                    <li><a class="dropdown-item" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Relatif à une convention</a></li>
                                </ul>
                            </li>
                        </div>
                    </li>
                    <li class="nav-item dropdown dmenu " *ngIf="isPublic && EmanagementAccess()">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown">
							e-Management
						</a>
                        <div class="dropdown-menu sm-menu responsive-dropdownM w-100">
                            <a class="dropdown-item" [routerLink]="['/e-management/tableaux-de-bord']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Tableaux de bord</a>
                            <a class="dropdown-item" [routerLink]="['/e-management/statistique']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Statistique</a>
                            <a class="dropdown-item" [routerLink]="['/e-management/performance']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Performance</a>
                        </div>
                    </li>
                    <li class="nav-item" *ngIf="isPrive || RecoursAccess()">
                        <a class="nav-link" [routerLink]="['/recours']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Recours</a>
                    </li>
                </ul>
                <div class="option-part">
                    <a (click)='navigate("/welcome")'>
                        <i class="armp-icon armp-notification">
													<span class="badge badge-danger">{{ notificationService.notifications$.value?.all.length}}</span>
												</i>
                    </a>
                    <i class="armp-icon armp-user" (click)='navigate("/user-visualisation")'>
                    </i>
                    <a class="formBasket" *ngIf="isPrive" (click)='navigate("/e-paiement/mon-panier")'>
                        <i class="armp-shopping-cart">
                            <span class="badge badge-danger">{{ myCartService.cartNumber$.value }}</span>
                        </i>
                    </a>

                    <i class="armp-icon armp-gear dropdown-toggle" data-toggle="dropdown" *ngIf="user_groupe_global == 'S-ADMIN'"></i>
                    <div class="dropdown-menu sm-menu params-menu responsive-dropdownM w-params">
                        <a class="dropdown-item" [routerLink]="['/parametrages/general']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Général</a>
                        <a class="dropdown-item" [routerLink]="['/parametrages/autorite-contractantes']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Autorités
							Contractantes</a>
                        <a class="dropdown-item" [routerLink]="['/parametrages/marches']" [state]="{ is_menu: true }" [routerLinkActive]="['is-active']">Marchés</a>
                    </div>

                    <i class="armp-logout logout-custom" (click)="logOut()">
						<span>Déconnexion</span>
					</i>
                </div>
            </div>
        </nav>
    </div>
</header>

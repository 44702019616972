import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppToolsService } from '../../services/tools/app-tools.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../../services/session/session.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

	constructor(
		private toolsSevice: AppToolsService,
		private toastr$: ToastrService,
		private session$: SessionService
	) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {

				let errorMsg = '';

				console.log("ETOOOO", error.error);
				if (error.status === 401) {
					this.toastr$.info(error.error.message)
					this.session$.clearSession()
				} else {
					if (error.error instanceof ErrorEvent) {
	
						alert("Une erreur s'est produite, veuillez bien vérifier votre connexion internet ! ");
	
						console.log('this is client side error');
						errorMsg = `Error: ${error.error.message}`;
	
					}
	
					else if (error.status >= 500 && error.status <= 599) {
						console.log('this is server side error');
						errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
					}
	
					alert("Une erreur s'est produite du côté serveur, veuillez réessayer votre action ! ");
				}


				console.warn(errorMsg);

				this.toolsSevice.hideLoader();

				return throwError(errorMsg);
			}));

	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { base_url, endpoints } from 'src/assets/api.json';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(
    private http: HttpClient,
    public toaster: ToastrService
  ) { }

  OtpToaster(data: any) {

    if (data) {
      if (data.status == '200') {
        this.toaster.success(data.message, 'Succès')
      } else {
        this.toaster.error(data.message, 'Erreur')
      }
    }

  }

  VerifierCode(param: any): Observable<any> {

    const url = base_url + endpoints.e_soummission.verificationcodeotp;
    return this.http.get<any>(url, { params: { CODE: param.Code, INDICE: param.Indice } })

  }

  VerifierCodeLogin(param: any): Observable<any> {

    const url = base_url + endpoints.verificationOTPLogin_opeco
    return this.http.get<any>(url, { params: { CODE: param.Code, LOGIN: param.Login, INDICE: param.Indice } })

  }

  SendCodebyMail(indice: any): Observable<any> {

    let data = { INDICE: indice };

    const url = base_url + endpoints.e_soummission.envoiecodeotpinmail
    return this.http.post<any>(url, data);

  }
  
  SendCodeLoginbyMail(data: any): Observable<any> {

    const url = base_url + endpoints.sendOTPLogin_opeco
    return this.http.post<any>(url, data);

  }

  GetParamOtp(): Observable<any> {

    const url = base_url + endpoints.e_soummission.getparamotp
    return this.http.get<any>(url);

  }

  GetParamOtpLogin(): Observable<any> {

    const url = base_url + endpoints.getparamotp
    return this.http.get<any>(url);

  }

}

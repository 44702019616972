import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";

export function MyCustomPaginatorIntl(): MatPaginatorIntl  {

 
    const customPaginatorIntl = new MatPaginatorIntl();
  
  
    customPaginatorIntl.itemsPerPageLabel = 'Elément par page :';
    customPaginatorIntl.firstPageLabel = 'Première page';
    customPaginatorIntl.lastPageLabel = 'Dernière page';
    customPaginatorIntl.nextPageLabel = 'Page suivante';
    customPaginatorIntl.previousPageLabel = 'Page précedente';
    customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length }`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex}  de  ${length}`;
    };
  
    return customPaginatorIntl;
  
}
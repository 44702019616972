<div class="container card-form-width">
	<h1>Inscription OPECO</h1>
	<app-breadcrumbs></app-breadcrumbs>

	<div class="row clearfix">
			<div class="col-md-12">
					<div class="card-bg long-card">
							<h3>Formulaire</h3>
							<app-inscription-opeco></app-inscription-opeco>
					</div>
			</div>
	</div>

	<div class="mt-4">
		<div class="card-action">
			<button type="button" class="btn btn-cancel-form" (click)="onBackHome()">Annuler</button>
			<button type="button" class="btn btn-send-form" [disabled]="!isFormValid" (click)="isFormValid && onSubmit()">Enregistrer</button>
		</div>
	</div>
</div>

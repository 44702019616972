import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-info-inscription',
  templateUrl: './info-inscription.component.html',
  styleUrls: ['./info-inscription.component.scss']
})
export class InfoInscriptionComponent implements OnInit {

  @Input()
  public receivedRadio : any;

  constructor(public userService:UserService) { }

  ngOnInit(): void {

  }


  date:Observable<number>= new Observable((obs)=>{
    setInterval(()=>{
      obs.next(Date.now())
    }, 1)

  });


}

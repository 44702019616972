import { Injectable } from '@angular/core';
import { ConfirmButtonConfig, ConfirmConfig, NgxMatAlertConfirmService } from 'ngx-mat-alert-confirm';
import { boutton_confirmation, USER_GROUP_CODE } from 'src/app/configs/constants.json';
import { ToastrService } from 'ngx-toastr';
import { StatusService } from 'src/app/shared/services/status/status.service';
import { RecoursApiService } from '../recours-api/recours-api.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

import { secteur } from "src/app/configs/storage_key.json";
import { secteur_prive, secteur_public, secteur_civil } from "src/app/configs/parametre.json";
import { SessionService } from 'src/app/shared/services/session/session.service';
import { api_res } from 'src/app/shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { TRAITEMENT } from '../../interfaces/traitement-recours';
import { DEMANDE_ELEMENT, ELEMENT_DEMANDE } from 'src/app/shared/models/demande';
import { UserService } from 'src/app/shared/services/user/user.service';

@Injectable()
export class TraitementRecoursService {

	public assigner$ = new BehaviorSubject<Boolean|undefined>(false);

	public openedTraitementRecours$ = new BehaviorSubject<TRAITEMENT|undefined>(undefined);

	public openedDemandeElement$ = new BehaviorSubject<DEMANDE_ELEMENT|undefined>(undefined);

	public openedListeElementDemande$ = new BehaviorSubject<ELEMENT_DEMANDE[]>([]);


  constructor(
		public alertConfirmService: NgxMatAlertConfirmService,
		public statusService: StatusService,
		public toastr: ToastrService,
		public apiService:RecoursApiService,
		public utilsService: UtilsService,
		public sessionService: SessionService,
		public userService: UserService,
		) {

	}


	hydrate(data:any){

		if (data.traitement){

			this.openedTraitementRecours$.next(data.traitement);

		}

	}


	editionConfirmBox() {

		let confirmConfig: ConfirmConfig = {
			title: 'Confirmation',
			titleSize: 20,
			message: "Ce recours n'a pas encore été assigné à un responsable, Voulez-vous vous y attribuer  ?",
			messageSize: 16,
			iconColor: '',
			buttons: [],
			disableClose: true,
			autoFocus: true,
			restoreFocus: true,
			width: 500
		}

		let buttonArr: Array < ConfirmButtonConfig > = [
			{
				id: boutton_confirmation.NON.id,
				text: boutton_confirmation.NON.text,
				color: 'primary',
				type: 'basic',
				icon: ''
			},
			{
				id: boutton_confirmation.OUI.id,
				text: boutton_confirmation.OUI.text,
				color: 'primary',
				type: 'basic',
				icon: ''
			}
		]

		confirmConfig.buttons = buttonArr;

		let dialogRef = this.alertConfirmService.confirm(confirmConfig);

		return dialogRef.afterClosed();

	}


	verifyAssignation(recoursCode:any) : Boolean | undefined{

		this.apiService.getTRByRecours<api_res>(recoursCode).subscribe((data) => {

			if(data.data) {

				this.assigner$.next(true);

			}

		});

		return this.assigner$.value;

	}


	giveDefaultValueToEmptyString(data: string | String | undefined | null, customDefaultValue?: string) {

		if (customDefaultValue) return this.utilsService.isEmptyString(data) ? customDefaultValue : data;
		else return this.utilsService.isEmptyString(data) ? "" : data;

	}


	giveDefaultValueToEmptyNumber(data: number | undefined | null, customDefaultValue?: number) {

		if (customDefaultValue) return this.utilsService.isEmptyNumber(data) ? customDefaultValue : data;
		else return this.utilsService.isEmptyNumber(data) ? 0 : data;

	}

	//Verifier le secteur de l'user connecte
	verifySecteur(): Boolean {

		let user_secteur = this.sessionService.getItem(secteur);
		if (user_secteur == secteur_prive || user_secteur === secteur_civil) {

			return	true;

		// } else if (user_secteur == secteur_public && this.userService.connectedUserB$.value?.USR_GROUPE === USER_GROUP_CODE.CRR) {

		// 	return true;

		} else {

			return false;

		}

	}

}

<div class="row">
    <div class="col-12 mb-4">
        <h4 class="title-form-modal">{{titleModal}}</h4>
    </div>
    <div class="card-action pl-3 mb-2 d-flex justify-content-between flex-wrap align-items-center">
        <div class="col-md-6" *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT != 'CNM' ">
            <a (click)="exportFichePresentation()" class="d-flex">
                <span class="form-label label-down">Télécharger le fiche de Présentation pour le PPM/AGPM  </span> &nbsp;
                <span matTooltip="Télécharger" matTooltipPosition="above" class="armp-download down-ppm-agpm"></span>
            </a>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography content-pj mb-0">
    <form class="col-md-12 form-modal">
        <div class="row">
            <div class="col-12 mt-3">

                <app-table-param-piece></app-table-param-piece>

                <div *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'PRMP'" class="col-12 form-group mt-3">
                    <input type="checkbox" [checked]="BehaviorCnm$.validation_document_in_popup.isValidateByPRMP" (change)="onCheckValidate()" class="styled-checkbox-armp" id="validationInput">
                    <label for="validationInput">Je valide que tous ces documents sont conforme</label>

                </div>

                <div class="col-12 form-group mt-3" *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM'">
                    <div class="row">
                        <div class="col-md-4">
                            <h5>Affectation</h5>

                            <input *ngFor="let secr of List_Secretaire" type="checkbox" [checked]="secr.CNM_CODE == BehaviorCnm$.data_acteur_connected$.value?.CODE_USER" class="styled-checkbox-armp" id="UserConnected.USR_CODE">
                            <label for="validationInput">{{ this.BehaviorCnm$.data_acteur_connected$.value?.NOM_ACT + ' '  +  this.BehaviorCnm$.data_acteur_connected$.value?.PRENOM_ACT}}</label>

                        </div>
                        <div class="col-md-8">
                            <h5>Commentaire en cas de rejet</h5>
                            <textarea name="commentaire" class="form-control" id="" cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
</mat-dialog-content>
<app-action-popup-pre-requis (CloseModalEvent)="onCloseModal()"></app-action-popup-pre-requis>
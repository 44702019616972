<div class="main-portail">
  <app-main-logo></app-main-logo>
  <div class="container">
    <h1>Choisir un Portail</h1>
    <div class="row">
      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card card-portail" (click)="RedirectTOhome('prive')">
          <span class="icon-portail armp-sectuer-prive"></span>
          <div class="card-body">
            <p class="card-text">Secteur Privé</p>
          </div>
          <div class="hover-content">
            <div class="h-100 d-flex align-items-center">
                <div class="w-100 responsive-txtPortail">
                    <h4>Secteur Privé</h4>
                    <p class="mt-0 mb-0">- Liste ASAPC</p>
                    <p class="mt-0 mb-0">- Achat Cahier de Charge</p>
                    <p class="mt-0 mb-0">- Soumission</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card card-portail" (click)="RedirectTOhome('public')">
          <span class="icon-portail armp-secteur-public"></span>
          <div class="card-body">
            <p class="card-text">Secteur Public</p>
          </div>
          <div class="hover-content">
            <div class="h-100 d-flex align-items-center">
                <div class="w-100 responsive-txtPortail">
                    <h4>Secteur Public</h4>
                    <p class="mt-0 mb-0">- Détermination des besoins</p>
                    <p class="mt-0 mb-0">- Plan de Passation des Marchés</p>
                    <p class="mt-0 mb-0">- Lancement mise en concurrence</p>
                    <p class="mt-0 mb-0">- DAO/DC/DCO/RJ</p>
                    <p class="mt-0 mb-0">- Contrat</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mb-4">
        <div class="card card-portail" (click)="RedirectTOhome('civil')">
          <span class="icon-portail armp-societe-civile-vip"></span>
          <div class="card-body">
            <p class="card-text">Société Civile et VIP</p>
          </div>
          <div class="hover-content">
            <div class="h-100 d-flex align-items-center">
                <div class="responsive-txtPortail">
                  <h4>Secteur civile et VIP</h4>
                  <p>- Visualisation toutes la chaine de passation</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

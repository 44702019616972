import { Component, OnInit } from '@angular/core';
import { StatusService } from '../../services/status/status.service';
import { AppToolsService } from '../../services/tools/app-tools.service';

@Component({
	selector: 'app-saving-status',
	templateUrl: './saving-status.component.html',
	styleUrls: ['./saving-status.component.scss']
})
export class SavingStatusComponent implements OnInit {

	constructor(public statusService: StatusService) { }

	ngOnInit(): void {
	}

}

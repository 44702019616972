<div class="main-spinner mt-2">

    <div class="section-head-modal">
        <h4 class="title-form-modal">Upload fichier [ {{modalData.fileName}} ]</h4>
    </div>

    <app-spinner-layouts *ngIf="!loading$.value"></app-spinner-layouts>

    <mat-dialog-content class="mat-typography">

        <form class="col-md-12 form-modal main-add-row p-0">
            <div class="scroller">
                <table class="mat-custom-table">
                    <thead>
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-block m-bottom" *ngIf="message$.value != undefined">
                            <td class="full-width center">
                                <h4>Un fichier a déjà été uploadé.</h4>
                            </td>
                            <td>
                                <span class="remove-btn" (click)="deleteFile()">
                                    <i class="armp-ico-delete-table delete-list"></i>
								</span>
                            </td>
                        </tr>
                        <tr>
                            <div class="center style-modal-Upload">
                                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [accept]="type">
                                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                        <div class="upload-file-from">
                                            <div class="icone-upload">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                                    <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                                </svg>
                                            </div>
                                            Déposer un fichier
                                            <button class="btn btn-modalite btn-add mt-2 btn-upload-form" type="button" (click)="openFileSelector()" [disabled]="status$.value == false">Séléctionner un fichier</button>
                                        </div>
                                    </ng-template>
                                </ngx-file-drop>
                                <div class="upload-table mt-3">
                                    <table class="table">
                                        <tbody class="upload-name-style">
                                            <tr *ngFor="let item of files; let i=index">
                                                <td class="name-file-upload"><strong>{{ item.relativePath }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center" colspan="6" *ngIf="loading$.value">Aucun résultat</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div class="card-action form-group longmodalf action-form-modal-spec">
            <button mat-button (click)="cancel()" class="btn btn-cancel-form" [disabled]="status$.value == false">Annuler</button>
            <button mat-button cdkFocusInitial (click)="save()" [disabled]="status$.value == false" class="btn btn-add-derogation mt-4 btn-send-form">Valider</button>
        </div>
    </mat-dialog-actions>

</div>

import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../../../../shared/services/user/user.service';
import { I_SelectedLot } from './../../../features/my-cart/e-pay-cart/e-pay-cart.component';
import { I_Cart_ApiResponse } from './../../models/cart.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { base_url, endpoints } from "src/assets/api.json";
import { indiceMailPayment, message } from './../../configs/constant.json';
import { number } from '@amcharts/amcharts4/core';
import { api_res } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MyCartService {

  /**
   * Liste des lots séléctionnés
   */
  public selectedLots = new BehaviorSubject<I_SelectedLot[] | undefined>( undefined );
  /**
   * Total des montats des lots
   */
  public lotTotalAmount = new BehaviorSubject<number>( 0 );
  /**
   * Le mode de paiement séléctionné
   */
  public selectedPaymentMethod = new BehaviorSubject<string | null>( null );

	/**
	 * Nombre de panier
	*/
	public cartNumber$ = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private toastrService: ToastrService
  ) { }

  /**
   * Lister les éléments d'un panier
   *
   * @return Observable
   */
  listCartElements(): Observable<I_Cart_ApiResponse | null> {

    return new Observable( obs => {

      this.userService.getConnectedOpeco();

      this.userService.connectedOpeco$.subscribe( opeco => {

        // console.log(46, opeco );

        if ( opeco ) {

          const opecoCode = opeco.OPER_CODE;
          const url = `${ base_url }/${ endpoints.ePaiement.cartContentByOperCode }?OPER_CODE=${ opecoCode }`;

          this.http.get<I_Cart_ApiResponse>( url ).subscribe( response => {

            obs.next( response );
            obs.complete();

          }, error => {

            obs.error( error );

          } );

        } else {

          obs.next( null );

        }

      }, error => {

        obs.error( error );

      } );

    } );

  }

  /**
   * Lister les modes de paiement disponibles
   *
   * @return Observable
   */
  listPaymentMethod(): Observable<any> {
    const url = `${ base_url }/${ endpoints.ePaiement.paymentMethodList }`;
    return this.http.get<any>( url );
  }

  /**
   * Vérifier le compte de l'utilisateur
   *
   * @param paymentMethod Le mode de paiement
   * @returns Observable
   */
  checkAccount( paymentMethod: string ): Observable<any> {
    const url = `${ base_url }${ endpoints.ePaiement.checkAccount }`;
    return this.http.post<any>( url, { PAYMENT_METHOD: paymentMethod } );
  }

  /**
   * Vérifier le numéro de quittance
   *
   * @param numQuittance Le numéro de quittance
   * @returns Observable
   */
  checkNumQuittance( numQuittance: string ): Observable<any> {
    const url = `${ base_url }${ endpoints.ePaiement.checkNumQuittance }`;
    return this.http.post<any>( url, { NUM_QUITTANCE: numQuittance } );
  }

  /**
   * Envoyer l'OTP par mail
   *
   * @returns Observable
   */
  sendOTPByMail(): Observable<any> {
    const url = `${ base_url }${ endpoints.e_soummission.envoiecodeotpinmail }`;
    const data = {
      INDICE: indiceMailPayment
    };
    return this.http.post<any>( url, data );
  }

  /**
   * Vérifier l'OTP
   *
   * @returns Observable
   */
  checkOTP( otp: string ): Observable<any> {
    const url = `${ base_url }${ endpoints.e_soummission.verificationcodeotp }`;
    return this.http.get<any>( url, { params: { CODE: otp, INDICE: indiceMailPayment } } );
  }

  /**
   * Effectuer le paiement
   * ( A remplacer plus tard par le vrai url de l'api de paiement )
   *
   * @returns Observable
   */
  pay( paymentData: any ): Observable<any> {
    const url = paymentData.successfulOption ?
      `${ base_url }${ endpoints.ePaiement.paySuccessful }`:
      `${ base_url }${ endpoints.ePaiement.payUnsuccessful }`;
    return this.http.post<any>( url, paymentData );
  }

  /**
   * Sauvegarder la transaction
   *
   * @returns Observable
   */
  saveTransaction( data: any ): Observable<any> {
    const url = `${ base_url }${ endpoints.ePaiement.saveTransaction }`;
    return this.http.post<any>( url, data );
  }

  /**
   * Grouper un tableau par un clé
   *
   * @param key clé à grouper
   * @returns any
   */
  arrayGroupBy( key: string ): any {
    return function group( theArray: any[] ) {
      return theArray.reduce( ( acc, obj ) => {
        const property = obj[ key ];
        acc[ property ] = acc[ property ] || [];
        acc[ property ].push( obj );

        return acc;
      }, {} );
    };
  }

  /**
   * Emettre la nouvelle valeur de la liste des lots séléctionnés
   *
   * @param selectedLots La liste des lots séléctionnés
   */
  emitSelectedLots( selectedLots: I_SelectedLot[] | undefined ): void {
    this.selectedLots.next( selectedLots );
  }

  /**
   * Emettre la nouvelle valeur du "total du montant des lots séléctionnés"
   *
   * @param currentLotTotalAmount Le total actuel des montants des Lots
   */
  emitLotTotalAmount( currentLotTotalAmount: number ): void {
    this.lotTotalAmount.next( currentLotTotalAmount );
  }

  /**
   * Emettre la nouvelle valeur du mode paiement séléctionné
   *
   * @param currentSelectedPaymentMethod Le mode de paiement actuellement séléctionné
   */
  emitSelectedPaymentMethod( currentSelectedPaymentMethod: string | null ): void {
    this.selectedPaymentMethod.next( currentSelectedPaymentMethod );
  }

  /**
   * ************************ PAIEMENT ************************************
   */


   private _getPaymentObject( lots: any[] ): string {
    // supprimer les propriétés inutiles
    let minifiedLots = lots.map( lot => {
      let _lot = lot;
      delete( _lot.index );
      delete( _lot.amount );
      return _lot;
    } );

    let getLotByDossier = this.arrayGroupBy( 'dossierCode' );
    let groupedLots = getLotByDossier( minifiedLots );

    return JSON.stringify( groupedLots );
  }

  _getDataTransaction( data: any, success: boolean ): any {
    return {
      TRANSACTION_DATA: {
        PAIEMENT_OBJET: this._getPaymentObject( data.lots ),
        PAIEMENT_MODE: data.paymentMethod,
        PAIEMENT_MONTANT: data.totalAmount,
        PAIEMENT_STATUS: success ? 1 : 0,
        PAIEMENT_INFORMATION: success ? 'Le paiement a été efféctué avec succès' : 'Le paiement a échoué',
        PAIEMENT_TRANSACTION_ID: Date.now()
      },
      LOT_DATA: {
        lot: data.lots,
        opecoInfo: data.opecoInfo
      }
    };
  }

  /**
   * Sauvegarder la transaction
   *
   * @param dataTransaction Données de transaction
   */
  _saveTransaction( dataTransaction: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      this.saveTransaction( dataTransaction )
        .subscribe( response => {
          if ( response.status ) {
            this.toastrService.success( response.message );
            resolve( {
              action: 'CONFIRM',
              success: true
            } );
          } else {
            this.toastrService.error( response.message );
            resolve( {
              action: 'CONFIRM',
              success: false
            } );
          }
        }, error => {
          this.toastrService.error( message.internalServerError );
          resolve( {
            action: 'CONFIRM',
            success: false
          } );
        } );
    } );
  }


	getCartNumber() {

		return this.http.get<api_res>(`${base_url}${endpoints.ePaiement.getCartNumber}`);

	}


	_getCartNumber() {

		this.getCartNumber().subscribe(data => {

			if (data.data) {

				localStorage.setItem('cartNumber', JSON.stringify(data.data));
				this.cartNumber$.next(data.data);

			}
// 			else {
// //
// 				localStorage.setItem('cartNumber', '0');
// 				this.cartNumber$.next(0);

// 			}

		});

	}





}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortailComponent } from './pages/portail/portail.component';
import { AuthentificationRoutingModule } from './authentification-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { BackNavigationComponent } from './components/back-navigation/back-navigation.component';
import { MainLogoComponent } from './components/main-logo/main-logo.component';
import { EgpAuthComponent } from './components/egp-auth/egp-auth.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FormLoginComponent } from './features/form-login/form-login.component';
import { FormForgotPasswordComponent } from './features/form-forgot-password/form-forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { FormInscriptionComponent } from './features/form-inscription/form-inscription.component';
import { InfoInscriptionComponent } from './features/info-inscription/info-inscription.component';
import { InscriptionOpecoComponent } from './features/inscription-opeco/inscription-opeco.component';
import { OpecoInscriptionComponent } from './pages/opeco-inscription/opeco-inscription.component';
import { InscriptionOpecoService } from './services/inscription-opeco.service';
import { UserVisualisationComponent } from './pages/user-visualisation/user-visualisation.component';


@NgModule({
  declarations: [PortailComponent, LoginComponent, BackNavigationComponent, MainLogoComponent, EgpAuthComponent, ForgotPasswordComponent, FormLoginComponent, FormForgotPasswordComponent, InscriptionComponent, FormInscriptionComponent, InfoInscriptionComponent, InscriptionOpecoComponent, OpecoInscriptionComponent, UserVisualisationComponent],
  imports: [
    CommonModule,
    AuthentificationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    InscriptionOpecoService
  ]
})
export class AuthentificationModule { }

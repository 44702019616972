import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UtilsService } from '../../services/utils/utils.service';

/**
 * @title Configurable paginator
 */
@Component({
	selector: 'app-pagination-table',
	templateUrl: './pagination-table.component.html',
	styleUrls: ['./pagination-table.component.scss']
})
export class PaginationTableComponent implements OnInit, AfterViewInit, AfterContentInit {

	@ViewChild('paginator') paginator: MatPaginator;

	// MatPaginator Inputs
	@Input('length') length: number = 100;
	@Input('pageSize') pageSize: number = 10;
	@Input('pageSizeOptions') pageSizeOptions: number[] = [5, 10, 25, 100];
	@Input('pageIndex') pageIndex : number = 0;

	// MatPaginator Output
	@Output('page') page: EventEmitter<PageEvent> = new EventEmitter();


	constructor(
		private utils: UtilsService
	) { }


	setPageSizeOptions(setPageSizeOptionsInput: string) {

		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);

	}


	setPage(event: PageEvent) {

		this.page.emit(event);

	}


	ngOnInit(): void {
	}


	ngAfterViewInit(){
	}


	ngAfterContentInit(){

		this.utils.paginator$.subscribe((res: string) => {
			this.paginator?.firstPage();
		});
		if (this.paginator) {
			this.paginator.pageIndex = 0;
		}
	}

}

import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


declare var $: any;


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'ARMP-FRONT';

	constructor(private route: Router, private spinner: NgxSpinnerService) {

		this.route.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.spinner.show();
					break;
				}
				case event instanceof NavigationEnd: {
					this.spinner.hide();
					break;
				}
				case event instanceof NavigationError: {
					this.spinner.hide();
					break;
				}
				case event instanceof NavigationCancel: {
					this.spinner.hide();
					break;
				}
			}
		});

	}

	ngOnInit() {

	}
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { version, name } from "../../../../../package.json";


@Component({
	selector: 'app-main-footer',
	templateUrl: './main-footer.component.html',
	styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit, OnDestroy {

	app_version = version;
	app_name = name;

	emplacement: string | undefined;
	is_static: false;

	subscription: Subscription = new Subscription();

	constructor(
		public appToolService: AppToolsService,
	) {

		this.subscription.add(
			this.appToolService.emplacement$.subscribe(d => {
				this.emplacement = d;
			})
		);

	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

}

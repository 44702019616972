import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
	selector: 'app-checkbox-shitch',
	templateUrl: './checkbox-shitch.component.html',
	styleUrls: ['./checkbox-shitch.component.scss']
})
export class CheckboxShitchComponent implements OnInit {
	
	@Input('Id') Id : string = 'switch';
	@Input() disabled: boolean;
	@Input() checked: boolean;
	@Input() formControl: string;
	@Output() onChecked = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit(): void {
	}

	onChange(event: any): void {
		const value = event.target.checked;
		this.onChecked.emit(value);
	}

}

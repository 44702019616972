import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { base_url, endpoints } from '../../../../../../../assets/api.json';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  constructor(
    private http: HttpClient

  ) { }

  getAllUtilisateur(): Observable<any> {

    const url = base_url + endpoints.parametrages.general.utilisateur.getAllUtilisateur
    return this.http.get<any>(url);

  }

  ModifieEtatUtilisateur(code:string, etat:string): Observable<any> {

    let data = { USR_CODE: code, ETAT_LIBELLE: etat };

    const url = base_url + endpoints.parametrages.general.utilisateur.changeEtatUtilisateur
    return this.http.post<any>(url, data);

  }

  getUtilisateurByCODE(code:string): Observable<any> {

    const url = base_url + endpoints.parametrages.general.utilisateur.getUtilisteurByCode
    return this.http.get<any>(url,{ params: { cdUtilisateur: code }});

  }
  getEntiteMereDir() : Observable<any> {
    const url = base_url + endpoints.parametrages.general.utilisateur.getEntiteMereDir
    return this.http.get<any>(url);
  }

  getAllPrmp() : Observable<any>{
    const url = base_url + endpoints.parametrages.autorites_contractantes.getAllPrmp
    return this.http.get<any>(url);

  }
  UpdateUtilisateur(data: any) : Observable<any> {
    const url = base_url + endpoints.parametrages.general.utilisateur.updateUtilisateurByCode;
    return this.http.put<any>(url, data);
  }

  getAllRegion() : Observable<any> {

    const url = base_url + endpoints.getAllRegion;
    return this.http.get<any>(url);
  }
  verifutilisateur(userlogin:any): Observable<any>{
    const url = base_url + endpoints.parametrages.general.utilisateur.verifUtilisateurisexist;
    return this.http.get<any>(url,{ params: { USR_LOGIN: userlogin }} );
  }

  insertutilisateur(data:any): Observable<any>{
    const url = base_url + endpoints.parametrages.general.utilisateur.insertUtilisateur;
    return this.http.post<any>(url, data );

  }



}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscription-layout',
  templateUrl: './inscription-layout.component.html',
  styleUrls: ['./inscription-layout.component.scss']
})
export class InscriptionLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

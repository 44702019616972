import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import { StatusService } from '../../services/status/status.service';
import { AppToolsService } from '../../services/tools/app-tools.service';
import { UtilsService } from '../../services/utils/utils.service';
import { messages } from 'src/app/configs/constants.json';
import { api_res } from '../../interfaces'
import { ModalDeterminationBesoinComponent } from 'src/app/modules/e-planification/features/determination/modal-determination-besoin/modal-determination-besoin.component';
import { TraitementRecoursService } from '../../modules/recours/services/traitement-recours/traitement-recours.service';
import { RecoursService } from '../../modules/recours/services/recours/recours.service';
// import { UploadService } from '../../services/upload/upload.service';
import { I_PDF_Upload_Request } from '../../interfaces/modalData';
import { ModalReponseDemandeComponent } from '../../modules/recours/features/modal-reponse-demande/modal-reponse-demande.component';
import { Modal } from '@amcharts/amcharts4/core';
import { MontantLettrePipe } from '../../pipes/montant-lettre.pipe';

  /**
	 *
	 * @author R.J. Christian <jrazanamihoatra@ingenosya.mg>
	 *
	 * UploadFileComponent
	 *
	 * @description Composant d'un système d'upload de fichier.
	 *
	 * @instuctions
	 * Le Format de la paramètre de la requête API "uploadFileRequest" devrais toujours contenir un
	 * input "file" pour contenir le fichier à uploader
	 *
	 * Le Format de "data" dans la réponse de la requête "fileExistRequest" doit être {
	 *                       "message": "message",
	 *                       "status": true
	 *                   }
	 *
	 *
	 *
	 */
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {


  form: FormGroup;

  private subscriptions: Subscription[] = [];

  private file: File;

  public files: NgxFileDropEntry[] = [];

  public message$ = new BehaviorSubject<string | undefined>(undefined);

  public status$ = new BehaviorSubject<boolean>(false);

  public loading$ = new BehaviorSubject<boolean>(false);

  public isFileExist: object;

  private chiffreEnLettre: string;

  public allowedFilesList: string[] = [];

  public allowedFiles: string;

  public isAllowed: boolean;

	public type = '.pdf, .jpeg';


  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: {
      fileName: string,
      fileExistRequest?: I_PDF_Upload_Request,
      deleteFileRequest?: I_PDF_Upload_Request,
      uploadFileRequest: I_PDF_Upload_Request,
      fileInputKey?: string,
      fileNumber?: number,
      allowedFiles?: string[];
    },
    private dialogRef: MatDialogRef<ModalDeterminationBesoinComponent>,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public apiService: ApiService,
    private toolsService: AppToolsService,
    public statusService: StatusService,
    public traitementService: TraitementRecoursService,
    public recoursService: RecoursService,
    // public uploadService: UploadService,
    public dialogClose: MatDialogRef<UploadFileComponent>,
    private lettre: MontantLettrePipe
  ) { }


  ngOnInit(): void {

    this.loading$.next(false);

    this.status$.next(false);

    this.message$.next(undefined);

    this.isFileExistCheck();

		if (this.modalData.allowedFiles == null || this.modalData.allowedFiles == undefined || this.modalData.allowedFiles == []) {

			this.allowedFilesList = ['.pdf'];

			this.type = '.pdf';

		} else {

			this.allowedFilesList = this.modalData.allowedFiles;

			this.type = this.modalData.allowedFiles.toString();

		}

  }


  public dropped(files: NgxFileDropEntry[]) {

    this.files = files;

    // if (this.modalData.fileNumber) {

      if (this.modalData.fileNumber && files.length > this.modalData.fileNumber) {

        this.files.length = 0;

        this.chiffreEnLettre = this.lettre.transform(this.modalData.fileNumber);

        this.toastr.error('Vous ne pouvez pas téléchagé plus de ' + this.chiffreEnLettre.toLowerCase() + ' fichier(s) en même temps', messages.ActionNotAuthorized);

				return;

      // } else {
      // }

			}
				for (const droppedFile of files) {

					this.isFileAllowed(droppedFile.fileEntry.name);

					// Is it a file?
					if (droppedFile.fileEntry.isFile) {

						if (!this.isFileAllowed(droppedFile.fileEntry.name)) {

							this.files.length = 0;

							this.toastr.error('Seul les fichiers au format ' + '"' + this.allowedFilesList?.join(', ') + '"' + ' sont autorisés', messages.ActionNotAuthorized);

						} else {

							const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

							fileEntry.file((file: File) => {

							// Here you can access the real file
							// console.log(droppedFile.relativePath, file);

							// You could upload it like this:
							this.file = file;

							});

						}

					} else {

						// It was a directory (empty directories are added, otherwise only files)
						const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
						// console.log(droppedFile.relativePath, fileEntry);

					}

					}

    // }

  }


  public fileOver(event: any) {

    // console.log(event);

  }


  public fileLeave(event: any) {

    // console.log(event);

  }


  // Verifier si le fichier existe
	isFileExistCheck() {
		// console.log('staticParamsDataOfRequest', {...this.modalData.fileExistRequest?.staticParamsDataOfRequest});

		if (this.statusService.isConnectedToNetwork$.value === false) {

		  this.toastr.warning(messages.actionNotAvailableMessage, messages.networkStatusMessages.offlineMessage);

		} else {

			// console.log("tokony manao vérif");

			this.modalData.fileExistRequest?.request({...this.modalData.fileExistRequest.staticParamsDataOfRequest}).subscribe(
			(data: any) => {


			this.status$.next(true);

			if (data.status) {

				// console.log("CE FICHIER EXISTE");

				this.message$.next(data.message);

				// console.log(this.message$.value);

			}

			this.loading$.next(true);

			}

		)

			this.status$.next(false);

		}

  }


  // supprimer le fichier
  deleteFile() {

    if (this.statusService.isConnectedToNetwork$.value === false) {

      this.toastr.warning(messages.actionNotAvailableMessage, messages.networkStatusMessages.offlineMessage);

    } else {

			this.toolsService.confirm({
				title: messages.confirmationTitle,
				message: messages.confirmationFileSuppression
			}).subscribe(confirmation => {

				if (confirmation) {

					this.loading$.next(false);

					this.modalData.deleteFileRequest?.request({...this.modalData.deleteFileRequest.staticParamsDataOfRequest}).subscribe(data => {

						// this.status$.next(true)

						if (data.status) {

							this.toastr.success(messages.suppressionSucces, messages.suppressionTitle);

							this.loading$.next(true);

							setTimeout(() => {

					this.dialogClose.close();

							}, 2000);

						} else {

							this.toastr.error(messages.suppressionError, messages.suppressionTitle);

							this.loading$.next(true);

						}

					});

					this.status$.next(false);

				}

			});

    }


  }


  cancel() {

	this.dialogClose.close();

  }


  save() {

    if (this.statusService.isConnectedToNetwork$.value === false) {

      this.toastr.warning(messages.actionNotAvailableMessage, messages.networkStatusMessages.offlineMessage);

    } else if (this.files.length < 1) {

			this.toastr.warning(messages.noFileUploaded, messages.ActionNotAuthorized);

    } else {

			this.toolsService.confirm({
				title: messages.confirmationTitle,
				message: messages.confirmationUpload
			}).subscribe(confirmation => {

				if (confirmation) {

					// conversion
					this.loading$.next(false);

					let reader = new FileReader();

					if (this.file) {

						let confirmation = true;

						if (this.message$.value !== undefined) {

							confirmation = confirm(messages.replaceFile);

						}

						if (confirmation) {

							reader.readAsDataURL(this.file);

							reader.onload = () => {

								// console.log(reader.result);

								this.modalData.uploadFileRequest.request({
									...this.modalData.uploadFileRequest.staticParamsDataOfRequest,
									[this.modalData.fileInputKey ? this.modalData.fileInputKey : "file"]: reader.result?.toString() as string,
								}).subscribe(
									data => {

										if (data.status) {

											this.toastr.success(messages.uploadSuccess, messages.uploadTitle);

											this.loading$.next(true);

											setTimeout(() => {

												this.dialogClose.close();

											}, 2000);

										} else {

											this.toastr.error(messages.uploadError, messages.uploadTitle);

										}


									})

							}

							this.status$.next(false);

						} else {

							// console.log("Makato confirmation");

							this.status$.next(false);


						}

					} else {

						this.loading$.next(true);
						this.dialogClose.close();

					}

					this.status$.next(false);

					reader.onerror = (error) => {

						// console.log('Error: ', error);
					}

					// console.log("TONGA ATO VE", this.status$.value);

				}

			});

    }

  }


  get deletable() {

    return this.modalData.deleteFileRequest !== undefined;

  }


  get existCheckingEnabled() {

    return this.modalData.fileExistRequest !== undefined;

  }

  isFileAllowed(fileName: string) {

	  this.isAllowed = false;

    const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png'];

    const regex = /(?:\.([^.]+))?$/;

    const extension = regex.exec(fileName);

		// console.log('TY LE EXTENSION ANLE FICHIER UPLOADÉ', extension);

    if (undefined !== extension && null !== extension) {

      for (const ext of this.allowedFilesList) {

        if (ext === extension[0]) {

          this.isAllowed = true;

        }

      }

    }

    return this.isAllowed;

  }


  ngOnDestroy() {

    this.subscriptions.forEach(el => {
      el.unsubscribe();
    });

  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base_url, endpoints, base_url_local } from '../../../../assets/api.json';
import { api_res } from '../../interfaces';
import { BehaviorCnmService } from './behavior-cnm.service';

@Injectable({
  providedIn: 'root'
})
export class CnmService {


    constructor(
      private http: HttpClient
    ) { }


    /**
     * @description Declanchement workflow transition (Insertion ligne dans table WT)
     * @param wp_id
     * @param code_ppm
     * @param code_dossier
     * @param cnm_user_code
     * @returns
     */
    insertWorkFlow<T>(wp_id:number, code_ppm:string | null , code_dossier:string | null , cnm_user_code:string){

      return this.http.post<api_res | T>((base_url + endpoints.CNM.workFlow.insertWorkflow), {
        DATA_WORKFLOW_TRANSITION: {
          WP_ID_FK:wp_id,
          PPM_HDR_CODE_FK: code_ppm,
          DOSSIER_CODE_FK: code_dossier,
          CNM_CODE_FK: cnm_user_code
        }
      });


    }


    /**
     * @description Récuperer le statut de workflow actuel
     * @param code document
     * @returns
     */
    getCurrentWorkflow<T>(code:any){

      return this.http.get<api_res | T>((base_url + endpoints.CNM.workFlow.getCurrentWorkflow), {
        params:{CODE:code}
      })

    }


    /**
     * @description Récupérer la tâche précédent
     * @param wp_id
     * @returns
     */
    getPreviousWorkflow<T>(wp_id:any){

      return this.http.get<api_res | T>((base_url + endpoints.CNM.workFlow.getPreviousWorkflow), {
        params:{WP_ID:wp_id}
      })

    }


    /**
     * @description Récuperer la tâche suivante
     * @param wp_id
     * @param pass
     * @returns
     */
    getNextWorkflow<T>(wp_id:string, pass:string | null){

      return this.http.post<api_res | T>((base_url + endpoints.CNM.workFlow.getNextWorkFlow), {
        WP_ID:wp_id,
        PASS:pass
      })

    }


    /**
     * Récupération de la liste des CNM vient de l'API
     */
    getListCNM(){

       return this.http.get<api_res>(endpoints.CNM.getAllCNM);

    }

		getListParamPiece(doc_type:string , doc_ref:string , recev_code:string , param_piece_code:string){

				return this.http.get<api_res>(endpoints.CNM.getListParamPiece, { params : {

					DOCUMENT_TYPE:doc_type,
					DOCUMENT_REF_CODE:doc_ref,
					RECEV_CODE_FK:recev_code,
					PARAM_PIECE_CODE_FK:param_piece_code

				} })

		}


    /**
     * @description Récuperer un(e) utililsateur CNM par code utililsateur
     * @param code
     * @returns api_res
     */
    getCNMByCode(code:any){

        return this.http.get<api_res>(endpoints.CNM.getCNMByCODE + '?CNM_CODE=' + code)

    }


    /**
     * @description Validation  par PRMP permettant de changer le statut de PPM en EXPEDIE
     * @param ppm_hdr_code
     * @returns message
     */
    validationPPM(ppm_hdr_code:string){


      return this.http.post<api_res>(base_url + endpoints.CNM.validationExpediePPM,
        { params: {
          DATA_VALIDATION_PPM: {
            PPM_HDR_CODE_FK:ppm_hdr_code
          }
        }
      });

    }


    /**
     * @description Récupérer l'acteur actuel qui traite le document
     * @param code_ppm
     * @returns
     */
    getActeurPositionneByCode<T>(code_ppm:string){

      return this.http.get<api_res>(base_url + endpoints.CNM.workFlow.getActeurActifWorkflow,
        {params: { CODE: code_ppm }}
      );

    }


    /**
     * @description Validation des PJ par SECRETAIRE qui permet de changer l'etat du document en RECEVABLE
     * @param ppm_hdr_code
     * @param cnm_code
     * @returns
     */
    validationPpmParSecretaire(ppm_hdr_code:string, cnm_code:string){

        return this.http.post<api_res>(base_url + endpoints.CNM.validationPPMCNM,
          {
            DATA_VALIDATION_PPM: {
              PPM_HDR_CODE_FK: ppm_hdr_code,
              CNM_CODE_FK: cnm_code
            }
          }
        )
    }


    /**
     * @description Rejection des PJ par SECRETAIRE qui permet de changer l'etat du document en REJETE
     * @param ppm_hdr_code
     * @param cnm_code
     * @returns
     */
  rejeterPpmParSecretaire(ppm_hdr_code:string, cnm_code:string){

      return this.http.post<api_res>(base_url + endpoints.CNM.rejeterPPMCNM,
        {
              DATA_PPM: {
                 PPM_HDR_CODE_FK: ppm_hdr_code,
                 CNM_CODE_FK: cnm_code
              }
        }
      )
  }


  /**
   * @description Récuperer l'acteur par le type compte
   * @param type_compte_cnm
   * @returns
   */
  getActeurCNMByTypeCompte(type_compte_cnm:string){

      return this.http.get<api_res>(base_url + endpoints.CNM.getACteurCNMByTypeCompte,
          {params:{TYPE_COMPTE_CNM_CODE:type_compte_cnm}}
      )

  }


  uploadPJ(fichier:any, code_ppm:string, num_pj:number){

    let edpts;
    switch (num_pj) {
      case 1:
        edpts = endpoints.CNM.print.uploadFP;
        break;
      case 2:
        edpts = endpoints.CNM.print.uplaodDGOV;
        break;
      case 3:
        edpts = endpoints.CNM.print.uploadAllAGPM;
        break;
      case 4:
        edpts = endpoints.CNM.print.uploadAllPPM;
        break;
      case 5:
        edpts = endpoints.CNM.print.uploadAllPVComission;
        break;
      default:
        break;
    }

    const url = base_url + edpts;
    const data = {file:fichier, PPM_HDR_CODE:code_ppm}

    return this.http.post(url, data);

  }


  downloadPrePreRequis(){

    this.http.get<api_res>(base_url + endpoints.CNM.downloadPrerequis).subscribe(data => {

    });

  }

}



/**
 * @author rico5k2
 * @description Déclaration des constantes
 */
export const C = {
  ENDPOINT: 'modules/e-Planification',
  API_RESPONSE_OK: '200',
  API_RESPONSE_KO: '400',
  API_RESPONSE_NOT_FOUND: '404',
  URL_CONFIG_API: 'app/configs/api.json',
  DEMANDE_ETAT_CODE_ACTIVE: 'ACTIVE',
  DEMANDE_ETAT_CODE_DESACTIVE: 'DESACTIVE',
  DEMANDE_ETAT_CODE_EXPEDIE: 'EXPEDIE',

	TOASTR_INFO_TITLE: 'Info !',
	TOASTR_SUCCESS_TITLE: 'Succès !',
	TOASTR_ERROR_TITLE: 'Erreur !',
	TOASTR_WARNING_TITLE: 'Attention !',

	MSG_SERVER_ERROR: 'Erreur rencontrée au niveau du serveur'
};

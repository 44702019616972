import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'montant'
})

export class MontantLettrePipe implements PipeTransform {

	public unite: string[];
	public dizaine_p: string[];
	public dizaine: string[];
	public sub: string[];

	constructor() {
		this.unite = ['', 'Un ', 'Deux ', 'Trois ', 'Quatre ', 'Cinq ', 'Six ', 'Sept ', 'Huit ', 'Neuf ']; // 0 - 9
		this.dizaine_p = ['Dix ', 'Onze ', 'Douze ', 'Treize ', 'Quatorze ', 'Quinze ', 'Seize ']; // 10, 11, 12, 13, 14, 15, 16
		this.dizaine = ['', 'Dix ', 'Vingt ', 'Trente ', 'Quarante ', 'Cinquante ', 'Soixante ', 'Soixante-Dix ', 'Quatre-Vingt ', 'Quatre-Vingt-Dix ']; // 10 - 90
		this.sub = ['', 'Mille', 'Million', 'Milliard'];
	}


	lire(n: any, v: any): any {

		n = '' + n;
		if (n == '') { return v; }
		let k: string = '_' + n.length;
		// let k = n.length-1;

		let fnc: { _1: (n: any, v: any) => {}, _2: (n: any, v: any) => {}, _3: (n: any, v: any) => {} } = {

			'_1': (n, v): any => {

				let l = v.split('-');
				let o = l.lastIndexOf('Dix ');
				let r = [v, this.unite[n]].join('');

				if (/Dix $/.test(v) && n < 7) {
					r = v.replace(/Dix $/, this.dizaine_p[n]);
				}

				return { n: '', v: r };
			},
			'_2': (n, v): any => {	// dizaine

				let c = n.split('').map((x: any) => Number(x));
				let r = [v, this.dizaine[c[0]]].join('');
				// console.log( { n: c[1], v: r } );
				return { n: c[1], v: r };
			},
			'_3': (n, v): any => {	// centaine
				let c = n.split('').map((x: any) => Number(x));
				// c[0] = Number( c[0] );
				// console.log( c );
				let cent = 'Cent ';
				if (c[0] == 0) { cent = ''; }
				if (c[0] == '1') { c[0] = 0; }
				// console.log( this.unite );
				let r = [v, this.unite[c[0]], cent].join('');
				c[0] = '';
				// console.log( { n: c.join(''), v: r } );
				return { n: c.join(''), v: r };
			}
		};
		// console.log(  fnc._1( n, v ) );
		// let m: any = fnc[ k ]( n, v );
		let m: any = { n: 0, v: 0 };
		switch (k) {
			case '_1':
				m = fnc._1(n, v);
				break;
			case '_2':
				m = fnc._2(n, v);
				break;

			case '_3':
				m = fnc._3(n, v);
				break;
		}

		return this.lire(m.n, m.v);
	}


	transform(n: any, args?: any): string {

		// console.log("-------------------------------------");

		if (isNaN(Number(n))) {

			return '...';

		}

		let num = '' + n as string;

		let splitedN = num.split(".");

		// console.log("ssssssssssssssss ", splitedN);


		if (splitedN.length > 2) {

			return '...';

		} else if (splitedN.length == 2) {

			let separator = " Virgule ";
			let firstLetter = this.getLetterNumber(splitedN[0]);
			let secondLetter = this.getLetterNumber(splitedN[1]);

			return firstLetter + separator + secondLetter;

		} else {

			return this.getLetterNumber(num);

		}


		// return null;
	}


	getLetterNumber(n: any) {

		if (n.length < 4) {

			return this.lire(n, '');

		} else {

			let rgx = /(\d+)(\d{3})/;

			while (rgx.test(n)) {

				n = n.replace(rgx, '$1' + 't' + '$2');

			}

			let t = n.split('t');
			let r = [];

			// console.log("Number, ", n);
			// console.log("Len, ", t.length);

			// console.log( t );
			for (let i = 1; i <= t.length; i++) {

				// if (t.length - i == 1 && t[i - 1] == '1') { t[i - 1] = 0 }

				r.push(this.lire((t.length - i == 1 && t[i - 1] == '1') ? '0' : t[i - 1], ''));

				if (i < t.length && t[i - 1] * 1 != 0) {

					// console.log("suuuuuuuuuuuuuub ", this.sub[t.length - i]);

					// console.this.log(t);
					r.push(this.sub[t.length - i]);

				}

			}

			// console.log("RESULT ", r);

			// console.log("-------------------------------------");

			// console.log( r );
			return r.join(' ');
		}

	}

}

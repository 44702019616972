import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploads-pr',
  templateUrl: './uploads-pr.component.html',
  styleUrls: ['./uploads-pr.component.scss']
})
export class UploadsPrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

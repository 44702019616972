import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InscriptionOpeco } from '../models/inscription-opeco';
import { endpoints, base_url } from 'src/assets/api.json';
import { api_res } from 'src/app/shared/interfaces';
import { digitList } from 'src/app/configs/constants.json';
import { map } from 'rxjs/operators';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { secteur } from 'src/app/configs/storage_key.json';
import { secteur_prive } from 'src/app/configs/parametre.json';

@Injectable()
export class InscriptionOpecoService {
  isValidForm = new BehaviorSubject<Boolean>(false);
  data = new BehaviorSubject<InscriptionOpeco | null>(null);
	digitListArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	onlyNumber = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
		private sessionService: SessionService

  ) { }

  postOpeco(data: InscriptionOpeco) {
    return this.http.post<api_res>(`${base_url}${endpoints.signUpOpeco}`, data);
  }

  postConfirmOpeco(token: string) {
    return this.http.post<api_res>(`${base_url}${endpoints.confirmSignUpOpeco}`, { token });
  }

	AllowNumericDigitOnly(event?: KeyboardEvent, identityValue?: string) {

		const reg = /\D/;
		let regTest = reg.test(identityValue as string);
		// if (this.sessionService.getItem( secteur ) == secteur_prive && this.digitListArray.indexOf(event.key) < 0) {
		if (this.sessionService.getItem( secteur ) == secteur_prive && regTest === true) {

			this.onlyNumber.next(true);
			return;

		} else {

			this.onlyNumber.next(false);

		}

	}

}

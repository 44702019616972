import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { CnmService } from 'src/app/shared/services/cnm/cnm.service';
import { StatusService } from 'src/app/shared/services/status/status.service';
import { messages } from "src/app/configs/constants.json";
import { BehaviorSubject, Observable } from 'rxjs';
import { api_res } from '../../interfaces';
import { UtilsService } from '../../services/utils/utils.service';


@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {

  @Input() request: Observable<api_res>;
  @Input() label: string = "Télécharger";

  // true si on est entrain de télécharger et false après la requête
  public isDownloading = false;

  constructor(
    public apiService: ApiService,
    public cnmService: CnmService,
    public statusService: StatusService,
    public toastr: ToastrService,
    public utilsService: UtilsService
  ) { }


  ngOnInit(): void {
  }


  /**
 * 
 * @author R. Jean Christian
 * 
 * @description Démarre le téléchargement d'un fichier
 * 
 * 
 */
  donwloadStart() {

    this.isDownloading = true;

    if (!this.statusService.isConnectedToNetwork$.value) {

      this.toastr.warning(messages.actionNotAvailableMessage, messages.networkStatusMessages.offlineMessage);

    } else {

      this.request.subscribe(data => {

        if (data.status) {

          this.utilsService.downloadPdf(data.data, Date.now() + "");

        } else {

          this.toastr.error("ERREUR", "Erreur de téléchargement");

        }

        this.isDownloading = false;

      });

    }

  }

}

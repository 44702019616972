import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api_res } from 'src/app/shared/interfaces';
import { base_url, endpoints } from "src/assets/api.json";
import { RECOURS, RecoursFilter, RecoursCRR, TYPE_RECOURS } from '../../interfaces/recours';
import { I_DECISION, I_SIGNATURE } from '../../interfaces/decision';
import { DEMANDE_ELEMENT_ALL } from '../../interfaces/demande';
import { DEMANDE_ELEMENT, ELEMENT_DEMANDE } from 'src/app/shared/models/demande';
import { newListElementFlag } from "src/app/configs/constants.json";

import { ELEMENT } from '../../interfaces/element';

import { DEMANDE_INFO } from '../../interfaces/demande-info';
import { I_REPONSE_INFO_SUPP } from '../../interfaces/reponse-info-supp';

@Injectable()
export class RecoursApiService {

  constructor(public http: HttpClient) { }


	public getRecoursByDoc(code: any) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getRecoursByDoc}`,
		{

			params: {
				DOC_CODE: code
			}

		});
	}


	createRecours<T>(data: RECOURS) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.createRecours}`, {

				RECOURS_DOC_TYPE: data.RECOURS_DOC_TYPE,
				RECOURS_DOC_CODE_FK: data.RECOURS_DOC_CODE_FK,
				RECOURS_REFERENCE: data.RECOURS_REFERENCE,
				RECOURS_TYPE_MOTIF_FK: data.RECOURS_TYPE_MOTIF_FK,
				RECOURS_MOTIF: data.RECOURS_MOTIF,
				RECOURS_DEMANDE_CONCL: data.RECOURS_DEMANDE_CONCL,
				RECOURS_LIEU: data.RECOURS_LIEU,
				ETAT_CODE_FK: "NOUVEAU",

				//Ajout 14/06/21
				RECOURS_DATE_CREATION:data.RECOURS_DATE_CREATION,
				EXPOSE_FAITS: data.EXPOSE_FAITS,
				DATE_RECEP: data.DATE_RECEP,
				DATE_AFF: data.DATE_AFF,
				ACTE_DECISION_PRX: data.ACTE_DECISION_PRX,
				TYPE_PERSONNE_CODE_FK: data.TYPE_PERSONNE_CODE_FK

		});
	}


	createExpedierRecours<T>(data: RECOURS) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.createRecours}`, {

				RECOURS_DOC_TYPE: data.RECOURS_DOC_TYPE,
				RECOURS_DOC_CODE_FK: data.RECOURS_DOC_CODE_FK,
				RECOURS_REFERENCE: data.RECOURS_REFERENCE,
				RECOURS_TYPE_MOTIF_FK: data.RECOURS_TYPE_MOTIF_FK,
				RECOURS_MOTIF: data.RECOURS_MOTIF,
				RECOURS_DEMANDE_CONCL: data.RECOURS_DEMANDE_CONCL,
				RECOURS_LIEU: data.RECOURS_LIEU,
				ETAT_CODE_FK: "EXPEDIE",

				//Ajout 14/06/21
				RECOURS_DATE_CREATION: data.RECOURS_DATE_CREATION,
				EXPOSE_FAITS: data.EXPOSE_FAITS,
				DATE_RECEP: data.DATE_RECEP,
				DATE_AFF: data.DATE_AFF,
				ACTE_DECISION_PRX: data.ACTE_DECISION_PRX,
				TYPE_PERSONNE_CODE_FK: data.TYPE_PERSONNE_CODE_FK

		});
	}


	updateRecours<T>(data: RECOURS) {

		return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateRecoursByCode}`, {

				RECOURS_CODE : data.RECOURS_CODE,
				RECOURS_DOC_TYPE: data.RECOURS_DOC_TYPE,
				RECOURS_DOC_CODE_FK: data.RECOURS_DOC_CODE_FK,
				RECOURS_REFERENCE: data.RECOURS_REFERENCE,
				RECOURS_TYPE_MOTIF_FK: data.RECOURS_TYPE_MOTIF_FK,
				RECOURS_MOTIF: data.RECOURS_MOTIF,
				RECOURS_DEMANDE_CONCL: data.RECOURS_DEMANDE_CONCL,
				RECOURS_LIEU: data.RECOURS_LIEU,
				RECOURS_DATE_CREATION: data.RECOURS_DATE_CREATION,
				ETAT_CODE_FK: "NOUVEAU",

				//Ajout 14/06/21
				EXPOSE_FAITS: data.EXPOSE_FAITS,
				DATE_RECEP: data.DATE_RECEP,
				DATE_AFF: data.DATE_AFF,
				ACTE_DECISION_PRX: data.ACTE_DECISION_PRX,
				TYPE_PERSONNE_CODE_FK: data.TYPE_PERSONNE_CODE_FK

		});
	}


	updateExpedierRecours<T>(data: RECOURS) {

		return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateRecoursByCode}`, {

				RECOURS_CODE : data.RECOURS_CODE,
				RECOURS_DOC_TYPE: data.RECOURS_DOC_TYPE,
				RECOURS_DOC_CODE_FK: data.RECOURS_DOC_CODE_FK,
				RECOURS_REFERENCE: data.RECOURS_REFERENCE,
				RECOURS_TYPE_MOTIF_FK: data.RECOURS_TYPE_MOTIF_FK,
				RECOURS_MOTIF: data.RECOURS_MOTIF,
				RECOURS_DEMANDE_CONCL: data.RECOURS_DEMANDE_CONCL,
				RECOURS_LIEU: data.RECOURS_LIEU,
				RECOURS_DATE_CREATION: data.RECOURS_DATE_CREATION,
				ETAT_CODE_FK: "EXPEDIE",

				//Ajout 14/06/21
				EXPOSE_FAITS: data.EXPOSE_FAITS,
				DATE_RECEP: data.DATE_RECEP,
				DATE_AFF: data.DATE_AFF,
				ACTE_DECISION_PRX: data.ACTE_DECISION_PRX,
				TYPE_PERSONNE_CODE_FK: data.TYPE_PERSONNE_CODE_FK
		});
	}


	public getAllMotif() {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getAllMotif}`);

	}


	public getAllRecours() {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getAllRecours}`);

	}


	public getRecoursByUser<T>(params: RecoursFilter) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getRecoursByUser}`, {
			params: {
				USER_CODE:params.USER_CODE ? params.USER_CODE : "",
				USER_TYPE:params.USER_TYPE ? params.USER_TYPE : "",
				RECOURS_DOC_TYPE:params.RECOURS_DOC_TYPE ? params.RECOURS_DOC_TYPE :"",
				ETAT_CODE_FK:params.STATUS ? params.STATUS : "",
				STATUS:params.STATUS ? params.STATUS : "",
				LIEU:params.LIEU ? params.LIEU : "",
				TYPE_MOTIF:params.TYPE_MOTIF ? params.TYPE_MOTIF : "",
				LIMIT: params.LIMIT ? params.LIMIT : 0,
				PAGE: params.PAGE ? params.PAGE : 0,
				RECOURS_CRR: params.RECOURS_CRR ? params.RECOURS_CRR : "",
				DEMANDE: params.DEMANDE ? params.DEMANDE : "",
				DOC_TYPE:params.DOC_TYPE ? params.DOC_TYPE : "",
				RECOURS_TYPE_MOTIF_FK:params.RECOURS_TYPE_MOTIF_FK ? params.RECOURS_TYPE_MOTIF_FK : "",
				ENTITE: params.ENTITE ? params.ENTITE : "",
				DIRECTION: params.DIRECTION ? params.DIRECTION : "",
				PRMP: params.PRMP ? params.PRMP : ""
			}
		});

	}


	public getRecoursByDocAndUser(userCode:string,docCode:string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getRecoursByDocAndUser}`, {
			params: {
				USER_CODE: userCode,
				DOC_CODE: docCode,
			}
		});

	}


	public getRecoursInitByDocAndUser(userCode:string,docCode:string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getRecoursInitByDocAndUser}`, {
			params: {
				USER_CODE: userCode,
				DOC_CODE: docCode,
			}
		});

	}


	public getOpecoByCode<T>(code:string) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getOpecoByCode}`, {
			params: {
				OPER_CODE:code
			}
		});

	}


	public getUserByCode<T>(code:string) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getUserByCode}`, {
			params: {
				USER_CODE:code
			}
		});

	}


	public createTraitementRecours<T>(code:any) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.createTraitementRecours}`, {

				RECOURS_CODE:code

		});
	}


	// TODOOOOO
	// updateRecoursStatus<T>(data: RECOURS) {

	// 	return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateRecoursStatus}`, {

	// 			RECOURS_CODE : data.RECOURS_CODE,
	// 			RECOURS_DOC_TYPE: data.RECOURS_DOC_TYPE,
	// 			RECOURS_DOC_CODE_FK: data.RECOURS_DOC_CODE_FK,
	// 			ETAT_CODE_FK: "NOUVEAU",
	// 	});
	// }

	public getTRByRecoursAndUser<T>(code:string, crrCode: any) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getTRByRecoursAndUser}`, {
			params: {
				RECOURS_CODE:code,
				CRR_CODE:crrCode
			}
		});

	}


	/**
	 *
	 * @author R.J. Christian
	 *
	 * getTRByDocumentAndUser
	 */
	public getTRByDocumentAndUser(docCOde: string, userCode: string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getTRByDossierAndUser}`, {
			params: {
				RECOURS_USER_CODE_FK: userCode,
				RECOURS_DOC_CODE_FK: docCOde
			}
		});

	}


	public getTRByRecours<T>(code:any) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getTRByRecours}`, {
			params: {
				RECOURS_CODE:code,
			}
		});

	}


	updateRecoursStatut<T>(
		recoursCode: any,
		docType: string,
		docCode: any,
		status: string,
		motifNonRecev?: string) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.updateRecoursStatus}`, {

			RECOURS_CODE: recoursCode,
			RECOURS_STATUS: status,
			DOC_TYPE: docType,
			DOC_CODE: docCode,
			MOTIF_NON_RECEVABILITE: motifNonRecev ? motifNonRecev : ''


		});
	}


	public getRecoursByCRR<T>(params: RecoursFilter) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getRecoursByCRR}`, {
			params: {
				TRAITEMENT_CRR:params.TRAITEMENT_CRR ? params.TRAITEMENT_CRR : 0,
				RECOURS_CRR:params.RECOURS_CRR ? params.RECOURS_CRR : 0,
				PAGE :params.PAGE ? params.PAGE : 0,
				LIMIT :params.LIMIT ? params.LIMIT : 0,
				ETAT_CODE_FK: params.STATUS ? params.STATUS : "",
				DEMANDE: params.DEMANDE ? params.DEMANDE : 0,
				RECOURS_TYPE_MOTIF_FK: params.RECOURS_TYPE_MOTIF_FK ? params.RECOURS_TYPE_MOTIF_FK : '',
				RECOURS_DOC_TYPE: params.RECOURS_DOC_TYPE ? params.RECOURS_DOC_TYPE : ''
			}
		});

	}
	public getRecoursByPRMP<T>(params: RecoursFilter) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getRecoursByPRMP}`, {
			params: {
				PAGE :params.PAGE ? params.PAGE : 0,
				LIMIT :params.LIMIT ? params.LIMIT : 0,
				RECOURS_TYPE_MOTIF_FK: params.RECOURS_TYPE_MOTIF_FK ? params.RECOURS_TYPE_MOTIF_FK : "",
				RECOURS_DOC_TYPE: params.RECOURS_DOC_TYPE ? params.RECOURS_DOC_TYPE : ""
			}
		});

	}

	public insertDecisionOrUpdateDecision<T>(decision: I_DECISION, signature: I_SIGNATURE[]) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.insertDecision}`, {


			DECISION: {

				DEC_SREC_CODE: (decision.DEC_SREC_CODE == '' || decision.DEC_SREC_CODE == null || decision.DEC_SREC_CODE == undefined) ? null : decision.DEC_SREC_CODE,
				// DEC_SREC_CODE: (decision.DEC_SREC_CODE !== '' && decision.DEC_SREC_CODE !== null && decision.DEC_SREC_CODE!== undefined) ? decision.DEC_SREC_CODE : null,
				DEC_SREC_NUMERO: decision.DEC_SREC_NUMERO,
				DEC_SREC_ARTICLE: decision.DEC_SREC_ARTICLE,
				DEC_SREC_LIBELLE: decision.DEC_SREC_LIBELLE,
				DEC_SREC_DECISION_CODE_FK: decision.DEC_SREC_DECISION_CODE_FK,
				// CRR_CODE_FK: data.CRR_CODE_FK,
				RECOURS_CODE_FK: decision.RECOURS_CODE_FK,
				RECOURS_DOC_STATUS: decision.RECOURS_DOC_STATUS,
				DECISION_DOC_STATUS: decision.RECOURS_DOC_STATUS

			},
			SIGNATURE: signature.map(

				el => {

					return {

						...el,
						DECISION_SIGNATURE_CODE: el.DECISION_SIGNATURE_CODE?.match(newListElementFlag) != null ? null : el.DECISION_SIGNATURE_CODE

					}

				}

			)

		});

	}


	//NOUVEAU
	public insertOrUpdateDMDInfo<T>(data:DEMANDE_INFO) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.insertOrUpdateDMDInfo}`, {

			DMD_INFO_CODE: data.DMD_INFO_CODE ? data.DMD_INFO_CODE : null,
			DMD_INFO_LIBELLE: data.DMD_INFO_LIBELLE,
			ETAT_CODE_FK: data.ETAT_CODE_FK,
			TR_CODE_FK: data.TR_CODE_FK,
			DMD_INFO_REFERENCE: data.DMD_INFO_REFERENCE

		});

	}

	public insertOrUpdateReponseInfo<T>(data:I_REPONSE_INFO_SUPP) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.insertOrUpdateReponseInfo}`, {

			REP_CODE: data.REP_CODE ? data.REP_CODE : null,
     	REP_TYPE: data.REP_TYPE,
      REP_LIBELLE:data.REP_LIBELLE,
      REP_DMD_INFO_CODE_FK:data.REP_DMD_INFO_CODE_FK,

		});

	}


	public getReponseByDmd<T>(code:any) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getReponseInfoByDmd}`, {
			params: {
				REP_DMD_INFO_CODE_FK: code,
			}
		});

	}

	// public updateDMDInfo<T>(data:DEMANDE_INFO) {

	// 	return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateDMDInfo}`, {

	// 		DMD_INFO_CODE: data.DMD_INFO_CODE,
	// 		DMD_INFO_LIBELLE: data.DMD_INFO_LIBELLE,
	// 		ETAT_CODE_FK:data.ETAT_CODE_FK,
	// 		TR_CODE_FK:data.TR_CODE_FK,

	// 	});

	// }

	updateStatusReponseInfo<T>(dmdCode:string, status:string) {

		return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateStatusReponseInfo}`, {

				DMD_INFO_CODE: dmdCode,
				ETAT_CODE_FK: status

		});
	}


	public updateStatusDMDInfo<T>(status: string, dmdInfoCode:string,) {

		return this.http.put<api_res | T>(`${base_url}${endpoints.recours.updateStatusDMDInfo}`, {

			DMD_INFO_CODE:dmdInfoCode,
			ETAT_CODE_FK:status

		});

	}



	// public getDecision<T>(crrCode: any, recoursCode:any) {
	public getDecision<T>(recoursCode:any) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getDecision}`, {
			params: {
				// CRR_CODE:crrCode,
				RECOURS_CODE: recoursCode
			}
		});

	}


	downloadRecours<T>(lotCode: any, userCode: any, docType: TYPE_RECOURS) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.exportRecoursPDF}`, {
			DOC_CODE: lotCode,
			OPER_CODE: userCode,
			DOC_TYPE: docType
		});

	}


	public getDemandeByTraitement<T>(traitementCode: string) {
		return this.http.get<api_res | DEMANDE_ELEMENT_ALL[] | T>(`${base_url}${endpoints.recours.getDemandeByTraitement}`, {params: {TR_CODE: traitementCode}});
	}


	public getDemandeInfoByTraitement<T>(traitementCode: string) {
		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getDemandeInfoByTraitement}`,  {params: {TR_CODE_FK: traitementCode}});
	}


	/**
	 * insertOrUpdateDemandeElement
	 */
	public insertOrUpdateDemandeElement(demande: DEMANDE_ELEMENT, elements: ELEMENT_DEMANDE[], PRMPCode: string, recoursCode: string, traitementRecoursCode: string ) {

		// console.log("DEMAAAAAAAAAAAAAANDE", demande);

		return this.http.post<api_res >(`${base_url}${endpoints.recours.insertOrUpdateDemandeElement}`, {

			DEMANDE: {
				DMD_ELEM_CODE: demande.DMD_ELEM_CODE,
				DMD_ELEM_REFERENCE: demande.DMD_ELEM_REFERENCE,
				DMD_ELEM_INTRO: demande.DMD_ELEM_INTRO,
				DMD_ELEM_CONCL: demande.DMD_ELEM_CONCL,
				TR_CODE_FK: traitementRecoursCode,
				ETAT_CODE_FK: demande.ETAT_DEMANDE
			},
			ELEMENTS: elements.map(
				el => {

					return {
						...el,
						ELEM_REC_CODE: el.ELEM_REC_CODE.match(newListElementFlag) != null ? null: el.ELEM_REC_CODE,
						// ETAT_CODE_FK: "NOUVEAU",
						ELEM_REC_PRMP_CODE_FK: PRMPCode,
						ELEM_REC_RECOURS_CODE_FK: recoursCode,
					};

				}
			)

		});

	}


	public getElementByDemande(demandeCode: string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getElementByDemande}`, {params: {DMD_ELEM_CODE_FK: demandeCode}});

	}


	public isFileElementExist<T>(params: {recoursCode: string, elementCode: string}) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.isFileElementExist}`, {
			params: {
				RECOURS_CODE: params.recoursCode,
				ELEM_REC_CODE: params.elementCode
			}
		});

	}


	public deleteElement(params: {recoursCode: any, elementCode: string}) {

		return this.http.post<api_res>(`${base_url}${endpoints.recours.deleteElement}`, {
			RECOURS_CODE: params.recoursCode,
			ELEM_REC_CODE: params.elementCode
			});

	}


	public uploadElement(params: {recoursCode: string, elementCode: string, elementLibelle: string, file: any}) {

		return this.http.post<api_res>(`${base_url}${endpoints.recours.uploadElement}`, {
			RECOURS_CODE: params.recoursCode,
			ELEM_REC_CODE: params.elementCode,
			ELEM_REC_DMD_LIBELLE: params.elementLibelle,
			FILE: params.file
		});

	}


	updateDemandeElement(demandeElement: DEMANDE_ELEMENT){

		return this.http.put<api_res >(`${base_url}${endpoints.recours.updateDemandeElement}`, {
			DMD_ELEM_CODE: demandeElement.DMD_ELEM_CODE,
			DMD_ELEM_REFERENCE: demandeElement.DMD_ELEM_REFERENCE,
			DMD_ELEM_INTRO: demandeElement.DMD_ELEM_INTRO,
			DMD_ELEM_CONCL: demandeElement.DMD_ELEM_CONCL,
			ETAT_CODE_FK: demandeElement.ETAT_DEMANDE
		});

	}


	updateStatusDMDElement(demandeCode: string, status: string) {

		return this.http.put<api_res>(`${base_url}${endpoints.recours.updateStatusDemandeElement}`, {
			DMD_ELEM_CODE: demandeCode,
			ETAT_CODE_FK: status
		});

	}


	getDemandeAsPdf(demandeCode:string){

		// return this.http.get<api_res>(`${base_url}${endpoints.recours.getElementByDemande}`, {params: {DMD_ELEM_CODE: demandeCode}});
		return this.http.post<api_res>(`${base_url}${endpoints.recours.exportDemande}`, { DMD_ELEM_CODE: demandeCode });

	}


	getElementPdf(recoursCode: string, elementCode: string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getElementPdf}`, {params: {RECOURS_CODE:  recoursCode, ELEM_REC_CODE: elementCode}});

	}


	public verifierRecoursEnCours<T>(recoursCode: any, docCode:string) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.verifierRecoursEnCours}`,
		{

			params: {
				RECOURS_CODE: recoursCode,
				RECOURS_DOC_CODE_FK: docCode
			}

		});

	}


	getDemandeElementByCode(demandeCode: string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getDemandeElementByCode}`, {params: {DMD_ELEM_CODE: demandeCode}});

	}


	getALlDecisions() {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getAllDemande}`);

	}

	getRecoursSusDefByDoc(documentCode: string) {
		return this.http.get<api_res>(`${base_url}${endpoints.recours.getRecoursSusDefByDoc}`, { params: {RECOURS_DOC_CODE_FK: documentCode} });
	}


	getRecoursOfUserOnDoc(documentCode: string){

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getRecoursOfUserOnDoc}`, { params: {RECOURS_DOC_CODE: documentCode} });

	}

	getAllTypePersonne() {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getAllTypePersonne}`);

	}


	getdocStatus() {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getDocStatusForDecision}`);

	}


	getSignatureByDecision(decisionCode: string) {

		return this.http.get<api_res>(`${base_url}${endpoints.recours.getSignatureByDecision}`, { params: {'DEC_SREC_CODE_FK': decisionCode} });

	}


	exportDecision<T>(decisionCode: any) {

		return this.http.post<api_res | T>(`${base_url}${endpoints.recours.exportDecisionSrec}`, {

			DECISION_CODE: decisionCode

		 });

	}


	getRecoursSusSoul<T>(docCode: string) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.getRecoursSusSoul}`, { params: {'RECOURS_DOC_CODE_FK': docCode} });

	}


	verifyRecoursForSusSoul<T>(docCode: string) {

		return this.http.get<api_res | T>(`${base_url}${endpoints.recours.verifyRecoursForSusSoul}`, { params: {'RECOURS_DOC_CODE_FK': docCode} });

	}

}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalDeterminationBesoinComponent } from 'src/app/modules/e-planification/features/determination/modal-determination-besoin/modal-determination-besoin.component';
import { api_res } from '../../interfaces';
import { StatusService } from '../../services/status/status.service';
import { UtilsService } from '../../services/utils/utils.service';


@Component({
  selector: 'app-prev-pdf',
  templateUrl: './prev-pdf.component.html',
  styleUrls: ['./prev-pdf.component.scss']
})
export class PrevPdfComponent implements OnInit {

  //inputs
  @Input() request: Observable<api_res>;
  @Input() userBackdropedSpinnerLoader: boolean = false;


  //outputs

  /**
   * @description Evènement emis quand la récupération du pdf est fini
   *
   * True si succès et false si erreur
   *
  */
  @Output() pdfLoaded = new EventEmitter<boolean>();

  @Output() closeClicked = new EventEmitter<boolean>();

  pdfFile$ = new BehaviorSubject<Uint8Array | undefined>(undefined);

  loadingPdfFile = false;

	isError = false;

	fileNotExist = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: {
      request: Observable<api_res>,
      userBackdropedSpinnerLoader: boolean,
			fileName?: string
    },
    private dialogRef: MatDialogRef<ModalDeterminationBesoinComponent>,
    private spinner: NgxSpinnerService,
    public statusService: StatusService,
		public tostr: ToastrService,
		public utilsService: UtilsService
  ) {

  }


	ngOnInit(): void {

		if (this.userBackdropedSpinnerLoader || this.modalData.userBackdropedSpinnerLoader) {

      this.spinner.show();

    } else {

      this.loadingPdfFile = true;

    }

    if (this.modalData) {

      this.modalData.request.subscribe(
        (data) => {

					if (data.status){

						if(data.data){

							let doc_pdf = this.getPDFArrayBufferFromBase64(data.data);

							this.pdfFile$.next(doc_pdf);

							this.fileNotExist = false;

						}else{

							this.fileNotExist = true;

						}

					}else{

						this.isError = true;

					}

          this.loadingPdfFile = false;
          this.spinner.hide();

          this.pdfLoaded.emit(true);

        }
      );

    } else {

      this.request.subscribe(
        (data) => {

          let doc_pdf = this.getPDFArrayBufferFromBase64(data.data);

          this.pdfFile$.next(doc_pdf);

          this.loadingPdfFile = false;
          this.spinner.hide();

          this.pdfLoaded.emit(true);

        }
      );

    }

  }


  getPDFArrayBufferFromBase64(base64: string) {

    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }


  close() {

    this.dialogRef.close();

    this.closeClicked.emit();

  }


	downloadFile(){

		if (this.fileNotExist){

			this.tostr.warning("TELECHARGEMENT IMPOSSIBLE", "Aucun fichier n'a été trouvé.");
			return;

		}

		this.utilsService.downloadFile(this.pdfFile$.value, this.modalData.fileName );

	}
}

<div class="height">

	<pdf-viewer class="size-form-modal" 
		*ngIf="pdf$.value!=undefined" 
		[show-all]="true" 
		[stick-to-page]="true" 
		[src]="pdf$.value"
		[render-text]="true">
  	</pdf-viewer>

</div>


<div class="card-action">
	<button class="btn btn-cancel-form" (click)="closeDialog()">Fermer</button>
	<button type="button" (click)="OnDownLoadPDF()" class="btn btn-send-form">Télécharger</button>
</div>

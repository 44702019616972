import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { T_MODULE, T_OPEN_MODE_PV } from '../../models/types';


@Component({
  selector: 'app-proces-verbale',
  templateUrl: './proces-verbale.component.html',
  styleUrls: ['./proces-verbale.component.scss']
})
export class ProcesVerbaleComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public modalData: {
    module: T_MODULE,
    openMode: T_OPEN_MODE_PV
  }) { }


  ngOnInit() {
  }

}

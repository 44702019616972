import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, CanDeactivate, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { UserService } from '../user/user.service';
import { map, catchError, retry, filter, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { hide_browser_route } from "src/app/configs/app.json";
import { SessionService } from '../session/session.service';



@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

	public constructor(
		public apiService: ApiService,
		public userService: UserService,
		public router: Router,
		public sessionService: SessionService) {

	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let permission = this.checkPermission();

		if (!permission) {

			this.router.navigateByUrl("/login" + this.sessionService.currentPortail$.value.link, {
				skipLocationChange: hide_browser_route
			});

			return false;

		}

		return permission;

	}

	canLoad(
		route: Route,
		segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let permission = this.checkPermission();

		if (permission == false) {

			this.router.navigateByUrl("/login" + this.sessionService.currentPortail$.value.link, {
				skipLocationChange: hide_browser_route
			});

			return false;

		} else if (permission == true) {

			return true;

		}

		return permission;

	}


	/**
	 *
	 * Check if permission is allowed
	 *
	 */
	public checkPermission(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (this.userService.getLocalToken() == null) {

			return false;

		}

		/* console.log("Maka données utilisateur connecté any aminy Guard..."); */

		let user_ = this.userService.getConnectedUserB();

		if (user_ instanceof Observable) {

			/* console.log("Maka données utilisateur any aminy API ny guard..."); */

			return user_.pipe(

				switchMap(data => {

					/* console.log("Nahazo données utilisateur tany aminy API ny guard..."); */

					if (data.status == false) {

						return new Observable<boolean | UrlTree>((observer) => {

							observer.next(
								this.router.createUrlTree(["/login", this.sessionService.currentPortail$.value.link])
							);

						});

					} else {

						this.userService.connectedUserB$.next(data.data.user);

						this.userService.connectionStusEvent$.next({ isConnected: true });

						return new Observable<boolean>((observer) => {

							observer.next(true);

						});

					}

				}),
				catchError(this.ReqErrorHanler.bind(this))

			);

		} else {

			/* console.log("Efa nahita données utilisateur ny guard..."); */

			return true;

		}

	}


	private ReqErrorHanler(error: HttpErrorResponse) {

		// if (error.error instanceof ErrorEvent) {

		// 	// TODO: add event message to show erros to users

		// 	// A client-side or network error occurred. Handle it accordingly.
		// 	console.error('An error occurred:', error.error.message);

		// } else {

		// 	// The backend returned an unsuccessful response code.
		// 	// The response body may contain clues as to what went wrong.
		// 	console.error(
		// 		`Backend returned code ${error.status}, `);

		/* console.log("Nisy erreur ny fakany guard données avy any aminy API..."); */


		if (error.status == 401) {
			alert("Vous n'êtes pas autorisé à accéder à cette page, vous allez être redirigé");
		}

		return new Observable<boolean | UrlTree>((observer) => {

			if (this.userService.connectionStusEvent$.value.isConnected && error.status != 401) {

				return observer.next(true);

			}
			return observer.next(this.router.createUrlTree(["/login" + this.sessionService.currentPortail$.value.link]));

		});

	}

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
	NgxMatAlertConfirmModule,
	NgxMatAlertConfirmService,
} from 'ngx-mat-alert-confirm';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { MainFooterComponent } from './layouts/main-layout/main-footer/main-footer.component';
import { MainHeaderComponent } from './layouts/main-layout/main-header/main-header.component';
import { AuthentificationModule } from './modules/authentification/authentification.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { InscriptionLayoutComponent } from './layouts/inscription-layout/inscription-layout.component';
import { UserService } from './shared/services/user/user.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EDossierModule } from './modules/e-dossier/e-dossier.module';
import { EssentialsDataLoaderResolver } from './shared/resolvers/essentialsDataLoader/essentials-data-loader.resolver';
import { DataService } from './shared/services/data/Data.service';
import { MatBreadcrumbModule } from 'mat-breadcrumb';

registerLocaleData(localeFr, 'fr');


@NgModule({
	declarations: [
		AppComponent,
		AuthLayoutComponent,
		MainLayoutComponent,
		MainFooterComponent,
		MainHeaderComponent,
		InscriptionLayoutComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AuthentificationModule,
		NgbModule,
		SharedModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			timeOut: 6000,
			positionClass: 'toast-top-right',
		}),
		NgIdleKeepaliveModule.forRoot(),
		NgxSpinnerModule,
		NgxMatAlertConfirmModule,
		MatFormFieldModule,
		MatBreadcrumbModule
		// EDossierModule
	],
	providers: [
		NgxMatAlertConfirmService,
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		EssentialsDataLoaderResolver,
		DataService
	],

	bootstrap: [AppComponent]

})
export class AppModule {

	constructor(public userService: UserService) {
	}

}

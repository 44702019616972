import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I_CustomService } from '../../interfaces/core_customisation';
import { Etat } from '../../interfaces/etat';
import { I_Entite } from '../../models/entite';
import { I_Monnaie } from '../../models/monnaie';
import { I_RequestResData } from '../../models/request';
import { I_TypeMarche } from '../../models/typeMarche';
import { etat } from "../../../configs/constants.json";

@Injectable()
export class DataService implements I_CustomService {

	/**
	 * Liste des entités
	 */
	listEntite$ = new BehaviorSubject<I_Entite[]>([]);

	/**
	 * Liste de type de marché
	 */
	listTypeMarche$ = new BehaviorSubject<I_TypeMarche[]>([]);

	/**
	 * Liste de type de monaie/devise
	 */
	listMonaie$ = new BehaviorSubject<I_Monnaie[]>([]);


	 /**
	 * Liste des états/statut de document et process
	 */
	listEtat$ = new BehaviorSubject<Etat[]>([]);


	constructor() { }


	hydrate(data: I_RequestResData){

		if (data.listEntite) {

			this.listEntite$.next(data.listEntite);

		}

		if(data.listMonaie){

			this.listMonaie$.next(data.listMonaie);

		}


		if (data.listTypeMarche){

			this.listTypeMarche$.next(data.listTypeMarche);

		}


		if(data.listEtat){

			this.listEtat$.next(data.listEtat);

		}

	}


	getEtatByCode(code: String | null){

		return code == null ? this.listEtat$.value.find( data => data.ETAT_CODE == etat.NOUVEAU) : this.listEtat$.value.find( data => data.ETAT_CODE == code);

	}


	getMonaieByCode(code: String){

		return this.listMonaie$.value.find( data => data.MONNAIE_CODE == code);

	}

}

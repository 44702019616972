import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from './converter';

@JsonObject("DroitGroupe")
export class DroitGroupe {

	@JsonProperty("GRP_CODE", String)
	GRP_CODE?: String = undefined;

	@JsonProperty("GRP_LIBELLE", String)
	GRP_LIBELLE?: String = undefined;

	@JsonProperty("GRP_ETAT_CODE", String)
	GRP_ETAT_CODE?: String = undefined;

	@JsonProperty("GRP_CREATION_DATE", DateConverter)
	GRP_CREATION_DATE?: DateConverter = undefined;

	@JsonProperty("GRP_CREATION_USER", String)
	GRP_CREATION_USER?: String = undefined;

	@JsonProperty("MENU_ID_PARENT", String)
	MENU_ID_PARENT?: Number = undefined;

	@JsonProperty("MENU_ID_TITRE", String)
	MENU_ID_TITRE?: Number = undefined;

	@JsonProperty("MENU_ID_ENFANT", String)
	MENU_ID_ENFANT?: Number = undefined;

	@JsonProperty("MENU_PARENT", String)
	MENU_PARENT?: String = undefined;

	@JsonProperty("MENU_TITRE", String)
	MENU_TITRE?: String = undefined;

	@JsonProperty("MENU_ENFANT", String)
	MENU_ENFANT?: String = undefined;

	@JsonProperty("MENU_ROUTE", String)
	MENU_ROUTE?: String = undefined;

	@JsonProperty("MENU_RANG", String)
	MENU_RANG?: Number = undefined;

	@JsonProperty("MENU_CREATION_DATE", DateConverter)
	MENU_CREATION_DATE?: DateConverter = undefined;

	@JsonProperty("MENU_CREATION_USER", String)
	MENU_CREATION_USER?: String = undefined;

	@JsonProperty("DRT_CONSULTATION", String)
	DRT_CONSULTATION?: String = undefined;

	@JsonProperty("DRT_SUPPRESSION", String)
	DRT_SUPPRESSION?: String = undefined;

	@JsonProperty("DRT_CREATION", String)
	DRT_CREATION?: String = undefined;

	@JsonProperty("DRT_MODIFICATION", String)
	DRT_MODIFICATION?: String = undefined;

	@JsonProperty("DRT_EXPEDITION", String)
	DRT_EXPEDITION?: String = undefined;

	@JsonProperty("DRT_VALIDATION", String)
	DRT_VALIDATION?: String = undefined;

	@JsonProperty("DRT_AUTRE", String)
	DRT_AUTRE?: String = undefined;

	@JsonProperty("DRT_VIEW", String)
	DRT_VIEW?: String = undefined;

	@JsonProperty("DRT_CREATION_DATE", DateConverter)
	DRT_CREATION_DATE?: DateConverter = undefined;

	@JsonProperty("DRT_CREATION_USER", String)
	DRT_CREATION_USER?: String = undefined;

	@JsonProperty("DRT_MENU_ID", String)
	DRT_MENU_ID?: Number = undefined;

	@JsonProperty("DRT_GRP_CODE", String)
	DRT_GRP_CODE?: String = undefined;

	@JsonProperty("ETAT", String)
	ETAT?: String = undefined;

	constructor() { }
}



export class I_DroitGroupe {

	GRP_CODE?: String;

	GRP_LIBELLE?: String;

	GRP_ETAT_CODE?: String;

	GRP_CREATION_DATE?: DateConverter;

	GRP_CREATION_USER?: String;

	MENU_ID_PARENT?: Number;

	MENU_ID_TITRE?: Number;

	MENU_ID_ENFANT?: Number;

	MENU_PARENT?: String;

	MENU_TITRE?: String;

	MENU_ENFANT?: String;

	MENU_ROUTE?: String;

	MENU_RANG?: Number;

	MENU_CREATION_DATE?: DateConverter;

	MENU_CREATION_USER?: String;

	DRT_CONSULTATION?: String;

	DRT_SUPPRESSION?: String;

	DRT_CREATION?: String;

	DRT_MODIFICATION?: String;

	DRT_EXPEDITION?: String;

	DRT_VALIDATION?: String;

	DRT_AUTRE?: String;

	DRT_VIEW?: String;

	DRT_CREATION_DATE?: DateConverter;

	DRT_CREATION_USER?: String;

	DRT_MENU_ID?: Number;

	DRT_GRP_CODE?: String;

	ETAT?: String;

}

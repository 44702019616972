<div class="login">
	<form [formGroup]="login_form" (ngSubmit)="submit_login()">
		<div class="mb-3">
			<label for="login" class="form-label">Identifiant</label>
			<!-- <input type="email" class="form-control" id="login" placeholder="Identifiant" [(ngModel)]="this.inscriptionOpecoService.identityValue" formControlName="identity" (keyup)="this.inscriptionOpecoService.AllowNumericDigitOnly($event)"> -->
			<input type="email" class="form-control" id="login" placeholder="Identifiant" formControlName="identity" (keyup)="this.inscriptionOpecoService.AllowNumericDigitOnly($event, identityValue)">
			<!-- TODO: asina style le texte (text_style_integration) -->
			<div *ngIf="loginFormControl.identity.touched && loginFormControl.identity.errors?.required" class="text-danger">
				L'identifiant est requis</div>
			<div *ngIf="loginFormControl.identity.touched && this.inscriptionOpecoService.onlyNumber.value" class="text-danger">
				Caractère numérique seulement</div>
		</div>
		<div class="mb-3">
			<label for="password" class="form-label">Mot de passe</label>
			<input type="password" class="form-control" id="password" placeholder="Mot de passe" formControlName="password">
			<!-- TODO: asina style le texte (text_style_integration) -->
			<div *ngIf="loginFormControl.password.touched && loginFormControl.password.errors?.required" class="text-danger">
				Veillez entrer votre mot de passe</div>
		</div>
		<div class="mb-3 form-check">
			<a class="form-check-label" [routerLink]="['/forgot-password']" [skipLocationChange]="hide_browser_route">
				Login ou mot de passe oublié?
			</a>
		</div>
		<button type="submit" class="btn btn-send" [ngClass]="{
			'event-none': login_form.invalid
		}">Connecter</button>
	</form>
	<hr>
	<a [routerLink]="[user_secteur === secteur_prive ? '/opeco-inscription' : '/inscription']" class="btn-inscription">S’inscrire</a>
</div>

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { I_Opeco } from 'src/app/modules/e-contrat/interfaces/opeco';
import { api_res } from 'src/app/shared/interfaces';

import { StatusService } from 'src/app/shared/services/status/status.service';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { I_Motif } from '../../interfaces/motif';
import { RECOURS, RecoursFilter, TYPE_RECOURS } from '../../interfaces/recours';
import { LoadingFilterRecours, LoadingRecours } from '../../interfaces/status';
import { RecoursApiService } from '../recours-api/recours-api.service';
import { I_Demande } from '../../../../models/e-planification/demande.interface';
import { DEMANDE_ELEMENT_ALL } from '../../interfaces/demande';
import { I_SingleRecours } from '../../interfaces/preloadData';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { etat, boutonLibelle, messages, GRP_CODE_SUPER_ADMIN } from 'src/app/configs/constants.json';
import { FormGroup } from '@angular/forms';
import { TypePersonne } from '../../interfaces/type-personne';
import { ActivatedRoute, Router } from '@angular/router';
import { hide_browser_route } from "src/app/configs/app.json";
import { RecoursAttributionComponent } from '../../pages/recours-attribution/recours-attribution.component';
import { Etat } from 'src/app/shared/interfaces/etat';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DecisionService } from '../decision/decision.service';


declare var reinitEvents: Function;

declare var isInputDisbled: (controllName: string) => boolean;

@Injectable()
export class RecoursService {

	public loadingRecours$ = new BehaviorSubject<LoadingRecours>({

		userLoaded: false,
		recoursLoaded: false,
		motifLoaded :false,
		demandeurLoaded:false

	});

	public params$ = new BehaviorSubject<any|null>({
		docType: "",
		docCode: "",
		userCode: ""
	})

	public openedRecours$ = new BehaviorSubject<RECOURS | undefined>(undefined);

	public isModif$ = new BehaviorSubject<string | null>(null);

	public listeMotif$: BehaviorSubject<I_Motif[] | undefined> = new BehaviorSubject<I_Motif[]| undefined>(undefined);

	public filtreRecours$: BehaviorSubject<RecoursFilter | undefined> = new BehaviorSubject<RecoursFilter| undefined>(undefined);

	public listeRecours$ =  new BehaviorSubject<RECOURS[] | undefined>(undefined);

	public loadingListeRecours$ = new BehaviorSubject<Boolean|undefined>(false);

	public isCreate$ = new BehaviorSubject<Boolean|undefined>(false);

	public filterRecoursData$ = new BehaviorSubject<LoadingFilterRecours|undefined>(undefined);

	public opecoInfo$ = new BehaviorSubject<any|undefined>(undefined);

	public demandeurInfo$ = new BehaviorSubject<any|undefined>(undefined);

	public isRecoursSusDef$ = new BehaviorSubject<boolean|undefined>(true);

	// Bouton enregistrement et modification
	public boutonLibelle$ = new BehaviorSubject<string | undefined>('Enregistrer');

	public docCode$ = new BehaviorSubject<string | undefined>(undefined);

	public activatedForm$ = new BehaviorSubject<FormGroup | undefined>(undefined);

	public listeTypePersonne$ = new BehaviorSubject<TypePersonne | undefined>(undefined);

	public formSpecificValidators: (() => {error: boolean, description: string})[] = [];

	public etat$ = new BehaviorSubject<string | undefined>(undefined);

	public decisionBouton$ = new BehaviorSubject<string | undefined>(boutonLibelle.decision);

	public docStatus$ = new BehaviorSubject<Etat[] | undefined>(undefined);




	// Pagination
	public page$ = new BehaviorSubject<number>(1);
	public limit$ = new BehaviorSubject<number>(5);
	public total_rows$ = new BehaviorSubject<number>(10);

  constructor(
		public statusService: StatusService,
		public toastr: ToastrService,
		private spinner: NgxSpinnerService,
		private toolsService: AppToolsService,
		public apiService: RecoursApiService,
		public utilsService: UtilsService,
		public router: Router,
		public route: ActivatedRoute,
		public userService: UserService,
		// public decisionService: DecisionService
		) {

	}


	hydrate(data: I_SingleRecours) {

		if (data.demandeur){
			this.demandeurInfo$.next(data.demandeur);
		}

		if (data.motif){
			this.listeMotif$.next(data.motif);
		}

		if (data.recours){
			// console.log("HYDRATATION DE RECOURS ", data.recours);

			this.openedRecours$.next(data.recours);

		}

		if (data.traitement){



		}

		if (data.recoursSuspenduDefinitif !== null) {

			this.isRecoursSusDef$.next(data.recoursSuspenduDefinitif);
			// console.log('TY LE RECOURS SUSPENDU DEFINITIF, ', data.recoursSuspenduDefinitif);

		}

		if (data.typePersonne) {

			this.listeTypePersonne$.next(data.typePersonne);

		}

		if (data.docStatus) {

			this.docStatus$.next(data.docStatus);

		}


		// if (data.getRecoursSusSoul) {

		// 	this.decisionService.openedDecision$.next(data.getRecoursSusSoul);

		// }

	}


	public isDataLoaded() {

		return this.loadingRecours$.value.motifLoaded
		//this.loadingRecours$.value.userLoaded
		&&	this.loadingRecours$.value.recoursLoaded
		&& this.loadingRecours$.value.demandeurLoaded;

	}


	public isFilterDataLoaded() {

	return this.loadingRecours$.value.motifLoaded;
	// && this.loadingRecours$.value.demandeurLoaded;
	// && this.loadingRecours$.value.recoursLoaded
	// this.filterRecoursData$.value?.userLoaded

	}


	public getRecoursByDocAndUser(userCode: any, docCode: any) {

		// if (this.openedRecours$.value == undefined) {

			 return this.apiService.getRecoursByDocAndUser(userCode, docCode); // subscribe((data)=>{

			// 	this.isModif$.next(data.data);

			// 	// console.log("MODIF",data.data)

			// });

		// } else {

		// 	return this.openedRecours$.value;

		// }

	}


	setOpenedRecours(newRecours: RECOURS) {

		this.openedRecours$.next(newRecours);

	}


	setListRecours(newList: RECOURS[]){

		this.listeRecours$.next(newList);

	}


	 updateRecours(data: any) {

		this.openedRecours$.next({

	 		...this.openedRecours$.value,

	 		...data

	 	})

	 }


	 getAllMotif(){

	//	if (this.listeMotif$.value == undefined) {

			return this.apiService.getAllMotif();

	//	} else {

		//	return this.listeMotif$.value;

	//	}

	 }


	 getDemandeurByCode(code:any){

	//	if (this.demandeurInfo$.value == undefined) {

			return this.apiService.getUserByCode(code);

		//} else {

	//		return this.demandeurInfo$.value;

//}

	 }


	saveRecours() {

		// console.log("IS MODIF", this.isModif$.value);

		//	API	INSERT STATUS NOUVEAU

		this.apiService.createRecours<api_res>(this.openedRecours$.value as RECOURS).subscribe(
		 	data => {

				//// console.log("IS MODIF TAFIDITRA", this.isCreate$.value)
				if (data.status) {

					this.statusService.editing$.next(false);

					this.openedRecours$.next({
						...this.openedRecours$.value as RECOURS,
						RECOURS_CODE: data.data
					});

					//	this.isCreate$.next(true)
					this.isModif$.next(data.data);

					// this.openedRecours$.next(data.data); // MIALA RAHA MISY OLANA

					this.toastr.success(messages.recours.demandeExpeditionSuccess, messages.recours.recoursTitle);

					this.boutonLibelle$.next('Modifier');

					this.etat$.next(etat.NOUVEAU);

				} else {

				 	this.toastr.error(messages.errorMessage, messages.errorTitle);

				}

			 	this.spinner.hide();

			}
		 );

	}


	updateNouveauRecours() {

		//	API UPDATE STATUS NOUVEAU

		this.apiService.updateRecours<api_res>(this.openedRecours$.value as RECOURS).subscribe(

			data => {

				if (data.status) {

					this.statusService.editing$.next(false);

					this.toastr.success(messages.recours.demandeModificationSuccess, messages.recours.recoursTitle);

					this.boutonLibelle$.next(boutonLibelle.modifier);

					this.etat$.next(etat.NOUVEAU);

				} else {

					this.toastr.error(messages.errorMessage, messages.errorTitle);

				}

				this.spinner.hide();
			}


		);

	}


	expedierRecours(){

		// if (this.isModif$.value == null) {
		if (!this.openedRecours$.value?.RECOURS_CODE) {

		//	API CREATE STATUT EXPEDIE

			this.apiService.createExpedierRecours<api_res>(this.openedRecours$.value as RECOURS).subscribe(
				data => {

					//	this.isCreate$.next(true)

					if (data.status) {

						this.statusService.editing$.next(false);

						this.openedRecours$.next({
							...this.openedRecours$.value as RECOURS,
							RECOURS_CODE: data.data
						});

						this.toastr.success(messages.recours.demandeExpeditionSuccess, messages.recours.recoursTitle);

						this.isActionEnabled('REFRESH');

						this.etat$.next(etat.EXPEDIE);


					} else {

						this.toastr.error(messages.errorMessage, messages.errorTitle);

					}

					this.spinner.hide();

					this.isModif$.next(data.data);

				}
			);

		} else {

		//	API UPDATE STATUT EXPEDIE
			this.apiService.updateExpedierRecours<api_res>(this.openedRecours$.value as RECOURS).subscribe(

				data => {

					if (data.status) {

						this.statusService.editing$.next(false);

						this.toastr.success(messages.recours.demandeModificationAndExpeditionSuccess, messages.recours.recoursTitle);

						this.isActionEnabled('REFRESH');

						this.etat$.next(etat.EXPEDIE);

					} else {

						this.toastr.error(messages.errorMessage, messages.errorTitle);

					}

					this.spinner.hide();
				}


			);

		}

	}


	downloadRecours(lotCode: any, userCode: any, docType: TYPE_RECOURS){

	this.apiService.downloadRecours<api_res>(lotCode, userCode, docType).subscribe((data) => {

		let base64string = data.data;
		this.utilsService.downloadPdf(base64string, lotCode);
		this.spinner.hide();
		return data.data;

	})

	}


	isActionEnabled(actionType: 'EXPEDIE' | 'REFRESH' | 'SOULEVE') {

		// if (this.openedRecours$.value) {

			// if (this.openedRecours$.value?.ETAT_CODE_FK === etat.NOUVEAU || this.openedRecours$.value?.ETAT_CODE_FK === null || this.openedRecours$.value?.ETAT_CODE_FK === undefined) {
		switch (actionType) {

			case 'EXPEDIE':

				if (this.openedRecours$.value?.ETAT_CODE_FK && this.openedRecours$.value?.ETAT_CODE_FK == etat.NOUVEAU) {

					return false;

				} else if (!this.openedRecours$.value?.ETAT_CODE_FK || this.openedRecours$.value?.ETAT_CODE_FK == undefined || this.openedRecours$.value?.ETAT_CODE_FK == null) {

					return false;

				} else if (this.openedRecours$.value == undefined) {

					return false;

				} else { return true; }

			case 'REFRESH':

				return true;

			case 'SOULEVE':

				return this.openedRecours$.value?.ETAT_CODE_FK == etat.SUS_SOUL;

		}


			// }
		// } else { return false; }

	}


	isActionRecoursForAdmin(): boolean {

		return this.userService.connectedUserB$.value?.USR_GROUPE === GRP_CODE_SUPER_ADMIN;

	}


	verifyDateRestante(dateRestante: any): number {

		const dateInit = new Date(dateRestante).getDay();

		const dateFinal = this.utilsService.DateSErver$.getDay();

		const dayNumber = dateFinal - dateInit;

		return dayNumber;

	}


 isFormInvalid(form: FormGroup, controllName: string, validatorName: 'required' | 'min' | 'max' | 'hourMinMax' | 'email' | 'dateMinMax' = "required") {

	let control = form.get(controllName);

	if (control == undefined || control == null) return false;

	// console.log("controllName Error ", control.errors != null ? control.errors[validatorName] : "null");

	if (validatorName != 'hourMinMax' && validatorName != 'dateMinMax') {

		return (control.touched && (control.errors == null ? false : (control.errors[validatorName]) != undefined));

	}


	// console.log("Heure ", (control.touched && (control.errors === null ? false : (control.errors[validatorName]) === null ? false : !control.errors[validatorName])));

	return (control.touched && (control.errors === null ? false : (((control.errors[validatorName]) === null || control.errors[validatorName]) === undefined) ? false : !control.errors[validatorName]));

}

validateForm(): boolean {

	if (this.activatedForm$.value) {

		let form = this.activatedForm$.value;

		form.markAllAsTouched();

		let is_error = false;

		let is_warning = false;

		let error_number = 0;

		let warning_number = 0;

		for (const key in form.controls) {

			if (Object.prototype.hasOwnProperty.call(form.controls, key)) {

				// console.log("********", key, "********");

				let is_disabled = isInputDisbled(key);

				// console.log("Is Disabled", is_disabled);

				// const element = form.controls[key];
				if (is_disabled == false) {

					// console.log("Vérification");

					// check
					let error_res = false;
					let warning_res = false;

					// console.log((form.get(key) instanceof FormGroup) ? "_GROUP_" : "_INPUT_");

					if ((form.get(key) instanceof FormGroup)) {

						let nested_ = form.get(key) as FormGroup;

						for (const key_in in nested_.controls) {

							let error_res_in = false;
							let warning_res_in = false;

							if (Object.prototype.hasOwnProperty.call(form.controls, key_in)) {

								let is_disabled_in = isInputDisbled(key_in);

								// console.log("Is Disabled in", is_disabled_in);

								if (!is_disabled_in) {

									error_res_in = this.isFormInvalid(form, key, "email") ||
										this.isFormInvalid(form, key + "." + key_in, "max") ||
										this.isFormInvalid(form, key + "." + key_in, "min") ||
										this.isFormInvalid(form, key + "." + key_in, "hourMinMax") ||
										this.isFormInvalid(form, key + "." + key_in, "dateMinMax");


									warning_res_in = this.isFormInvalid(form, key + "." + key_in, "required");


									if (warning_res_in == true) {

										// console.log("Warning in...");

										is_warning = true;

										warning_number += 1;

									}

									if (error_res_in == true) {

										// console.log("Error in...");

										is_error = true;

										error_number += 1;

									}

								}

							}

						}

					} else {

						// console.log("Res ext ", form.get(key)?.errors);
						// console.log("Is Invalid Hour Min Max: ", this.isFormInvalid(form, key, "hourMinMax"));


						// console.log("email, ", this.isFormInvalid(form, key, "email"));
						// console.log("max, ", this.isFormInvalid(form, key, "max"));
						// console.log("min, ", this.isFormInvalid(form, key, "min"));
						// console.log("HOur ", this.isFormInvalid(form, key, "hourMinMax"));

						error_res = this.isFormInvalid(form, key, "email") == true ||
							this.isFormInvalid(form, key, "max") == true ||
							this.isFormInvalid(form, key, "min") == true ||
							this.isFormInvalid(form, key, "hourMinMax") == true ||
							this.isFormInvalid(form, key, "dateMinMax");

						// console.log("Error detected : ", error_res);

						// this.isFormInvalid(form, key, "dateMinMax");

						warning_res = this.isFormInvalid(form, key, "required");

					}

					if (warning_res == true) {

						// console.log("Warning...");

						is_warning = true;

						warning_number += 1;

					}

					if (error_res == true) {

						// console.log("Error...");

						is_error = true;

						error_number += 1;

					}

				}

				// console.log("********", key, "********\n");
				// console.log("******************************************************************************");

			}

		}


		this.formSpecificValidators.forEach(fn => {

			let validation = fn.call({});

			 if (validation.error){

				this.toastr.error("La '"+ validation.description + "' est invalide");

				error_number++;

				is_error = true;

			 }

		});


		//s'il y a une erreur fatal
		if (is_error) {

			this.toastr.error((error_number == 1 ?
				"Un champ n'est pas valide, veuillez bien vérifier la formulaire"
				: error_number + " champs sont invalides, veillez bien vérifier la formulaire"), "FORMULAIRE INVALIDE");

		}

		//s'il n'y a pas d'erreur fatal mais seulement des avertissements
		if (is_warning && !is_error) {

			// on averti l'utilisateur qu'il y a des champs no remplies
			// let confirmRes: boolean = Alert((warning_number == 1 ?
			// 	"Un champ n'a pas été rempli, enregistrer quand même ?"
			// 	: warning_number + " champs n'ont pas été remplis, enregistrer quand même"));

			this.toastr.warning(warning_number == 1 ? "Un champ n'a pas été rempli" : warning_number + " champs n'ont pas été remplis", "AVERTISSEMENT");

			// if (!confirmRes) {

			// 	this.toastr.warning("L'enregistrement a été annulé", "ANNULATION D'ENREGISTREMENT");

			// 	return false;

			// }
			return true;

		}

		return !is_error;

	} else {

		return false;

	}

}

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { EplPpmService } from '../../../core/services/epl-ppm/epl-ppm.service';
import { ModalPreRequisVerificationComponent } from '../modal-pre-requis-verification/modal-pre-requis-verification.component';

@Component({
  selector: 'app-modal-visualisation-pre-requis',
  templateUrl: './modal-visualisation-pre-requis.component.html',
  styleUrls: ['./modal-visualisation-pre-requis.component.scss']
})
export class ModalVisualisationPreRequisComponent implements OnInit {
	IsmodelShow: boolean;
	doc: any;
  	docName: string;
  	pdf$ = new BehaviorSubject<Uint8Array | undefined>(undefined);

	codePPMHDR:string
	type_doc:string;
  constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef : MatDialogRef<ModalVisualisationPreRequisComponent>,
		public dialog : MatDialog,
		private servicePPM: EplPpmService,
		private spinner: NgxSpinnerService


	) { }

  ngOnInit(): void {

	this.spinner.show();
	this.type_doc = this.data.type;
    this.codePPMHDR = this.data.code_ppm;
	// console.log(this.data)
    this.expPdfPPM();

  }

	expPdfPPM() {

		let data = {PPM_HDR_CODE: this.codePPMHDR};
		
		switch (this.type_doc) {
			case 'PPM':
				this.servicePPM.ExportPpmPdf(data).subscribe((res: any) => {
					this.setResponse(res);
				});
				break;
			case 'AGPM':
				this.servicePPM.ExportAGPMPdf(data).subscribe((res: any) => {
					this.setResponse(res);
				});
				break;
			default:
				break;
		}

	}

	setResponse(res:any){

		this.docName = res.message;
		let document = this.getPDFArrayBufferFromBase64(res.data);

		this.pdf$.next(document);
		  this.spinner.hide();

	}

	getPDFArrayBufferFromBase64(base64: string) {
		var binary_string = window.atob(base64);
		var l = binary_string.length;
		var bytes = new Uint8Array(l);
		for (var i = 0; i < l; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes;
  	}
  
	OnDownLoadPDF(){

		this.doc =  this.pdf$.value;
		let blob: any = new Blob([this.doc], {type: 'application/pdf' });

		let blobUrl: string = window.URL.createObjectURL(blob);

		let link: any = window.document.createElement('a');
			// console.log(blobUrl, link);
			
		if ('download' in link) {
		link.setAttribute('href', blobUrl);

		link.setAttribute('download', this.docName);
		
		let event: any = window.document.createEvent('MouseEvents');
		event.initMouseEvent
			('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
		link.dispatchEvent(event);
		}

	}
	public closeDialog() {
		this.dialogRef.close("CLOSE_MODAL");
    }

}

import { Component, OnInit } from '@angular/core';
import { StatusService } from '../../services/status/status.service';
import { AppToolsService } from '../../services/tools/app-tools.service';

@Component({
	selector: 'app-connexion-status',
	templateUrl: './connexion-status.component.html',
	styleUrls: ['./connexion-status.component.scss']
})
export class ConnexionStatusComponent implements OnInit {

	constructor(public statusService: StatusService,
	) { }

	ngOnInit(): void {
	}

}

<mat-dialog-actions align="end" class="mb-0">
	<div class="card-action form-group longmodalf action-form-modal-spec mb-0">
		<button mat-button mat-dialog-close class="btn btn-cancel-form">Annuler</button>
		<button mat-button *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'PRMP'" [disabled]="!BehaviorCnm$.validation_document_in_popup.isValidateByPRMP" (click)="onValideInput()"  class="btn btn-send-form">Valider</button>

		<button mat-button *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' || BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' && currentWorkflow ==  1"  (click)="onValidePPPM()"  class="btn btn-send-form">Valider</button>
		<button mat-button *ngIf="BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' || BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' && currentWorkflow ==  1"  (click)="onRejetPPM()"   class="btn btn-danger-armp">Rejeter</button>
		<button mat-button  (click)="onAffectPPM()"  *ngIf="(BehaviorCnm$.data_acteur_connected$.value?.GROUPE_ACT == 'CNM' && currentWorkflow ==  1 && BehaviorCnm$.validation_document_in_popup.isValidateBySECR) "  class="btn btn-send-form">Affecter</button>

	</div>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { emplacement } from 'src/app/configs/constants.json';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {

  public receivedRadio: any;
  public invalid_form: boolean;
  constructor(public router: Router, private appToolService: AppToolsService, public userService: UserService) { }

  ngOnInit(): void {
    this.appToolService.emitEmplacement(emplacement.signUp);
  }

  public receiveRadioClick(message: string): void {

    this.receivedRadio = message;

  }
  onAnnule() {
    this.router.navigate(['/login/public']);
  }

  updateInscription(formValid: boolean) {
    this.invalid_form = formValid;
  }

  save() {

    this.userService.createNewUser();

  }

}

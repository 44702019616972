<footer>
	<div class="container max-widthRecT">
		<div class="d-flex justify-content-center">

			<div class="left-foot">
				<span>
					Vous êtes ici : {{emplacement}}
				</span>
				<span>
				</span>
			</div>

			<!-- <lib-mat-breadcrumb bgColor="rgba(0,0,0,0.5)" fontColor="white" symbol=" -> " fontSize="14px"></lib-mat-breadcrumb> -->

			<ul class="center-foot">
				<li>
					<app-connexion-status></app-connexion-status>
				</li>
				<li>
					<app-saving-status></app-saving-status>
				</li>
				<li>
					<!-- <app-app-notification></app-app-notification> -->
				</li>
			</ul>
			<div class="right-foot">
				Copyright © ARMP 2021 | {{app_name}} Version {{app_version}}
			</div>

		</div>
	</div>
</footer>

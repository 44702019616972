import { Injectable } from '@angular/core';
import { web_socket_url } from 'src/assets/api.json';
import {ToastrService} from 'ngx-toastr';

export interface SessionData {
  ACTION: string;
  PAYLOAD: any;
}

@Injectable()
export class SocketServiceService {
  private conn: WebSocket;
  private closed = false;

  constructor(
  	private toastr$: ToastrService
	) { }

  initConnection() {
    this.conn = new WebSocket(web_socket_url);
    this.conn.onopen = () => console.log("Connection socket OK")
    this.conn.onerror = (e) => {
      // console.error("Impossible de se connecter au websocket du serveur : ", e)
			this.toastr$.warning( 'Traitement des informations en simultané indisponible", "Connection serveur websocket impossible');
      this.closed = true;
    }
  }

  sendData(data: SessionData) {
    if (!this.closed) {
      this.conn.send(JSON.stringify(data))
    }
  }

  get connection() { return this.conn }
}

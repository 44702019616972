import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BehaviourPpmServiceService } from 'src/app/modules/e-planification/core/services/epl-ppm/behaviour-ppm-service.service';
import { EplPpmService } from 'src/app/modules/e-planification/core/services/epl-ppm/epl-ppm.service';
import { BehaviorCnmService } from 'src/app/shared/services/cnm/behavior-cnm.service';
import { CnmService } from 'src/app/shared/services/cnm/cnm.service';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';

@Component({
  selector: 'app-action-popup-pre-requis',
  templateUrl: './action-popup-pre-requis.component.html',
  styleUrls: ['./action-popup-pre-requis.component.scss']
})
export class ActionPopupPreRequisComponent implements OnInit, OnDestroy {

  currentWorkflow:any

  subscription: Subscription[] = []

  @Output() CloseModalEvent: EventEmitter<any> = new EventEmitter()
  constructor(
    public BehaviorCnm$: BehaviorCnmService,
    private CnmService : CnmService,
    private toastr: ToastrService,
    private appTools: AppToolsService,
		private servicePPM: EplPpmService,
		private behaviorService: BehaviourPpmServiceService,
		private EplPpmService: EplPpmService,
		public toastServic: ToastrService


  ) { }

  ngOnInit() {
  }


	/**
	 * @description Action de  validation des inputs remplis par le'acteur PRMP
	 */
  onValideInput(){

      this.subscription.push(this.servicePPM.ExpedierPpm({PPM_HDR_CODE:this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE}).subscribe((res:any) => {

					this.behaviorService.Donnee_SelEnt$.subscribe((res: any) => {
						this.behaviorService.filtrer_PPM({
								...this.behaviorService.filtrer_param,
								ANNEE: res.ANNEE,
								USR_CODE: this.BehaviorCnm$.data_acteur_connected$.value?.CODE_USER

						});
						this.CloseModalEvent.emit('CLOSE MODAL')
						this.toastServic.success(res.message, 'Expédition PPM')
					})
				})

				// this.CnmService.validationPPM(this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE as string).subscribe((res:any) => {
        // if(res.status == 200){
        //      this.toastr.success(res.message, 'Succès')
        //      this.CloseModalEvent.emit('CLOSE MODAL')
        //     }
   			// })
			)

  }


	/**
	 * @description Action de validation des exhaustivités par l'acteur secretaire
	 */
  onValidePPPM(){

    this.subscription.push(
      this.appTools.confirm({
      title:'Validation des Pré-requis',
      message: 'Etes-vous sûr que les pièces jointes sont complètes',
      confirmBtnText: 'Ok',
      cancelBtnText: 'Annuler'
      }).subscribe((confirm:any) => {
        if(confirm){

          this.CnmService.validationPpmParSecretaire(this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE as string, this.BehaviorCnm$.data_acteur_connected$.value?.CODE_USER as string)
                         .subscribe((res:any) => {
            if(res.status == 200){
                this.BehaviorCnm$.validation_document_in_popup.isValidateBySECR = true;
                this.toastr.success(res.message, 'Validation des pré-requis')
            }
          })

        }
      })
    )

  }

	/**
	 * @description Action de rejet par l'acteur Secretaire en cas de l'anormalité des pré-requis
	 */
	onRejetPPM(){

    this.subscription.push(this.appTools.confirm({
      title:'Validation des Pré-requis',
      message: 'Etes-vous sûr que les pièces jointes sont complètes',
      confirmBtnText: 'Ok',
      cancelBtnText: 'Annuler'
    }).subscribe((confirm:any) => {
        if(confirm){

          this.CnmService.rejeterPpmParSecretaire(this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE as string, this.BehaviorCnm$.data_acteur_connected$.value?.CODE_USER as string)
                         .subscribe((res:any) => {
              if(res.status == 200){
                this.toastr.success(res.message, 'Rejection des pré-requis')
                this.CloseModalEvent.emit('CLOSE MODAL')
                // this.dialogRef.close()
              }
          })

        }
    }))

  }

  /**
	* @description Affection de tâches à l'autre secretaire et enchapinement workflow
	*/
  onAffectPPM(){

    this.CnmService.insertWorkFlow( this.currentWorkflow,
                                    this.BehaviorCnm$.data_for_cnm$.value?.DOC_CODE as string,
                                    null,
                                    this.BehaviorCnm$.data_acteur_connected$.value?.CODE_USER as string)
									 	.subscribe((res:any) => {
												if(res.status == 200){
															this.toastr.success(res.message, 'WORKFLOW')
												}
										})
    	this.CloseModalEvent.emit('CLOSE MODAL')
  }


  ngOnDestroy(){

    this.subscription.forEach(element => {

        element.unsubscribe();

    })

	}



}

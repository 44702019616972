import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I_Portail, T_PortailType } from '../../interfaces';
import { session_key } from "src/app/configs/storage_key.json";


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public currentPortail$ = new BehaviorSubject<I_Portail>({
    type: "public",
    link: "/public",
    textLabel: "Public"
  });

  // private storage: Storage = sessionStorage;
  private storage: Storage = localStorage;

  constructor() { }

  public getItem(key: string): any {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public clearSession(): void {
    this.storage.clear();
  }

  public hasSession(): boolean {
    return this.getItem(session_key);
  }

}

<div>
	<div class="main-logo">
		<img src="assets/images/logo_armp.png" alt="Logo ARMP">
	</div>
	<div class="text-center mt-4">
		<h5>Demande de création de compte {{receivedRadio?.code}}</h5>
	</div>
	<div>
		<p style="padding-top: 15px ;">
			Je soussigné(e) <span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_NOM}}
				{{(userService.openedForm$ | async)?.dataUser?.USR_PRENOM}}</span></p>
		<p> nommé(e) {{receivedRadio?.libelle}} ({{receivedRadio?.code}}) </p>
		<p>selon l’acte suivant : <span
				class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_ACTE_NOMINATION}}</span>
			du <span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_ACTE_NOMINATION_DATE?.split('-')?.reverse()?.join('/')}}</span> ,
			demande la création de mon compte SIGMP, avec les informations suivantes :
		</p>
		<div class="d-flex log">
			Autorité Contractante :
			<div *ngFor="let entite of (userService.entites$ | async) ">
				<span *ngIf="entite.ENT_CODE == (userService.openedForm$ | async)?.dataUser?.USR_ENTITE_CODE " class="val-auto-complet">
					{{entite.ENT_LIBELLE}}
				</span>
			</div>
			<div *ngFor="let direction of (userService.direction$ | async) ">
				<span *ngIf="+direction.COD_DIRECTION== (userService.openedForm$ | async)?.dataUser?.USR_ENTITE_CODE"
					class="val-auto-complet">
					{{direction.LIBELLE_DIRECTION}}
				</span>
			</div>

		</div>
	</div>

	<div class="d-flex log">
		Login (Matricule) :
		<span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_LOGIN}}</span>
	</div>
	<div class="d-flex log">
		Région :
		<span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_REGION_CODE}}</span>
	</div>
	<div class="d-flex log">
		Téléphone :
		<span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_TELEPHONE}}</span>
	</div>
	<div class="d-flex log">
		E-Mail :
		<span class="val-auto-complet">{{(userService.openedForm$ | async)?.dataUser?.USR_EMAIL}}</span>
	</div> <br>
	<p>
		En application des dispositions de l’article 11 , paragraphe III, de la loi n° 2016-055 portant Code des
		Marchés Publics , je m’engage à me conformer aux dispositions du Code Ethique , dont entre autres :<br><br>
		- L’obligation de confidentialité absolue à l’égard des informations non publiques dont je
		disposerai comme mon mot de passe ;<br>
		- Le présent engagement est établi en deux (2) originaux pour être utilisé et servir ce que de droit.
	</p>
	<p></p>
	<div class="d-flex justify-content-end mt-5">
		<p>
			Fait à <span class="val-auto-complet">{{(userService.openedForm$ | async)?.publicInfo?.LIEU}}</span>, le {{date | async | date : 'dd/MM/yyyy'}}
		</p>
	</div>

	<div class="d-flex justify-content-md-between mr mt-5">

		<p *ngIf="receivedRadio?.code === 'UGPM'">La PRMP</p>
		<p *ngIf="receivedRadio?.code === 'CAO' ">Le Président de la Commision</p>
		<p></p>
		<p class="align-self-end">
			L’Intéressé(e)
		</p>
	</div>

</div>
